import React from 'react'
import { Component } from 'react';
import http from "helpers/http.js";
import { Container, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import LayoutBox from 'components/layout/layout-box/LayoutBox.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';
import LayoutBoxFooter from 'components/layout/layout-box/LayoutBoxFooter.js';
import styles from 'components/api-authorization/AuthorizationStyles.module.scss';
import landingBanner from 'images/landing/BigPotatoBanner.png';
import potatoHappy from 'images/landing/PotatoHappy.png';

export class ConfirmEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirming: false,
            tokenInvalid: false,
            error: null,
            success: null,
            isSilk: false,
            isMounted: false
        }
    }

    componentDidMount = () => {
        this.setState({
            isMounted: true
        });

        var url = process.env.REACT_APP_API_URL;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const email = urlParams.get('email');
        const tokenConfirm = urlParams.get('tokenConfirm');

        var emailString = "";
        var tokenConfirmString = "";
        if (email || tokenConfirm) {
            emailString = email.toString();
            tokenConfirmString = tokenConfirm.toString();
        }

        var config = {
            method: 'get',
            params: {
                email: emailString,
                token: tokenConfirmString
            },
            url: url + 'api/Email/confirm-email',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        http(config)
            .then((response) => {
                if (this.state.isMounted) {
                    if (response.status === 200) {
                        const successMessage = response.data.message;
                        this.setState({
                            confirming: true,
                            success: successMessage
                        });
                    }
                }
            })
            .catch((error) => {
                if (this.state.isMounted) {
                    if (error) {
                        const errors = error.response.data.message;
                        if (error.response.status === 401) {
                            this.setState({
                                confirming: false,
                                tokenInvalid: true,
                                error: errors
                            });
                            console.log(error);
                        }
                        else {
                            this.setState({
                                confirming: false,
                                error: errors
                            });
                            console.log(error);
                        }
                    }
                }
            });

        this.setSilk();
    }

    componentWillUnmount() {
        this.setState({
            isMounted: false
        });
    }

    setSilk() {
        //this works to detect silk browser
        if (/\bSilk\b/.test(navigator.userAgent)) {
            this.setState({
                isSilk: true
            });
        }
    }

    render() {
        return (
            <div className={styles.containerBox}>
                <div className={styles.containerConfirm}>
                    {/*<img className={styles.bannerImage} src={landingBanner} />*/}
                    <Container className="h-100 p-0">
                        {this.state.tokenInvalid ? <div className="row justify-content-center w-100 h-100 m-0">
                            <LayoutBox className="card text-center my-auto mx-auto">
                                <LayoutBoxHeader className="card-header">
                                    <h5 className={this.state.isSilk ? styles.cardTitleSilk + " card-title" : styles.cardTitle + " card-title"}>Confirm Email</h5>
                                </LayoutBoxHeader>
                                <LayoutBoxBody className={styles.cardBody + " card-body"}>
                                    {this.state.error}
                                </LayoutBoxBody>
                            </LayoutBox>
                        </div> :
                            <div className="row justify-content-center w-100 h-100 m-0">
                                {this.state.confirming
                                    ? <LayoutBox className="card text-center my-auto mx-auto">
                                        <LayoutBoxHeader className="card-header">
                                            <h5 className={this.state.isSilk ? styles.cardTitleSilk + " card-title" : styles.cardTitle + " card-title"}>Confirm Email</h5>
                                        </LayoutBoxHeader>
                                        <LayoutBoxBody className={styles.cardBody + " card-body"}>
                                            {this.state.success}
                                        </LayoutBoxBody>
                                        <LayoutBoxFooter className="card-footer text-muted">
                                            <NavLink tag={Link} className={styles.buyBtn + " btn mt-2"} to="/authentication/Login">Login into account</NavLink>
                                        </LayoutBoxFooter>
                                    </LayoutBox>
                                    : <LayoutBox className="card text-center my-auto mx-auto">
                                        <LayoutBoxHeader className="card-header">
                                            <h5 className={this.state.isSilk ? styles.cardTitleSilk + " card-title" : styles.cardTitle + " card-title"}>Confirm Email</h5>
                                        </LayoutBoxHeader>
                                        <LayoutBoxBody className={styles.cardBody + " card-body"}>
                                            {this.state.error ? <div className={styles.alertSilk + " alert alert-danger"} role="alert">{this.state.error}</div> : <Link to='/'>
                                                <span className={styles.loadingSpinner + " spinner-border spinner-border mr-3"}></span>
                                            </Link>}
                                        </LayoutBoxBody>
                                        {!this.state.error && <LayoutBoxFooter className="card-footer text-muted">
                                            <NavLink tag={Link} className={styles.buyBtn + " btn mt-2"} to="/authentication/Login">Login into account</NavLink>
                                        </LayoutBoxFooter>}
                                    </LayoutBox>
                                }
                            </div>
                        }

                    </Container>
                </div>
            </div>
        )
    }
}