import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect, } from 'react-redux';
//import { LoginMenu } from './api-authorization/LoginMenu';
import UserInfo from './api-authorization/UserInfo';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import styles from './NavMenu.module.scss';
import logo from '../images/tv-logo.png';
import shopping from '../images/shopping.png';
import shoppingWhite from '../images/shopping-white.png';
import potatoBuy from 'images/landing/BuyItHere.png';
import { withRouter } from 'react-router-dom';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);

        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const { isLoggedIn, userName } = this.state;
        const registerPath = `${ApplicationPaths.Register}`;
        const loginPath = `${ApplicationPaths.Login}`;
        const checkoutPath = `${ApplicationPaths.Checkout}`;
        const profilePath = `${ApplicationPaths.Profile}`;
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        const pathname = window.location.pathname;
        let cartQuantity = 0;
        if (this.props.cart.items.length > 0) {
            this.props.cart.items.forEach(x => {
                cartQuantity += x.quantity;
            });
        }
        if (this.props.location.pathname == '/lobby' /*|| this.props.location.pathname.includes('webgltest')*/) {
            return null;
        }

        return (
            <header className={styles.headerBar}>
                <Navbar className={styles.navbarBp + " navbar navbar-expand-lg navbar-toggleable-lg ng-white border-bottom box-shadow justify-content-center"} light>
                    <Container fluid="lg">

                        <Link className={styles.navIconLeft + " navbar-brand nav-link d-flex mr-auto p-2"} to={{ pathname: "/", state: { detail: true } }}>
                            <img className={styles.logoImage} src={logo} alt="Logo" />
                        </Link>

                        <Collapse className={styles.mainnav + " navbar-collapse collapse w-100"} isOpen={!this.state.collapsed} navbar>
                            <ul className={styles.navLinks + " navbar-nav w-100 justify-content-center"}>
                               
                                {!this.props.currentUser &&
                                    (
                                        <div className="d-flex">
                                            <NavItem className={styles.navLinkMain}>
                                                <Link to="/play" className={styles.navLink + " text-dark nav-link"}>
                                                    Join A Game
                                                </Link>
                                            </NavItem>
                                        <NavItem className={styles.navLinkMain}>
                                            <Link to="/packs" className={styles.navLink + " text-dark nav-link"}>
                                                Packs
                                            </Link>
                                        </NavItem>
                                            <NavItem className={styles.navLinkMain}>
                                                <Link to={registerPath} className={styles.navLink + " text-dark nav-link"}>
                                                    Redeem Code
                                                </Link>
                                            </NavItem>
                                            <NavItem className={styles.navLinkMain}>
                                                <Link to={loginPath} className={styles.navLink + " text-dark nav-link"}>
                                                    Login
                                                </Link>
                                            </NavItem>
                                        </div>
                                    )}

                                {this.props.showModeratorBoard && (
                                    <NavItem>
                                        <NavLink to={"/mod"} tag={Link} className={styles.navLink + " text-dark"}>
                                            Moderator Board
                                        </NavLink>
                                    </NavItem>
                                )}

                                {this.props.showAdminBoard && (
                                    <NavItem>
                                        <NavLink to={"/admin"} tag={Link} className={styles.navLink + " text-dark"}>
                                            Admin Board
                                        </NavLink>
                                    </NavItem>
                                )}

                                {this.props.currentUser && (
                                    <React.Fragment>
                                        <NavItem>
                                            <Link className={styles.navLink + " text-dark nav-link"} to="/play">Join A Game</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link className={styles.navLink + " text-dark nav-link"} to="/lobby">Lobby</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link className={styles.navLink + " text-dark nav-link"} to="/packs">Packs</Link>
                                        </NavItem>
                                    </React.Fragment>
                                )}
                            </ul>
                        </Collapse>

                        <NavbarToggler onClick={this.toggleNavbar} className={styles.toggleBurger + " mr-2"} />
                        <Collapse className={styles.sidenav + " w-100"} isOpen={!this.state.collapsed}>
                            <a href={void (0)} className="closebtn float-right text-white" onClick={this.toggleNavbar}>&times;</a>
                            <ul className={styles.navLinks + " navbar-nav w-100 justify-content-center"} onClick={this.toggleNavbar}>
                                {!this.props.currentUser && (
                                    <div>
                                        <NavItem className="nav-item">
                                            <Link to="/play" className={styles.navLink + " text-dark nav-link"}>
                                                Join A Game
                                            </Link>
                                        </NavItem>
                                        <NavItem className="nav-item">
                                            <Link to="/packs" className={styles.navLink + " text-dark nav-link"}>
                                                Packs
                                            </Link>
                                        </NavItem>
                                        <NavItem className="nav-item">
                                            <Link to={loginPath} className={styles.navLink + " text-dark nav-link"}>
                                                Login
                                            </Link>
                                        </NavItem>
                                        <NavItem className="nav-item">
                                            <Link to={registerPath} className={styles.navLink + " text-dark nav-link"}>
                                                Redeem Code
                                            </Link>
                                        </NavItem>
                                    </div>
                                )}

                                {this.props.showModeratorBoard && (
                                    <NavItem>
                                        <NavLink to={"/mod"} tag={Link} className={styles.navLink + " text-dark"}>
                                            Moderator Board
                                        </NavLink>
                                    </NavItem>
                                )}

                                {this.props.showAdminBoard && (
                                    <NavItem className="nav-item">
                                        <NavLink to={"/admin"} tag={Link} className={styles.navLink + " text-dark"}>
                                            Admin Board
                                        </NavLink>
                                    </NavItem>
                                )}

                                {this.props.currentUser && (
                                    <React.Fragment>
                                        <NavItem>
                                            <Link className={styles.navLink + " text-white nav-link"} to="/play">Join A Game</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link className={styles.navLink + " text-white nav-link"} to="/packs">Packs</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link className={styles.navLink + " text-white nav-link"} to="/lobby">Lobby</Link>
                                        </NavItem>
                                    </React.Fragment>
                                )}
                                <li className="nav-item">
                                    <Link className="nav-link p-1 position-relative" to={checkoutPath} style={{ marginLeft: "10px" }}>
                                        <img className={styles.shoppingIcon} src={shoppingWhite} alt="Shopping" />
                                        {
                                            cartQuantity > 0 &&
                                            <div className={styles.cartNumber}>{cartQuantity}</div>
                                        }
                                    </Link>
                                </li>


                                {this.props.isLoggedIn && (
                                    <UserInfo logOut={this.props.logOut} isLoggedIn={this.props.isLoggedIn} isMobile={true} />
                                )}

                            </ul>
                        </Collapse>

                        <ul className="nav navbar-collapse collapse navbar-nav ml-auto w-100 justify-content-center" style={{ maxWidth: "25%", }}>
                            <li className="nav-item" style={{ /*paddingLeft: "12vh",*/ }}>
                                <Link className="nav-link position-relative" to={checkoutPath}>
                                    <img className={styles.shoppingIcon} src={shopping} alt="Shopping" />
                                    {
                                        cartQuantity > 0 &&
                                        <div className={styles.cartNumber}>{cartQuantity}</div>
                                    }
                                    {!this.props.isLoggedIn && (
                                        <img id="potatoimage" className={this.state.isSilk ? styles.potatoMobileSilk : styles.potatoMobile} src={potatoBuy} />
                                    )}
                                </Link>
                            </li>
                            <li className="nav-item" style={{ maxWidth: "75%", display: "flex", }}>
                                {this.props.isLoggedIn && (
                                    <UserInfo logOut={this.props.logOut} isLoggedIn={this.props.isLoggedIn} isMobile={false} />
                                )}
                            </li>
                        </ul>

                    </Container>
                </Navbar>
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
    };
}


export default connect(mapStateToProps)(withRouter(NavMenu));