const animation = require("./cos-background.json");

module.exports = {
    loop: true,
    autoplay: true,
    prerender: true,
    renderer: "svg",
    animationData: animation,
    rendererSettings: {
        preserveAspectRatio: 'none'
    },
}