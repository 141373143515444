import {
    UPDATE_CART
} from "actions/types";


const initialState = {
    items: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_CART:
            return {
                items: payload.items,
            };
        default:
            return state;
    }
}



