import React, {
    useState,
    useRef,
    useEffect
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Container } from 'reactstrap';
import { OverlayTrigger, Tooltip, Button, Overlay } from 'react-bootstrap';
import LayoutBox from 'components/layout/layout-box/LayoutBox.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';
import styles from 'components/api-authorization/AuthorizationStyles.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faCheck } from "@fortawesome/free-solid-svg-icons";
import { register, clearMessage, checkUserExists, login } from "actions/auth";
import { useLocation, useHistory } from 'react-router-dom';

import landingBanner from 'images/landing/BigPotatoBanner.png';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';

import { usePasswordValidation } from "actions/usePasswordValidation";

import gameType from "constants/gameSelection";
import registerSteps from "constants/registerSteps";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                That doesn't look right. Can you try again?
            </div>
        );
    }
};

const vPassword = (value) => {
    if (value.length < 8) {
        return (
            <div className="alert alert-danger" role="alert">
                Your password must be at least 8 characters.
            </div>
        );
    }
};

const RegisterBox = () => {
    const form = useRef();
    const checkBtn = useRef();
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState({
        firstPassword: "",
    });

    const [
        validLength,
        hasNumber,
        upperCase,
        lowerCase,
    ] = usePasswordValidation({
        firstPassword: password.firstPassword
    });

    const setFirst = (event) => {
        setPassword({ ...password, firstPassword: event.target.value });
    };

    //const [confirmPassword, setConfirmPassword] = useState("");
    const [voucherCode, setVoucherCode] = useState("");
    const [loading, setLoading] = useState(false);

    const [accepterms, setTNC] = useState(false);
    const [type, setType] = useState("password");
    const [errors, setErrors] = useState({ email: "", password: "" });
    const [input, setInputs] = useState({});
    const [name, setName] = useState({});
    const [isSilk, setSilk] = useState(false);

    const [successful, setSuccessful] = useState(false);

    const { message, list } = useSelector(state => state.message);
    const dispatch = useDispatch();
    const location = useLocation();

    const [show, setShow] = useState(false);
    const target = useRef(null);

    const [submitButtonText, setSubmitButtonText] = useState("Next");
    const [registerStep, setRegisterStep] = useState(registerSteps.ActivationCode);
    const [gameSelection, setGameSelection] = useState(gameType.Web);

    const [stepNumberText, setStepNumberText] = useState("Step One");
    const [stepTitleLine1, setStepTitleLine1] = useState("Type in your");
    const [stepTitleLine2, setStepTitleLine2] = useState("Activation Code");
    const [showPostPurchaseText, setShowPostPurchaseText] = useState(false);

    const { isLoggedIn } = useSelector(state => state.auth);


    // Effetively componentDidMount
    useEffect(() => {
        setSilkLoad();

        const search = location.search
            ? location.search
            : '';

        let searchParams = new URLSearchParams(search);

        // Get the params in lower case
        const newParams = new URLSearchParams();
        for (const [name, value] of searchParams) {
            newParams.append(name.toLowerCase(), value);
        }

        if (newParams.has('voucher')) {

            const voucher = newParams.get('voucher');

            input["voucherCode"] = voucher;

            setVoucherCode(voucher);
            setName("voucherCode");
        }
        if (newParams.has('email')) {

            const email = newParams.get('email');

            input["email"] = email;

            setEmail(email);
            setName("email");
        }

        if (newParams.has('fc')) {
            if (newParams.get('fc') == 'true') {
                setShowPostPurchaseText(true);
                setSubmitButtonText("Activate");
            }
        }
    }, [])

    const setSilkLoad = (e) => {
        if (/\bSilk\b/.test(navigator.userAgent)) {
            setSilk(true);
        }
    };

    const onChangeEmail = (e) => {
        const target = e.target;
        const name = target.name;

        input[e.target.name] = e.target.value;

        setEmail(e.target.value);
        setName(name);
    };

    //const onChangePassword = (e) => {
    //    const password = e.target.value;
    //    const target = e.target;
    //    const name = target.name;

    //    input[e.target.name] = e.target.value;

    //    setPassword(password);
    //    setName(name);
    //};

    const onChangeTNC = (e) => {
        input[e.target.name] = e.target.value;

        setTNC(e.target.value);
        setName(e.target.name);
    };

    const onChangeVoucherCode = (e) => {
        input[e.target.name] = e.target.value;

        setVoucherCode(e.target.value);
        setName(e.target.name);
    };

    const onChangeGameSelection = (e) => {
        setGameSelection(parseInt(e.target.value));
    }

    const validate = (e) => {
        let isValid = true;

        if (!input["email"]) {
            isValid = false;
            errors.email = "Please enter your email Address.";
            const enterEmail = { email: errors.email };
            setErrors(enterEmail);
        }
        else {
            setErrors({ email: "" });
        }

        if (typeof input["email"] !== "undefined") {
            //this regex doesn't catch domains like .education e.g. simon@school.education
            //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            //Just simplify this...
            if (input["email"].indexOf("@") == -1) {
                isValid = false;
                errors.email = "Please enter valid email address.";
                const newEmail = { email: errors.email };
                setErrors(newEmail);
            }
            else {
                setErrors({ email: "" });
            }
        }

        if (gameSelection == gameType.Web && !input["password"]) {
            if (validLength && hasNumber && upperCase && lowerCase) {
                setErrors({ password: "" });
            }
            else {
                isValid = false;
                errors.password = "Please enter a valid password.";
                const enterPassword = { password: errors.password };
                setErrors(enterPassword);
            }
        }
        else {
            setErrors({ password: "" });
        }

        return isValid;
    }

    const showHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (type === 'input') {
            setType('password');
        }
        else {
            setType('input');
        }
    }

    const handleRegister = (e) => {
        e.preventDefault();

        switch (registerStep) {
            case registerSteps.ActivationCode:
                setRegisterStep(registerStep + 1);
                setShowPostPurchaseText(false);
                setStepNumberText("Step Two");
                setStepTitleLine1("Select a");
                setStepTitleLine2("Game Version");
                setSubmitButtonText("Next");
                break;
            case registerSteps.GameSelect:
                setRegisterStep(registerStep + 1);
                setStepNumberText("Step Three");
                setStepTitleLine1("Enter your");
                if (gameSelection == gameType.Web) {
                    if (isLoggedIn) {
                        history.push("/authentication/voucher-code?voucher=" + voucherCode);
                    } else {
                        dispatch(checkUserExists(email))
                            .then(() => {
                                history.push("/authentication/login?email=" + email);
                            })
                            .catch(() => {
                                setSubmitButtonText("Register");
                                setStepTitleLine2("Email and Password");
                            });
                    }
                } else {
                    setSubmitButtonText("Get Key");
                    setStepTitleLine2("Email Address");
                }
                break;
            case registerSteps.Register:
                if (validate()) {
                    setLoading(true);
                    setSuccessful(false);

                    form.current.validateAll();

                    if (checkBtn.current.context._errors.length === 0) {
                        dispatch(register(e, email, password, voucherCode, gameSelection))
                            .then((res) => {
                                setSuccessful(true);
                                setLoading(true);

                                if (gameSelection == gameType.Web) {
                                    dispatch(login(email, password.firstPassword))
                                        .then((e) => {
                                            history.push("/authentication/welcome?email=" + email);
                                        })
                                        .catch(() => {
                                            setLoading(false);
                                        });
                                } else {
                                    const key = res.data.key;
                                    history.push("/authentication/welcome?email=" + email + "&key=" + key);
                                }
                            })
                            .catch((err) => {
                                setSuccessful(false);
                                setLoading(false);
                            });
                    }
                }
                break;
            default:
                console.warning("No Handler for Register Step " + registerStep);
                break;
        }
    };

    const navBack = (e) => {
        e.preventDefault();
        let previousStep = registerStep - 1;
        setSubmitButtonText("Next");
        setRegisterStep(previousStep <= 0 ? registerSteps.ActivationCode : previousStep);
        setStepNumberText("Step Two");
        setStepTitleLine1("Select a");
        setStepTitleLine2("Game Version");
        dispatch(clearMessage());
    }

    const renderTooltip = (props) => (
        <Tooltip id="buttonActivationWT" {...props}>
            This will be the code you received from Big Potato after purchasing the Big Screen Games pack.
        </Tooltip>
    );

    const renderTooltipOptionSelection = (props) => (
        <Tooltip id="buttonActivationWT1" {...props}>
            <p>Steam is an online gaming platform for Mac and PC. It works as a sort of 'library' for your video games.</p>
            <p>If you've never heard of Steam, we recommend you choose the website version!</p>
        </Tooltip>
    );

    return (
        <div className={styles.containerRegister}>
            {/*<img className={isSilk ? styles.bannerImageSilk + " position-relative" : styles.bannerImage + " position-relative"} src={landingBanner} />*/}
            <Container className="h-100 p-0 mt-5">
                <div className={isSilk ? styles.rowCardSilk + " row justify-content-center w-100 h-100 m-0" : styles.rowCard + " row justify-content-center w-100 h-100 m-0"}>
                    <LayoutBox className="card text-center mt-0 my-auto p-0 mx-auto">
                        <LayoutBoxHeader className="card-header mt-0">
                            {!showPostPurchaseText &&
                                <React.Fragment>
                                    <h6 className={isSilk ? styles.cardSubTitleSilk + " card-title pb-0" : styles.cardSubTitle + " card-title pb-0"}>{stepNumberText}</h6>
                                    <h5 className={isSilk ? styles.cardTitleSilk + " card-title pb-0" : styles.cardTitle + " card-title pb-0"}>{stepTitleLine1}<br className={isSilk ? styles.brCardTitleSilk : styles.brCardTitle} /> {stepTitleLine2}</h5>
                                </React.Fragment>
                            }
                            {showPostPurchaseText &&
                                <React.Fragment>
                                    <h6 className={isSilk ? styles.cardSubTitleSilk + " card-title pb-0" : styles.cardSubTitle + " card-title pb-0"}>Thank you for your purchase!</h6>

                                    {/*<h6 className={isSilk ? styles.cardSubTitleSilk + " card-title pb-0" : styles.cardSubTitle + " card-title pb-0"}></h6>*/}
                                    {/*<h6 className={isSilk ? styles.cardSubTitleSilk + " card-title pb-0" : styles.cardSubTitle + " card-title pb-0"}></h6>*/}
                                    <div className="px-3 pt-2" style={{ marginTop: "10px", fontFamily: "Interstate-Regular", }}>
                                        <div align="center" style={{ color: "#e6017d", fontWeight: "bold" }}>
                                            We have sent an activation code to your email address.
                                            If you are purchasing for a friend, you can forward that email to them and should FINISH NOW.
                                        </div>
                                        <div align="center" className="mt-3">
                                            If you are ready to activate for yourself, hit ACTIVATE.
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </LayoutBoxHeader>
                        <LayoutBoxBody className={styles.cardBody + " card-body pt-0"}>
                            <div>
                                <Form className={styles.formEnter + " mb-3"} id="registerForm" onSubmit={handleRegister} ref={form}>
                                    {!successful && (
                                        <div className={styles.cardPDiv}>
                                            {registerStep == registerSteps.ActivationCode &&
                                                <div className={styles.formGroupErrors}>

                                                    {
                                                        showPostPurchaseText ?
                                                            //<label className={`${isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} ${styles.steamKeyBox} ${styles.smaller}`}>
                                                            //    {voucherCode}
                                                            //</label>
                                                            //<div align="left" style={{ color: "black", fontWeight: "bold" }}>
                                                            //    {voucherCode}
                                                            //</div>
                                                            null
                                                            :
                                                            <React.Fragment>
                                                                <label className={isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="voucherCode">
                                                                    Activation Code
                                                                    <OverlayTrigger
                                                                        trigger="click"
                                                                        rootClose
                                                                        placement="bottom"
                                                                        delay={{ show: 250 }}
                                                                        overlay={renderTooltip}
                                                                    >
                                                                        <Button className={styles.forgottenLink} variant="success"> What's this?</Button>
                                                                    </OverlayTrigger>
                                                                </label>
                                                                <input className={styles.formEnterInput}
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    id="voucherCode"
                                                                    name="voucherCode"
                                                                    onChange={onChangeVoucherCode}
                                                                    value={voucherCode}
                                                                    required
                                                                />
                                                            </React.Fragment>
                                                    }

                                                </div>
                                            }

                                            {registerStep == registerSteps.GameSelect &&
                                                <div className={`${styles.formGroup}`}>
                                                    <br />
                                                    <label className={`${styles.formRadioOptionLabel} ${styles.pinkRadioLabel}`}>
                                                        <input className={`${styles.formRadioInput} ${styles.pinkRadioInput}`}
                                                            type="radio"
                                                            name="game-select"
                                                            value={gameType.Web}
                                                            checked={gameSelection == gameType.Web}
                                                            onChange={onChangeGameSelection}
                                                        />
                                                        <span className={styles.pinkCheckbox}></span>
                                                        PLAY ON OUR WEBSITE
                                                    </label>
                                                    <label className={`${styles.formRadioOptionLabel} ${styles.pinkRadioLabel}`} style={{ marginBottom: 0 }}>
                                                        <input className={`${styles.formRadioInput} ${styles.pinkRadioInput}`}
                                                            type="radio"
                                                            name="game-select"
                                                            value={gameType.Steam}
                                                            checked={gameSelection == gameType.Steam}
                                                            onChange={onChangeGameSelection}
                                                        />
                                                        <span className={styles.pinkCheckbox}></span>
                                                        PLAY ON STEAM
                                                    </label>
                                                    <label className={isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} style={{ marginLeft: "4rem" }}>

                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="bottom"
                                                            delay={{ show: 250 }}
                                                            overlay={renderTooltipOptionSelection}
                                                        >
                                                            <Button className={styles.forgottenLink} variant="success"> What should I choose?</Button>
                                                        </OverlayTrigger>
                                                    </label>

                                                    <div style={{ marginTop: "10px", fontFamily: "Interstate-Regular" }}>
                                                        <div align="center" style={{ color: "#e6017d", fontWeight: "bold" }}>
                                                            Heads up!
                                                        </div>
                                                        <div align="center">
                                                            Once you activate your account, you can't change your mind - so choose carefully!
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {registerStep == registerSteps.Register &&
                                                <div className={styles.formGroup}>
                                                    <label className={isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="email">
                                                        Email
                                                    </label>
                                                    <br />
                                                    <Input className={styles.formEnterInput}
                                                        type="text"
                                                        autoComplete="off"
                                                        id="email"
                                                        name="email"
                                                        value={email}
                                                        onChange={onChangeEmail}
                                                        validations={[required, validEmail]}
                                                        required
                                                    />
                                                    {errors.email ? <div className={styles.alertSilk + " alert alert-danger"} role="alert">{errors.email}</div> : ""}
                                                </div>
                                            }

                                            {(registerStep == registerSteps.Register && gameSelection == gameType.Web) &&
                                                <div className={styles.formGroup + " " + styles.pass_wrapper}>
                                                    <label className={isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="password">
                                                        Password
                                                    </label>
                                                    <br />
                                                    <input className={styles.formEnterInput}
                                                        type={type}
                                                        autoComplete="off"
                                                        id="password"
                                                        name="password"
                                                        value={password.firstPassword}
                                                        onChange={setFirst}
                                                        validations={[required, vPassword]}
                                                        required
                                                    />
                                                    <i onClick={showHide} ref={target} className={isSilk ? styles.eyeSilk : styles.eye}>{type === 'input' ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</i>
                                                    {validLength && hasNumber && upperCase && lowerCase && <i className={styles.passwordCheck}><FontAwesomeIcon icon={faCheck} /></i>}
                                                    <ReactIsCapsLockActive>
                                                        {active => <Overlay target={target.current} show={active ? true : false} placement="right">
                                                            {(props) => (
                                                                <Tooltip className="tooltipCaps" id="overlayCaps" {...props}>WARING: CAPS Lock is {active ? 'on' : 'off'}</Tooltip>
                                                            )}
                                                        </Overlay>}
                                                    </ReactIsCapsLockActive>
                                                </div>
                                            }

                                            {(registerStep == registerSteps.Register && gameSelection == gameType.Web) &&
                                                <div className={styles.formGroupErrors}>
                                                    {errors.password ? <div className={styles.alertSilk + " alert alert-danger"} role="alert">{errors.password}</div> : ""}
                                                    <div>
                                                        <ul className={isSilk ? styles.paddingListErrorsSilk : styles.paddingListErrors}>
                                                            <li className={validLength ? "text-success" : "text-danger"}>
                                                                {validLength ? <span className="text-success">Your password must be at least 8 characters</span> : <span className="text-danger">Your password must be at least 8 characters</span>}
                                                            </li>
                                                            <li className={hasNumber ? "text-success" : "text-danger"}>
                                                                {hasNumber ? <span className="text-success">Your password must include a number</span> : <span className="text-danger">Your password must include a number</span>}
                                                            </li>
                                                            <li className={upperCase ? "text-success" : "text-danger"}>
                                                                {upperCase ? <span className="text-success">Your password must include an upper case letter</span> : <span className="text-danger">Your password must include an upper case letter</span>}
                                                            </li>
                                                            <li className={lowerCase ? "text-success" : "text-danger"}>
                                                                {lowerCase ? <span className="text-success">Your password must include a lower case letter</span> : <span className="text-danger">Your password must include a lower case letter</span>}
                                                            </li>
                                                            {/*<li className={specialChar ? "text-success" : "text-danger"}>*/}
                                                            {/*    {specialChar ? <span className="text-success">Cannot contain any symbols</span> : <span className="text-danger">Cannot contain any symbols</span>}*/}
                                                            {/*</li>*/}
                                                        </ul>
                                                    </div>
                                                </div>
                                            }

                                            {(registerStep == registerSteps.Register && gameSelection == gameType.Web) &&
                                                <div className={styles.formGroupErrors}>
                                                    By registering you are agreeing to the <a href="/terms" target="_blank">BigPotato.tv Terms</a>
                                                </div>
                                            }

                                            {(message || (list && list.length > 0)) &&
                                                <div className={styles.formGroup}>
                                                    <div className={successful ? styles.alertSilk + " alert alert-success" : styles.alertSilk + " alert alert-danger"} role="alert">
                                                        {message}
                                                        {list && list.length > 0 &&
                                                            <ul>
                                                                {list.map(item =>
                                                                    <li key={item}>
                                                                        {item}
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            <div className={isSilk ? styles.formGroupButtonSilk + " position-relative form-group" : styles.formGroupButton + " position-relative form-group d-flex justify-content-between"}>
                                                {registerStep > registerSteps.GameSelect &&
                                                    <button type="button" onClick={navBack} className={isSilk ? styles.backStepBtnSilk + " btn" : styles.backStepBtn + " btn"} disabled={loading}>
                                                        <span>Back</span>
                                                    </button>
                                                }
                                                <button className={isSilk ? styles.submitBtnSilk + " btn" : styles.submitBtn + " btn"} disabled={loading}>
                                                    {loading ?
                                                        <span className={styles.loadingSpinner + " spinner-border spinner-border mr-3"}></span>
                                                        :
                                                        <span>{submitButtonText}</span>
                                                    }
                                                </button>
                                                {/*<img className={styles.potatoHappy} src={potatoHappy} width="48" />*/}
                                            </div>
                                        </div>
                                    )}

                                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                                </Form>
                            </div>
                        </LayoutBoxBody>
                    </LayoutBox>
                </div>
            </Container>
        </div>
    );
};

export default RegisterBox;