import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import { connect, } from 'react-redux';
import { Badge, } from "react-bootstrap"
import Lottie from 'react-lottie';
import { addToCart } from 'actions/cart';

import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';

import ageAnim from 'animations/age.js';
import playersAnim from 'animations/players.js';
import timerAnim from 'animations/timerTurning.js';

import styles from './Pack1Overview.module.scss';


class Pack2Overview extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }



    clickBuyNow = (e) => {
        e.preventDefault();
        this.props.addToCart({
            packId: 2,
            price: this.props.pricing.rawPrice,
        })
        this.props.history.push(ApplicationPaths.Checkout);
    }

    clickStartGame = (e) => {
        e.preventDefault();
        this.props.history.push("/lobby2");
    }

    ownsPackAndActive(packId) {
        if (this.props.auth.isLoggedIn) {
            const packs = this.props.auth.user.packs;
            if (packs != null) {
                const ownsAndActive = packs.find(x => x.gamePackId == packId) && packs.find(x => x.gamePackId == packId).active;
                return ownsAndActive;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    clickSeeMore = (e) => {
        e.preventDefault();
        this.props.history.push("/");
    }

    clickAddToCart = (e) => {
        e.preventDefault();

    }

    render() {
        return (
            <div className={`${styles.overviewCard} ${!this.props.packsPage ? styles.normal : styles.smaller} card text-center border-0 m-auto`} style={{ boxShadow: "0px 5px 9px 4px rgba(0,0,0,0.55)", borderRadius: "1.5rem", }}>
                <div className={`${styles.iconsRow} row px-4 m-1`}>
                    <div className="col p-0">
                        <Lottie options={timerAnim} width="100%" height="auto" isClickToPauseDisabled={true} />
                        <div className={`${styles.iconText} card-subtitle text-muted`}>15MINS+</div>
                    </div>
                    <div className="col p-0">
                        <Lottie options={playersAnim} width="100%" height="auto" isClickToPauseDisabled={true} />
                        <div className={`${styles.iconText} card-subtitle text-muted`}>3-8 PLAYERS</div>
                    </div>
                    <div className="col p-0">
                        <Lottie options={ageAnim} width="100%" height="auto" isClickToPauseDisabled={true} />
                        <div className={`${styles.iconText} card-subtitle text-muted`}>AGE 9+</div>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <hr className={`${styles.overviewBreak} w-75`} />
                </div>
                <div className="card-body p-2 m-1">
                    <h5 className={`${styles.overviewDescription} card-title px-2`}>Round up the players and use your smartphones to play three family-favourite party games on your TV screen!</h5>
                    {/*<h5 className={`${styles.overviewDescription} ${styles.second} card-title px-2`}>Includes 3 family friendly party games.</h5>*/}
                </div>
                <div className="row justify-content-md-center">
                    <hr className={`${styles.overviewBreak} ${styles.second} w-75`} />
                </div>
                <div className="card-body p-2 m-1">
                    {
                        this.props.pricing.rawPrice ?
                            <React.Fragment>
                                {
                                    !this.ownsPackAndActive(2) ?
                                        this.props.pricing.discountActive == true ?
                                            <React.Fragment>
                                                <div className={`${styles.priceRow} row`}>
                                                    <h3 className={`${styles.priceText} ${styles.smaller} card-title px-2`}><s>{this.props.pricing.price}</s></h3>
                                                    <h3 className={`${styles.priceText} ${styles.sale} card-title px-2`}>{this.props.pricing.discount}<span className={styles.priceLabel} dangerouslySetInnerHTML={{ __html: this.props.pricing.discountDescription }}></span></h3>
                                                </div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <div className={`${styles.priceRow} row`}>
                                                    <h3 className={`${styles.priceText} card-title px-2`}>{this.props.pricing.price}</h3>
                                                </div>
                                            </React.Fragment>
                                        :
                                        null
                                }
                                <div className="row justify-content-md-center w-75 m-auto my-2 p-1">
                                    {
                                        !this.ownsPackAndActive(2) ?
                                            <button type="button" onClick={this.clickBuyNow} className={`${styles.packButton} btn btn-lg`}>ADD TO CART</button>
                                            :
                                            <button type="button" onClick={this.clickStartGame} className={`${styles.packButton} btn btn-lg`}>START A GAME</button>
                                    }
                                </div>
                            </React.Fragment>
                            :
                            <div className="spinner-border m-auto" style={{ width: "3rem", height: "3rem", }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                    }
                    <div className="row justify-content-md-center w-75 m-auto my-2 p-1">
                        <button type="button" onClick={this.props.packsPage ? this.clickSeeMore : () => document.getElementById('howToPlay').scrollIntoView()} className={`${styles.packButton} ${styles.blue} btn btn-lg`}>{this.props.packsPage ? "SEE MORE" : "HOW TO PLAY"}</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pricing: state.pricing.Pack2Pricing,
        auth: state.auth,
        packs: state.packs,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (item) => {
            dispatch(addToCart(item))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Pack2Overview));