import { fireCAPIEvent, } from "helpers/capi";
import { fireTikTokConversionEvent, } from "helpers/tiktok";
import { fireGtmConversionEvent, } from "helpers/googleTagManager";
import stripeService from "services/stripe.service.js";
 
const firePageView = (pageName) => {

   
}

const fireClick = (options) => {
}

export const fireConversionEvent = async (options) => {
    let res = await stripeService.getIP();
    options.ip = res.data;

    options.userAgent = navigator.userAgent;

    if (options.price == 0) {
        let pricing = await stripeService.getPrice();
        pricing = pricing.data;
        options.price = pricing.rawPrice;
        options.currency = pricing.currency;
    }

    fireCAPIEvent(options);
    fireTikTokConversionEvent(options);    
    fireGtmConversionEvent(options);
}