import http from "helpers/http.js";
import { sha256 } from "js-sha256";
import { ServerEvent, EventRequest, UserData, CustomData, Content, FacebookAdsApi } from "facebook-nodejs-business-sdk";

const accessToken = "EAAEjmafghgIBAEeWK2Q7CfPmb2HPpZC25qWU8WdJwQ9Q3c1QZCDHZB6uEITQbYMCHkKTG8ulXqhphOcgqOvdYQ1xP1b08CQCge2cBfOGEEUFCwE8ZBsn2CwNg95uvtEkF0JwpcZCwscPuUSPFuXCiEDxrLTuF5iTEcU33pZCZAp1AOHSnfly6GE";
const pixelId = 1513181152325903;

export const fireCAPIEvent = (options) => {
    window.fbq('track', options.eventName, {
        "currency": options.currency,
        "value": `${options.price}`,
        "content_name": "Pack 1",
        "content_type": "product",
        "content_category": "Big Screen Games",
        "contents": [{ 'id': 'P1', 'quantity': 1, }]
    });

    const api = FacebookAdsApi.init(accessToken);
    let current_timestamp = Math.floor(new Date() / 1000);

    const userData = (new UserData())
        .setClientIpAddress(options.ip)
        .setClientUserAgent(options.userAgent)
    const content = (new Content())
        .setId('P1')
        .setQuantity(1);
    const customData = (new CustomData())
        .setContents([content])
        .setValue(options.price)
        .setCurrency(options.currency);
    const serverEvent = (new ServerEvent())
        .setEventName(options.eventName)
        .setEventTime(current_timestamp)
        .setEventSourceUrl(window.location.href)
        .setUserData(userData)
        .setCustomData(customData)
        .setActionSource("website");

    const eventsData = [serverEvent];
    const eventRequest = (new EventRequest(accessToken, pixelId))
        .setEvents(eventsData);
    eventRequest.execute().then(
        response => {
            console.log('Response: ', response);
        },
        err => {
            console.error('Error: ', err);
        }
    );
    //let data = {
    //    "data": [
    //        {
    //            "event_name": options.eventName, 
    //            "event_time": Math.round((new Date()).getTime() / 1000), 
    //            "event_source_url": window.location.href,
    //            "action_source": "website",
    //            "user_data": {
    //                //"em": sha256(options.email ? options.email : "noemail@vocala.co"),
    //                "client_ip_address": options.ip,
    //                "client_user_agent": options.userAgent
    //            },
    //            "custom_data": {
    //                "currency": options.currency,
    //                "value": `${options.price}`,
    //                "content_name": "Pack 1",
    //                "content_type": "product",
    //                "content_category": "Big Screen Games",
    //                "contents": [{ 'id': 'P1', 'quantity': 1, }]
    //            }
    //        }
    //    ],
    //    //"test_event_code": "TEST1862",
    //};

    //var config = {
    //    method: 'post',
    //    url: "https://graph.facebook.com/v14.0/" + pixelId + "/events?access_token=" + accessToken,
    //    headers: {
    //        'Content-Type': 'application/json'
    //    },
    //    data,
    //};

    //return http(config)
    //    .then(function (response) {
    //        console.log(JSON.stringify(response.data));
    //    })
    //    .catch(function (error) {
    //        console.log(error);
    //    });
};