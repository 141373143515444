import React from 'react'
import { Component } from 'react';
import { Container, NavLink } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import LayoutBox from 'components/layout/layout-box/LayoutBox.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';
import LayoutBoxFooter from 'components/layout/layout-box/LayoutBoxFooter.js';
import styles from 'components/api-authorization/AuthorizationStyles.module.scss';
import http from 'helpers/http';
import landingBanner from 'images/landing/BigPotatoBanner.png';
import { getGames, applyGames, getPackOwnership } from 'actions/auth.js';
import store from 'store/reduxstore.js';
import { connect } from 'react-redux';
import { Modal, ModalBody, Accordion } from 'react-bootstrap';
import globalSettings from 'constants/globalSettings'
import globalSettingsService from 'services/globalSettings.service'

class VoucherCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            gamePacks: [],
            voucherCode: "",
            message: "",
            errorStatus: "",
            voucherExpiring: false,
            voucherStatusMessage: "",
            voucherPromotionMessage: "",
            userVouchers: [],
        }
    }

    componentDidMount() {
        if (this.props.auth.isLoggedIn) {
            let searchParams = new URLSearchParams(window.location.search);
            const voucher = searchParams.get('voucher');
            if (voucher) {
                this.setState({ voucherCode: voucher, });
            }
            this.setState({ loading: false, voucherExpiring: true, });
        } else {
            this.props.history.push("/authentication/register");
        }
        
    }

    updateVoucherCode = (event) => {
        this.setState({
            voucherCode: event.target.value,
            errorStatus: "",
            message: "",
        });
    }

    applyVoucherCode = async (event) => {
        event.preventDefault();
        if (!/^([a-zA-Z0-9]+)$/.test(this.state.voucherCode)) {
            this.setState({ errorStatus: "Error", message: "Invalid characters in Voucher." });
        } else {
            this.setState({ loading: true, });
            var response = await http.post(process.env.REACT_APP_API_URL + "api/Authenticate/ApplyVoucherCode", {
                voucherCode: this.state.voucherCode
            });
            console.log(response);
            this.setState({ loading: false, });
            if (response.data.status == "Success") {
                this.props.applyGames(response.data.games);
                this.props.getPackOwnership();
            }

            this.setState({
                errorStatus: response.data.status,
                message: response.data.message,
            });
        }
        this.setState({ voucherCode: "" });
    }

    render() {
        return (
            <div>
                <div className={styles.containerVoucher}>
                    <Container className="h-100 p-0 mt-5">
                        <div className="row justify-content-center w-100 h-100 m-0">
                            <LayoutBox className="card text-center my-auto p-0 mx-auto">
                                <LayoutBoxHeader className="card-header">
                                    <h5 className={styles.cardTitle + " card-title"}>Apply Voucher</h5>
                                </LayoutBoxHeader>
                                {
                                    this.state.loading ?
                                        <div className="card-body">
                                            <div className="spinner-border m-auto" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        :
                                        <LayoutBoxBody className={styles.cardBody + " card-body"}>
                                            <h4 style={{ textAlign: 'justify' }} className={styles.voucherTitle}>Your GamePacks:</h4>
                                            {
                                                this.state.voucherExpiring &&
                                                <div className="list-group list-group-flush">
                                                    {
                                                        this.props.auth.user.packs.map((voucher) => {
                                                            let iconColour = voucher.active ? voucher.message == "Active" ? "#198754" : "#FFC107" : "#DC3545";
                                                            return <div className="list-group-item">
                                                                <div className="d-flex w-100 justify-content-between">
                                                                    <h5 className={`${styles.voucherText} mb-1`}>{voucher.title}</h5>
                                                                </div>
                                                                <div className="d-flex w-100">
                                                                    <div className={`${styles.voucherStatusIcon} rounded my-auto`} style={{ backgroundColor: iconColour }}></div>
                                                                    <p className={`${styles.voucherText} my-auto mx-2`}>{voucher.message}</p>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                //<div className={styles.cardPDiv + " p-0"}>
                                                //    {this.state.voucherStatusMessage == "" ?
                                                //        <div style={{ marginBottom: "35px" }}>
                                                //            <p style={{ textAlign: 'justify' }} className={styles.cardP}>It appears your voucher code has now expired. Please purchase another code if you haven't already done so.</p>
                                                //            <p style={{ textAlign: 'justify' }} className={styles.cardP}>{this.state.voucherPromotionMessage}</p>
                                                //            <div className={styles.formGroupButtonPurchased + " form-group text-center"}>
                                                //                <Link tag={Link} className={styles.buyBtn + " btn mt-2"} to="/authentication/checkout" target="_blank">Buy now!</Link>
                                                //            </div>
                                                //        </div>
                                                //        :
                                                //        <div style={{ marginBottom: "35px" }}>
                                                //            <p style={{ textAlign: 'justify' }} className={styles.cardP}>{this.state.voucherStatusMessage}</p>
                                                //            <p style={{ textAlign: 'justify' }} className={styles.cardP}>{this.state.voucherPromotionMessage}</p>
                                                //            <div className={styles.formGroupButtonPurchased + " form-group text-center"}>
                                                //                <Link tag={Link} className={styles.buyBtn + " btn mt-2"} to="/authentication/checkout" target="_blank">Buy now!</Link>
                                                //            </div>
                                                //            <div>
                                                //                <NavLink tag={Link} className={styles.buyBtn + " btn mt-2"} to="/lobby">Continue To Lobby</NavLink>
                                                //            </div>
                                                //        </div>
                                                //    }
                                                //</div>
                                            }
                                            <div id="mygame">
                                                <form className={styles.formEnter + " mb-3 mt-4"} id="roomCodeForm">
                                                    <div className={styles.formGroup}>
                                                        <label className={styles.formEnterLabel} htmlFor="voucher">Enter your voucher code:</label><br />
                                                        <input className={styles.formEnterInput} type="text" autoComplete="off" id="voucher" name="voucher"
                                                            onChange={this.updateVoucherCode} value={this.state.voucherCode} required /> <br />
                                                        {
                                                            this.state.errorStatus == "Error" &&
                                                            <div className={styles.formGroup}>
                                                                <div className={styles.alertSilk + " alert alert-danger"} role="alert">
                                                                    {this.state.message}
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.errorStatus == "Success" &&
                                                            <div className={styles.formGroup}>
                                                                <div className={styles.alertSilk + " alert alert-success"} role="alert">
                                                                    {this.state.message}
                                                                </div>
                                                            </div>
                                                        }
                                                        {/*{this.state.error ? <label className="text-danger" htmlFor="voucher">Invalid Voucher Code</label>*/}
                                                        {/*    : <label className={`${this.state.errorStatus == "Success" ? "text-success" : "text-danger"}`}>{this.state.message}</label>}*/}
                                                    </div>
                                                    <input className={styles.submitBtn + " btn"} type="submit" value="Apply Code" onClick={this.applyVoucherCode} />
                                                </form>
                                            </div>
                                        </LayoutBoxBody>
                                }
                            </LayoutBox>
                        </div>
                    </Container>
                </div>

            </div>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        applyGames: (games) => {
            dispatch(applyGames(games));
        },
        getPackOwnership: () => {
            dispatch(getPackOwnership());
        }
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VoucherCode));