import { useState, useEffect } from "react";

export const usePasswordValidation = ({
  
firstPassword = "",
requiredLength = 8,
  
}) => {
const [validLength, setValidLength] = useState(null);
const [hasNumber, setHasNumber] = useState(null);
const [upperCase, setUpperCase] = useState(null);
const [lowerCase, setLowerCase] = useState(null);
//const [specialChar, setSpecialChar] = useState(null);
  
useEffect(() => {
  
setValidLength(firstPassword.length >= requiredLength ? true : false);
setUpperCase(firstPassword.toLowerCase() !== firstPassword);
setLowerCase(firstPassword.toUpperCase() !== firstPassword);
setHasNumber(/\d/.test(firstPassword));
//setSpecialChar(/^[A-Za-z0-9 ]+$/.test(firstPassword));
  
}, [firstPassword, requiredLength]);
  
return [validLength, hasNumber, upperCase, lowerCase];
};