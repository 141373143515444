import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import { connect, } from 'react-redux';
import Lottie from 'react-lottie';

import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';

import BlueBg from 'images/packs/blue-container-3.png';
import Pack1Tatos from 'images/packs/pack1-tatos.png';
import ScrawlIcon from 'images/scrawl-icon.png';
import HerdIcon from 'images/herd-icon.png';
import SquirmsIcon from 'images/squirms-icon.png';

import PurpleBg from 'images/packs/purple-container-2.png';
import Pack2Tatos from 'images/packs/pack2-tatos.png';
import HiddenIcon from 'images/packs/hidden-icon.png';

import Pack1Test from 'images/packs/pack1-test.png';
import Pack2Test from 'images/packs/pack2-test.png';

import styles from './Packs.module.scss';
import Pack1Overview from './Pack1Overview';
import Pack2Overview from './Pack2Overview';


class Packs extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }



    clickBuyNow = (packId) => {
        let checkoutPath = ApplicationPaths.Checkout;
        this.props.history.push(checkoutPath);
    }

    clickStartGame = (packId) => {
        let lobbyPath = "";
        switch (packId) {
            case 1:
                lobbyPath = "/lobby";
                break;
            case 2:
                lobbyPath = "/lobby2";
                break;
        }
        this.props.history.push(lobbyPath);
    }

    render() {
        return (

            <div className={`container-fluid mt-1`}>
                <div className={`${styles.packsRow} row`} >
                    <div className={`${styles.packContainer}`}>
                        <div className={`${styles.packItem} ${styles.pack1} row`}>
                            {/*<img className={styles.bgImage} src={BlueBg} />*/}
                            <div className={`${styles.titleCol} col-7`}>
                                <img className={styles.packTitle} src={Pack1Test} />
                                {/*<div className={`${styles.gameRow} row`}>*/}
                                {/*    <img className={styles.gameIcon} src={HerdIcon} />*/}
                                {/*    <img className={styles.gameIcon} src={ScrawlIcon} />*/}
                                {/*    <img className={styles.gameIcon} src={SquirmsIcon} />*/}
                                {/*</div>*/}
                            </div>
                            <div className={`${styles.overviewCol} col-5`}>
                                <Pack1Overview packsPage={true} />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.packContainer}`}>
                        <div className={`${styles.packItem} ${styles.pack2} row`}>
                            {/*<img className={styles.bgImage} src={PurpleBg} />*/}
                            <div className={`${styles.titleCol} col-7`}>
                                <img className={styles.packTitle} src={Pack2Test} />
                                {/*<div className={`${styles.gameRow} row`}>*/}
                                {/*    <img className={styles.gameIcon} src={HiddenIcon} />*/}
                                {/*    <img className={styles.gameIcon} src={HiddenIcon} />*/}
                                {/*    <img className={styles.gameIcon} src={HiddenIcon} />*/}
                                {/*</div>*/}
                            </div>
                            <div className={`${styles.overviewCol} col-5`}>
                                <Pack2Overview packsPage={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Packs);