import React, { Component } from 'react';

import Footer from 'components/Footer';

export class LayoutWithFooter extends Component {
    constructor(props) {
        super(props);
    }

    static displayName = LayoutWithFooter.name;

    render() {
        return (
            <React.Fragment>
                <div style={{ minHeight: "100%", }}>
                    {this.props.children}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
