import * as React from 'react';
import styles from './LayoutBox.module.scss';

export default class LayoutBoxFooter extends React.Component {
    render() {
        return (
            <div className={styles['footer'] + (this.props.className ? ' ' + this.props.className : '')}>
                {this.props.children}
            </div>
        );
    }
}