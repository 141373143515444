import http from "helpers/http.js";

const API_URL = process.env.REACT_APP_API_URL;

const createCheckoutSession = async (email, items) => {
    return await http.post(API_URL + "api/payments/create-checkout-session", {
        email,
        items,
    });
}

const generateCodeFromCheckout = async (sessionId) => {
    return await http.get(API_URL + "api/payments/checkout-success?sessionId=" + sessionId);
}

const createPaymentIntent = async (productIds) => {
    return await http.post(API_URL + "api/payments/create-intent", {
        items: productIds.map(x => { return { id: x } })
    });
};

const verifyAndGenerateActivationCode = async (paymentIntentId, email) => {
    return await http.post(API_URL + "api/payments/verify-and-generate", {
        paymentIntentId,
        email
    });
}

const getPrice = async () => {
    return await http.get(API_URL + "api/payments/get-price");
}

const getIP = async () => {
    return await http.get(API_URL + "api/payments/get-ip");
}

export default {
    createCheckoutSession,
    generateCodeFromCheckout,
    createPaymentIntent,
    verifyAndGenerateActivationCode,
    getPrice,
    getIP
};