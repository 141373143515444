import React from 'react'
import { Component } from 'react';
import http from "helpers/http.js";
import { Container, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import LayoutBox from 'components/layout/layout-box/LayoutBox.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';
import LayoutBoxFooter from 'components/layout/layout-box/LayoutBoxFooter.js';
import styles from 'components/api-authorization/AuthorizationStyles.module.scss';
import landingBanner from 'images/landing/BigPotatoBanner.png';
import potatoHappy from 'images/landing/PotatoHappy.png';

export class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            enterEmail: false,
            input: {},
            errors: {},
            message: '',
            successful: false,
            loading: false,
            isSilk: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
    }

    componentDidMount() {
        this.setSilk();
    }

    setSilk() {
        //this works to detect silk browser
        if (/\bSilk\b/.test(navigator.userAgent)) {
            this.setState({
                isSilk: true
            });
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            [name]: value,
            input
        });
    }

    validate() {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }

        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    handleForgotPassword = event => {
        event.preventDefault();

        if (this.validate()) {
            this.setState({
                loading: true
            });

            const API_URL = process.env.REACT_APP_API_URL;
            var data = JSON.stringify({
                "Email": this.state.email,
            });

            var configForgotPassword = {
                method: 'post',
                url: API_URL + 'api/Authenticate/forgot-password',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            return http(configForgotPassword)
                .then((response) => {
                    this.setState({
                        message: response.data.message,
                        successful: true,
                        loading: false
                    });
                    return response.data;

                }).catch((error) => {
                    this.setState({
                        message: error.response.data.message,
                        successful: false,
                        loading: false
                    });
                    return error.response.data;
                });
        }
    }

    render() {
        return (
            <div className={styles.containerBox}>
                <div className={styles.containerForgot}>
                    {/*<img className={this.state.isSilk ? styles.bannerImageSilk : styles.bannerImage} src={landingBanner} />*/}
                    <Container className="p-0 mt-5">
                        <div className={this.state.isSilk ? styles.rowCardSilk + " row justify-content-center w-100 h-100 m-0" : styles.rowCard + " row justify-content-center w-100 h-100 m-0"}>
                            <LayoutBox className="card text-center my-auto p-0 mx-auto">
                                <LayoutBoxHeader className="card-header mt-0">
                                    <h5 className={this.state.isSilk ? styles.cardTitleSilk + " card-title pb-0" : styles.cardTitle + " card-title pb-0"}>Forgot Password</h5>
                                </LayoutBoxHeader>
                                <LayoutBoxBody className={styles.cardBody + " card-body pt-0"}>
                                    <div className={styles.cardPDiv}>
                                        <p className={this.state.isSilk ? styles.cardPSilk : styles.cardP}>Please enter your email so we can find your account and reset your password.</p>
                                    </div>
                                    <Form className={styles.formEnter + " mb-3"} id="forgotPasswordForm" onSubmit={this.handleForgotPassword}>
                                        <div className={styles.cardPDiv}>
                                            <div className={styles.formGroupErrors}>
                                                <label className={this.state.isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="email">
                                                    Email
                                                </label>
                                                <br />
                                                <Input className={styles.formEnterInput}
                                                    type="text"
                                                    autoComplete="off"
                                                    id="email"
                                                    name="email"
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                    required
                                                    disabled={this.state.successful}
                                                />
                                                {this.state.errors.email ? <div className={styles.alertSilk + " alert alert-danger"} role="alert">{this.state.errors.email}</div> : ""}
                                            </div>

                                            {this.state.message && (
                                                <div className={styles.formGroup}>
                                                    <div className={this.state.successful ? styles.alertSilk + " alert alert-success" : styles.alertSilk + " alert alert-danger"} role="alert">
                                                        {this.state.message}
                                                    </div>
                                                </div>
                                            )}

                                            {!this.state.successful &&
                                                <div className={this.state.isSilk ? styles.formGroupButtonSilk + " position-relative form-group" : styles.formGroupButton + " position-relative form-group"}>
                                                <button className={this.state.isSilk ? styles.submitBtnSilk + " btn" : styles.submitBtn + " btn"} disabled={this.state.loading}>
                                                        {this.state.loading && (
                                                            <span className={styles.loadingSpinner + " spinner-border spinner-border mr-3"}></span>
                                                        )}
                                                        <span>Submit</span>
                                                    </button>
                                                    <img className={styles.potatoHappy} src={potatoHappy} width="48" />
                                                </div>
                                            }
                                        </div>
                                    </Form>
                                </LayoutBoxBody>
                                {this.state.successful &&
                                    <LayoutBoxFooter className="card-footer text-muted">
                                        <NavLink tag={Link} className={styles.buyBtn + " btn mt-2"} to="/authentication/Login">Login into account</NavLink>
                                    </LayoutBoxFooter>
                                }
                            </LayoutBox>
                        </div>
                    </Container>
                </div>
            </div>
        )
    }
}