import React, { useState, useRef, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from 'react-router-dom';
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Container, NavLink } from 'reactstrap';
import { Tooltip, Overlay } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import LayoutBox from 'components/layout/layout-box/LayoutBox.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';
import LayoutBoxFooter from 'components/layout/layout-box/LayoutBoxFooter.js';
import styles from 'components/api-authorization/AuthorizationStyles.module.scss';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLock, faLockOpen, faCheck } from "@fortawesome/free-solid-svg-icons";

import { login } from "actions/auth";
import landingBanner from 'images/landing/BigPotatoBanner.png';
import potatoHappy from 'images/landing/PotatoHappy.png';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import http from 'helpers/http';

//import { usePasswordValidation } from "actions/usePasswordValidation";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const vPassword = (value) => {
    if (value.length < 8) {
        return (
            <div className="alert alert-danger" role="alert">
                Your password must be at least 8 characters.
            </div>
        );
    }
};

const LoginBox = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    //const [
    //    validLength,
    //    hasNumber,
    //    upperCase,
    //    lowerCase,
    //    specialChar,
    //] = usePasswordValidation({
    //    firstPassword: password.firstPassword
    //});

    //const setFirst = (event) => {
    //    setPassword({ ...password, firstPassword: event.target.value });
    //};

    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("password");
    const [errors, setErrors] = useState({ email: "", password: "" });
    const [input, setInputs] = useState({});
    const [name, setName] = useState({});
    const forgotPasswordPath = `${ApplicationPaths.ForgotPassword}`;
    const [isSilk, setSilk] = useState(false);

    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const rememberMe = localStorage.getItem('rememberMe') === 'true';
        const emailLocal = rememberMe ? localStorage.getItem('email') : '';

        const queryParams = new URLSearchParams(window.location.search);
        const queryEmail = queryParams.get("email");

        if (isLoggedIn) {
            history.push("/");
        }

        if (queryEmail) {
            input["email"] = queryEmail;
            setEmail(queryEmail);
            setRememberMe(rememberMe);
        } else if (emailLocal != '') {
            input["email"] = emailLocal; 
            setEmail(emailLocal);
            setRememberMe(rememberMe);
        }

        setSilkLoad();
    }, []);

    const setSilkLoad = (e) => {
        if (/\bSilk\b/.test(navigator.userAgent)) {
            setSilk(true);
        }
    };

    const [show, setShow] = useState(false);
    const target = useRef(null);

    const onChangeEmail = (e) => {
        const email = e.target.value;
        const target = e.target;
        const name = target.name;

        input[e.target.name] = e.target.value;

        setEmail(email);
        setName(name);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        const target = e.target;
        const name = target.name;

        input[e.target.name] = e.target.value;

        setPassword(password);
        setName(name);
    };

    const onChangeRememberMe = (event) => {
        if (event.target.checked) {
            setRememberMe(true);
            localStorage.setItem('rememberMe', rememberMe);
        }
        else {
            setRememberMe(false);
            localStorage.setItem('rememberMe', false);
        }
    };

    const handleLogin = (e) => {
        e.preventDefault();

        if (validate()) {
            setLoading(true);

            form.current.validateAll();

            if (rememberMe) {
                localStorage.setItem('rememberMe', rememberMe);
                localStorage.setItem('email', rememberMe ? email : '');
            }

            if (checkBtn.current.context._errors.length === 0) {
                dispatch(login(email, password))
                    .then((e) => {
                        http.get(process.env.REACT_APP_API_URL + "api/Authenticate/CheckVoucherExpires").then((response) => {
                            if (response.data.status === "Success") {
                                history.push({
                                    pathname: "/authentication/voucher-code"
                                });
                            } else {
                                history.push({
                                    pathname: "/packs"
                                });
                            }
                        });
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }
        }
    };

    const validate = (e) => {
        let isValid = true;

        if (!input["email"]) {
            isValid = false;
            errors.email = "Please enter your email Address.";
            const enterEmail = { email: errors.email };
            setErrors(enterEmail);
        }
        else {
            setErrors({ email: "" });
        }

        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors.email = "Please enter valid email address.";
                const newEmail = { email: errors.email };
                setErrors(newEmail);
            }
            else {
                setErrors({ email: "" });
            }
        }

        if (!input["password"]) {
            isValid = false;
            errors.password = "Please enter a valid password.";
            const enterPassword = { password: errors.password };
            setErrors(enterPassword);
        }
        else {
            setErrors({ password: "" });
        }

        return isValid;
    }

    const showHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (type === 'input') {
            setType('password');
        }
        else {
            setType('input');
        }
    }

    return (
        <div className={styles.containerLogin}>
            {/*<img className={isSilk ? styles.bannerImageSilk + " position-relative" : styles.bannerImage + " position-relative"} src={landingBanner} />*/}
            <Container className="h-100 p-0 mt-5">
                <div className={isSilk ? styles.rowCardSilk + " row justify-content-center w-100 h-100 m-0" : styles.rowCard + " row justify-content-center w-100 h-100 m-0"}>
                    <LayoutBox className="card text-center mt-0 my-auto p-0 mx-auto">
                        <LayoutBoxHeader className="card-header mt-0">
                            <h5 className={isSilk ? styles.cardTitleSilk + " card-title pb-0" : styles.cardTitle + " card-title pb-0"}>login to start<br className={isSilk ? styles.brCardTitleSilk : styles.brCardTitle} /> a new game</h5>
                        </LayoutBoxHeader>
                        <LayoutBoxBody className={styles.cardBody + " card-body pt-0"}>
                            <div>
                                <Form className={styles.formEnter + " mb-3"} id="loginForm" onSubmit={handleLogin} ref={form}>
                                    <div className={styles.cardPDiv}>
                                        <div className={isSilk ? styles.formGroupSilk : styles.formGroup}>
                                            <label className={isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="email">
                                                Email
                                            </label>
                                            <br />
                                            <input className={styles.formEnterInput}
                                                type="text"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={onChangeEmail}
                                                validations={[required]}
                                                required
                                            />
                                            {errors.email ? <div className={styles.alertSilk + " alert alert-danger"} role="alert">{errors.email}</div> : ""}
                                        </div>
                                        <div className={styles.formGroupErrors + " " + styles.pass_wrapper}>
                                            <label className={isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="password">
                                                Password
                                                <NavLink to={forgotPasswordPath} tag={Link} className={isSilk ? styles.forgottenLinkSilk + " text-center" : styles.forgottenLink + " text-center"}>
                                                    Forgot your password?
                                                </NavLink>
                                            </label>
                                            <br />
                                            <input className={styles.formEnterInput}
                                                type={type}
                                                autoComplete="off"
                                                id="password"
                                                name="password"
                                                value={password}
                                                onChange={onChangePassword}
                                                validations={[required, vPassword]}
                                                required
                                            />
                                            <i onClick={showHide} ref={target} className={isSilk ? styles.eyeSilk : styles.eye}>{type === 'input' ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</i>
                                            <ReactIsCapsLockActive>
                                                {active => <Overlay target={target.current} show={active ? true : false} placement="right">
                                                    {(props) => (
                                                        <Tooltip className="tooltipCaps" id="overlayCaps" {...props}>WARING: CAPS Lock is {active ? 'on' : 'off'}</Tooltip>
                                                    )}
                                                </Overlay>}
                                            </ReactIsCapsLockActive>
                                        </div>

                                        {message && (
                                            <div className={styles.formGroup}>
                                                <div className={styles.alertSilk + " alert alert-danger"} role="alert">
                                                    {message}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className={styles.formGroup + " text-center"}>
                                        <label className={styles.rememberMe}>
                                            <input name="rememberMe" checked={rememberMe} onChange={onChangeRememberMe} type="checkbox" /> Remember me
                                        </label>
                                    </div>

                                    <div className={isSilk ? styles.formGroupButtonSilk + " position-relative form-group" : styles.formGroupButton + " position-relative form-group"}>
                                        <button className={isSilk ? styles.submitBtnSilk + " btn" : styles.submitBtn + " btn"} disabled={loading}>
                                            {loading && (
                                                <span className={styles.loadingSpinner + " spinner-border spinner-border mr-3"}></span>
                                            )}
                                            <span>Login</span>
                                        </button>
                                        <img className={styles.potatoHappy} src={potatoHappy} width="48" />
                                    </div>

                                    <CheckButton style={{ display: "none" }} ref={checkBtn} />

                                    <div className={isSilk ? styles.formGroupButtonPurchasedSilk + " form-group text-center" : styles.formGroupButtonPurchased + " form-group text-center"}>
                                        <NavLink tag={Link} className={isSilk ? styles.buyBtnSilk + " btn mt-2" : styles.buyBtn + " btn mt-2"} to="/authentication/register">Just Purchased A Voucher Code? Click Here!</NavLink>
                                    </div>
                                </Form>
                            </div>
                        </LayoutBoxBody>
                    </LayoutBox>
                </div>
            </Container>
        </div>
    );
};

export default LoginBox;