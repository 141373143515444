import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    CLEAR_MESSAGE,
    APPLY_GAMES,
    UPDATE_OWNERSHIP
} from "actions/types";
import { history } from "helpers/history";

import AuthService from "services/auth.service";

export const register = (e, email, password, voucherCode, gameSelection) => (dispatch) => {
    dispatch({
        type: CLEAR_MESSAGE
    });
    return AuthService.register(e, email, password.firstPassword, voucherCode, gameSelection).then(
        (response) => {
            if (response.status == "Error") {
                const message =
                    response.message ||
                    response.message.toString();

                dispatch({
                    type: LOGIN_FAIL,
                });

                let list = null;
                if (response.messageList) {
                    list = response.messageList
                }

                dispatch({
                    type: SET_MESSAGE,
                    payload: {
                        message,
                        list: list
                    },
                });

                return Promise.reject();
            }
            else {
                dispatch({
                    type: REGISTER_SUCCESS,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: {
                        message: response.data.message
                    },
                });

                return response;
            }
        },
        (error) => {
            const messageError =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: { message: messageError },
            });

            return Promise.reject();
        }
    );
};

export const login = (email, password) => (dispatch) => {
    return AuthService.login(email, password).then(
        (response) => {
            if (response.isAuthenticated == true) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: response },
                });
                dispatch(getPackOwnership());

                return Promise.resolve();
            }
            else {
                const messageRes =
                    response.message ||
                    response.message.toString();

                dispatch({
                    type: LOGIN_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: messageRes }
                });

                return Promise.reject();
            }
        },
        (error) => {
            const messageError =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: { message: messageError }
            });

            return Promise.reject();
        }
    );
};

export const getGames = (email) => (dispatch) => {
    return AuthService.getGames(email).then(
        (response) => {
            if (response.isAuthenticated == true) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: response },
                });

                return Promise.resolve();
            }
            else {
                const messageRes =
                    response.message ||
                    response.message.toString();

                dispatch({
                    type: LOGIN_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: messageRes }
                });

                return Promise.reject();
            }
        },
        (error) => {
            const messageError =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: { message: messageError }
            });

            return Promise.reject();
        }
    );
};

export const applyGames = (games) => {
    AuthService.applyGames(games);

    return {
        type: APPLY_GAMES,
        payload: games
    };
};

export const getPackOwnership = () => (dispatch) => {
    return AuthService.getPackOwnership().then((res) => {
        if (res.status == "Success" || res.status == "Ok") {
            dispatch({
                type: UPDATE_OWNERSHIP,
                payload: res.vouchers,
            });
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    }).catch((err) => {
        console.log(err);
        return Promise.reject();
    });

}

export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
        type: LOGOUT,
    });

    history.push('/');
};

export const clearMessage = () => (dispatch) => {
    dispatch({
        type: CLEAR_MESSAGE
    });
}

export const checkUserGamePack = () => (dispatch) => {
    return AuthService.checkUserGamePack().then(
        (response) => {
            if (response.status == "Success") {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        },
        (error) => {
            return Promise.reject();
        }
    );
};

export const checkUserExists = (email) => (dispatch) => {
    return AuthService.checkUserExists(email).then(
        (response) => {
            if (response.status == "Success") {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        },
        (error) => {
            return Promise.reject();
        }
    );
};


export const checkUserHasSteamKey = (email) => (dispatch) => {
    return AuthService.checkUserHasSteamKey(email).then(
        (response) => {
            if (response.status == "Success") {
                return Promise.resolve();
            } else {

                return Promise.reject();
            }
        },
        (error) => {
            return Promise.reject();
        }
    );
};