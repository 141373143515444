import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import { Link, } from 'react-router-dom';

import styles from "components/FooterStyles.module.scss";

export default class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

       
    }

    getCurrentYear() {
        const date = new Date();
        const year = date.getFullYear();
        return year;
    }

    render() {
        const pathname = window.location.pathname;
        return (
            !pathname.includes("lobby") &&
            <div className={`container-fluid ${styles.footerContainer} mt-4`}>
                {/* Policies */}
                <div align="center" className="container py-4">
                    <div className={`row ${styles.linksRow}`}>
                        <div className={styles.linkText}>
                            <Link to="/terms">Terms & Conditions</Link>
                        </div>
                        <div className={styles.linkText}>
                            <Link to="/privacy">Privacy Policy</Link>
                        </div>
                        <div className={styles.linkText}>
                            <Link to="/cookies">Cookie Policy</Link>
                        </div>
                        <div className={styles.linkText}>
                            <Link to="/data">Data Protection</Link>
                        </div>
                    </div>

                    <div style={{ clear: 'both' }}>
                    </div>
                    <div className={`pt-3 ${styles.copyrightText}`} style={{ textAlign: "center" }}>
                        &copy; {this.getCurrentYear()} The Games Collective, Ltd. All rights reserved.
                    </div>
                </div>
            </div>
        )
    }

}