import React from 'react'
import { Component } from 'react';
import http from "helpers/http.js";
import { Container, NavLink } from 'reactstrap';
import { Tooltip, Overlay } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import LayoutBox from 'components/layout/layout-box/LayoutBox.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';
import LayoutBoxFooter from 'components/layout/layout-box/LayoutBoxFooter.js';
import styles from 'components/api-authorization/AuthorizationStyles.module.scss';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faCheck } from "@fortawesome/free-solid-svg-icons";
import landingBanner from 'images/landing/BigPotatoBanner.png';
import potatoHappy from 'images/landing/PotatoHappy.png';

import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';

export class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            token: '',
            password: '',
            confirmPassword: '',
            enterEmail: false,
            input: {},
            errors: {},
            type: 'password',
            loading: false,
            message: '',
            successful: false,
            isSilk: false
        }

        this.validate = this.validate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.showHide = this.showHide.bind(this);

        this.target = React.createRef();
    }

    componentDidMount = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const email = urlParams.get('email');
        const token = urlParams.get('tokenReset');

        var emailString = "";
        var tokenString = "";
        if (email || token) {
            emailString = email.toString();
            tokenString = token.toString();

            this.setState({
                email: emailString,
                token: tokenString
            });
        }

        this.setSilk();
    }

    setSilk() {
        //this works to detect silk browser
        if (/\bSilk\b/.test(navigator.userAgent)) {
            this.setState({
                isSilk: true
            });
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            [name]: value,
            input
        });

        this.validate();
    }

    validate() {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter a new password.";
        }

        if (!input["confirmPassword"]) {
            isValid = false;
            errors["confirmPassword"] = "Please ensure your new password and confirmation password match.";
        }

        if (typeof input["password"] !== "undefined" && typeof input["confirmPassword"] !== "undefined") {

            if (input["password"] != input["confirmPassword"]) {
                isValid = false;
                errors["password"] = "Passwords don't match.";
            }
            else {
                if (input["password"].length < 7 &&
                    input["password"].toLowerCase() !== input["password"] &&
                    input["password"].toUpperCase() !== input["password"] &&
                    /\d/.test(input["password"]) &&
                    /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(input["password"])) {
                    isValid = false;
                    errors["password"] = "Password reset invalid, please make sure all validations are met.";
                }
                else {
                    if (input["password"].length < 7) {
                        isValid = false;
                        errors["password"] = "Password length not met.";
                    }
                    else {
                        isValid = true;
                        errors["password"] = "";
                    }
                }
            }
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    handleResetPassword = event => {
        event.preventDefault();

        if (this.validate()) {
            this.setState({
                loading: true
            });

            const API_URL = process.env.REACT_APP_API_URL;
            var data = JSON.stringify({
                "Email": this.state.email,
                "Token": this.state.token,
                "Password": this.state.password,
                "ConfirmPassword": this.state.confirmPassword
            });

            var configReset = {
                method: 'post',
                url: API_URL + 'api/Authenticate/reset-password',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            return http(configReset)
                .then((response) => {
                    this.setState({
                        message: response.data.message,
                        successful: true,
                        loading: false
                    });
                    return response.data;

                }).catch((error) => {
                    this.setState({
                        message: error.response.data.message,
                        successful: false,
                        loading: false
                    });
                    return error.response.data;
                    console.log(error);
                });
        }
    }

    showHide(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input'
        })
    }

    render() {
        return (
            <div className={styles.containerBox}>
                <div className={styles.containerReset}>
                    {/*<img className={this.state.isSilk ? styles.bannerImageSilk + " position-relative" : styles.bannerImage + " position-relative"} src={landingBanner} />*/}
                    <Container className="h-100 p-0 mt-5">
                        <div className={this.state.isSilk ? styles.rowCardSilk + " row justify-content-center w-100 h-100 m-0" : styles.rowCard + " row justify-content-center w-100 h-100 m-0"}>
                            <LayoutBox className="card text-center my-auto mt-0 p-0 mx-auto">
                                <LayoutBoxHeader className="card-header mt-0">
                                    <h5 className={this.state.isSilk ? styles.cardTitleSilk + " card-title pb-0" : styles.cardTitle + " card-title pb-0"}>Reset Password</h5>
                                </LayoutBoxHeader>
                                <LayoutBoxBody className={styles.cardBody + " card-body pt-0"}>
                                    <Form className={styles.formEnter + " mb-3"} id="forgotPasswordForm" onSubmit={this.handleResetPassword}>
                                        {this.state.successful ?
                                            <div className={styles.cardPDiv}>
                                                {this.state.message && (
                                                    <div className="form-group mt-3">
                                                        <div className={this.state.successful ? styles.alertSilk + " alert alert-success" : styles.alertSilk + " alert alert-danger"} role="alert">
                                                            {this.state.message}
                                                        </div>
                                                    </div>
                                                )}
                                            </div> :
                                            <div className={styles.cardPDiv}>
                                                <div className={styles.formGroup + " " + styles.pass_wrapper}>
                                                    <label className={this.state.isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="password">
                                                        New Password
                                                    </label>
                                                    <br />
                                                    <Input className={styles.formEnterInput}
                                                        type={this.state.type}
                                                        autoComplete="off"
                                                        id="password"
                                                        name="password"
                                                        value={this.state.password}
                                                        onChange={this.handleChange}
                                                        required
                                                        disabled={this.state.successful}
                                                    />
                                                    {!this.state.successful &&
                                                        <i onClick={this.showHide} className={this.state.isSilk ? styles.eyeSilk : styles.eye}>{this.state.type === 'input' ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</i>
                                                    }
                                                    {this.state.errors.password ? <div className={styles.alertSilk + " alert alert-danger"} role="alert">{this.state.errors.password}</div> : ""}
                                                </div>
                                                <div className={styles.formGroupErrors + " " + styles.pass_wrapper}>
                                                    <label className={this.state.isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="confirmPassword">
                                                        Confirm New Password
                                                    </label>
                                                    <br />
                                                    <Input className={styles.formEnterInput}
                                                        type={this.state.type}
                                                        autoComplete="off"
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        value={this.state.confirmPassword}
                                                        onChange={this.handleChange}
                                                        required
                                                        disabled={this.state.successful}
                                                    />
                                                    {!this.state.successful &&
                                                        <i onClick={this.showHide} ref={this.target} className={this.state.isSilk ? styles.eyeSilk : styles.eye}>{this.state.type === 'input' ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</i>
                                                    }
                                                    {this.state.errors.confirmPassword ? <div className={styles.alertSilk + " alert alert-danger"} role="alert">{this.state.errors.confirmPassword}</div> : ""}
                                                    {this.state.password.length >= 8 &&
                                                        /\d/.test(this.state.password) &&
                                                        this.state.password.toUpperCase() &&
                                                        this.state.password.toLowerCase() &&
                                                        this.state.password === this.state.confirmPassword &&
                                                        <i className={styles.passwordCheck}><FontAwesomeIcon icon={faCheck} /></i>
                                                    }

                                                    <ReactIsCapsLockActive>
                                                        {active => <Overlay target={this.target.current} show={active ? true : false} placement="right">
                                                            {(props) => (
                                                                <Tooltip className="tooltipCaps" id="overlayCaps" {...props}>WARING: CAPS Lock is {active ? 'on' : 'off'}</Tooltip>
                                                            )}
                                                        </Overlay>}
                                                    </ReactIsCapsLockActive>
                                                </div>
                                                <div className={styles.formGroup}>
                                                    <div>
                                                        <ul className={this.state.isSilk ? styles.paddingListErrorsSilk : styles.paddingListErrors}>
                                                            <li className={this.state.password.length >= 8 ? "text-success" : "text-danger"}>
                                                                {this.state.password.length >= 8 ? <span className="text-success">Your password must be at least 8 characters</span> : <span className="text-danger">Your password must be at least 8 characters</span>}
                                                            </li>
                                                            <li className={/\d/.test(this.state.password) ? "text-success" : "text-danger"}>
                                                                {/\d/.test(this.state.password) ? <span className="text-success">Your password must include a number</span> : <span className="text-danger">Your password must include a number</span>}
                                                            </li>
                                                            <li className={this.state.password.toLowerCase() !== this.state.password ? "text-success" : "text-danger"}>
                                                                {this.state.password.toLowerCase() !== this.state.password ? <span className="text-success">Your password must include an upper case letter</span> : <span className="text-danger">Your password must include an upper case letter</span>}
                                                            </li>
                                                            <li className={this.state.password.toUpperCase() !== this.state.password ? "text-success" : "text-danger"}>
                                                                {this.state.password.toUpperCase() !== this.state.password ? <span className="text-success">Your password must include a lower case letter</span> : <span className="text-danger">Your password must include a lower case letter</span>}
                                                            </li>
                                                            {/*<li className={/^[A-Za-z0-9 ]+$/.test(this.state.password) ? "text-success" : "text-danger"}>*/}
                                                            {/*    {/^[A-Za-z0-9 ]+$/.test(this.state.password) ? <span className="text-success">Cannot contain any symbols</span> : <span className="text-danger">Cannot contain any symbols</span>}*/}
                                                            {/*</li>*/}
                                                            <li className={this.state.password === this.state.confirmPassword ? "text-success" : "text-danger"}>
                                                                {this.state.password === this.state.confirmPassword ? <span className="text-success">Passwords do match</span> : <span className="text-danger">Passwords don't match</span>}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className={styles.formGroup}>
                                                    <input className={styles.formEnterInput}
                                                        type="hidden"
                                                        autoComplete="off"
                                                        id="email"
                                                        name="email"
                                                        value={this.state.email}
                                                        required
                                                    />
                                                </div>
                                                <div className={styles.formGroup}>
                                                    <input className={styles.formEnterInput}
                                                        type="hidden"
                                                        autoComplete="off"
                                                        id="token"
                                                        name="token"
                                                        value={this.state.token}
                                                        required
                                                    />
                                                </div>

                                                {this.state.message && (
                                                    <div className="form-group mt-3">
                                                        <div className={this.state.successful ? styles.alertSilk + " alert alert-success" : styles.alertSilk + " alert alert-danger"} role="alert">
                                                            {this.state.message}
                                                        </div>
                                                    </div>
                                                )}

                                                {!this.state.successful &&
                                                    <div className={this.state.isSilk ? styles.formGroupButtonSilk + " position-relative form-group" : styles.formGroupButton + " position-relative form-group"}>
                                                        <button className={styles.submitBtn + " btn"} disabled={this.state.loading}>
                                                            {this.state.loading && (
                                                                <span className={styles.loadingSpinner + " spinner-border spinner-border mr-3"}></span>
                                                            )}
                                                            <span>Submit</span>
                                                        </button>
                                                        <img className={styles.potatoHappy} src={potatoHappy} width="48" />
                                                    </div>
                                                }

                                            </div>
                                        }
                                    </Form>
                                </LayoutBoxBody>
                                {this.state.successful &&
                                    <LayoutBoxFooter className="card-footer text-muted">
                                        <NavLink tag={Link} className={styles.buyBtn + " btn mt-2"} to="/authentication/Login">Login into account</NavLink>
                                    </LayoutBoxFooter>
                                }
                            </LayoutBox>
                        </div>
                    </Container>
                </div>
            </div>
        )
    }
}