import React from 'react';
import styles from './Home.module.scss';
import http from 'helpers/http';
import { Toast, ToastBody, ToastHeader } from "reactstrap";

export default class Banner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bannerShow: "",
            message: "",
            show: true
        }
    }

    async componentDidMount() {
        //var response = await http.get(process.env.REACT_APP_API_URL + "api/Authenticate/CheckVoucherExpires");
        //console.log(response);
        //this.setState({
        //    bannerShow: response.data.status,
        //    message: response.data.message
        //});
        //console.log(this.state.bannerShow);
        //setTimeout(() => this.setState({ show: false }), 6000); // ?? This is not right only reason to do this is because you want to AWAIT the async call. Putting in a random timeout to wait for it to respond is a bit risk. 
    }

    renderInnerHTMLForNotifications() {
        var value = this.state.message;
        return { __html: value };
    }

    voucherPage = () => {
        this.props.history.push("/authentication/voucher-code");
    }

    render() {
        return (
            <React.Fragment>
                {this.state.bannerShow === "Success" &&
                    this.state.show &&
                    <Toast style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        left: "0",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "500px",
                        height: "200px",
                        fontSize:"20px",
                        zIndex: "1000", 
                }} autohide={this.state.show}>
                        <ToastHeader>
                            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                            <strong className="me-auto">Voucher Notifications!</strong>
                        </ToastHeader>
                        <ToastBody>
                            <div dangerouslySetInnerHTML={this.renderInnerHTMLForNotifications()}>
                            </div>
                        </ToastBody>
                    </Toast>}
            </React.Fragment>
        )
    }
}
