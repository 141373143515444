import axios from "axios";
import store from 'store/reduxstore.js';
import { logout } from 'actions/auth.js';
const instance = axios.create();

instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token) {
        config.headers['Authorization'] = 'Bearer ' + user.token;
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.data.message == "It looks like this link expired we’ve sent you a new one please check your inbox.") {
        // Confirm email resend if token invalid
        console.log(error.response.data.message);
        return Promise.reject(error);
    }
    else {
        if (error.response && error.response.status === 401) {
            // Just log out the user
            store.dispatch(logout());
        }
        return Promise.reject(error);
    }
});

export default instance;