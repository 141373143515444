import http from "helpers/http.js";
import gameType from "constants/gameSelection";

const API_URL = process.env.REACT_APP_API_URL;

const register = (e, email, password, voucherCode, gameSelection) => {
    var data = JSON.stringify({
        "Email": email,
        "Password": password,
        "VoucherCode": voucherCode,
        "GameSelection": parseInt(gameSelection)
    });

    var config = {
        method: 'post',
        url: API_URL + 'api/Authenticate/register',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return http(config)
        .then(function (response) {
            e.preventDefault();
            console.log(JSON.stringify(response.data));
            const status = response.status;
            const key = response.data.key;
            var dataGet = JSON.parse(response.config.data);
            const email = dataGet.Email;
            //redirect logic
            if (status == 200) {
                //if (gameSelection == gameType.Web) {
                //    window.location = "authentication/welcome?email=" + email;
                //} else {
                //    window.location = "authentication/welcome?email=" + email + "&key=" + key;
                //}
            }
            return response;
        })
        .catch(function (error) {
            console.log(error);
            return error.response.data;
        });
};

const login = (email, password) => {
    var data = JSON.stringify({
        "Email": email,
        "Password": password
    });

    var configLogin = {
        method: 'post',
        url: API_URL + 'api/Authenticate/login',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return http(configLogin)
        .then(function (response) {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            localStorage.setItem("games", JSON.stringify(response.data.games.length));
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
};

const getGames = (email) => {
    var data = JSON.stringify({
        "Email": email
    });

    var configGetGames = {
        method: 'get',
        url: API_URL + 'api/Authenticate/GetGames?' + email,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return http(configGetGames)
        .then(function (response) {
            localStorage.setItem("games", JSON.stringify(response.data.games.length));
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
};

const getPackOwnership = async () => {
    var configGetGames = {
        method: 'get',
        url: API_URL + 'api/Authenticate/CheckVoucherExpires',
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return http(configGetGames)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
}


const applyGames = (games) => {
    var user = JSON.parse(localStorage.getItem('user'));
    user.games = games;
    localStorage.setItem("user", JSON.stringify(user));
}

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("games");
};

const checkUserGamePack = () => {
    var configCheckUser = {
        method: 'post',
        url: API_URL + 'api/Authenticate/CheckUserGamePack',
        headers: {
            'Content-Type': 'application/json'
        },
    };

    return http(configCheckUser)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
}

const checkUserExists = (email) => {
    var data = JSON.stringify({
        "Email": email
    });

    var configCheckUser = {
        method: 'post',
        url: API_URL + 'api/Authenticate/CheckUserExists',
        headers: {
            'Content-Type': 'application/json'
        },
        data,
    };

    return http(configCheckUser)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
}

const checkUserHasSteamKey = (email) => {
    var data = JSON.stringify({
        "Email": email
    });

    var configCheckUserSteamKey = {
        method: 'post',
        url: API_URL + 'api/Authenticate/CheckUserHasSteamKey',
        headers: {
            'Content-Type': 'application/json'
        },
        data,
    };

    return http(configCheckUserSteamKey)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
}

export default {
    register,
    login,
    logout,
    getGames,
    applyGames,
    getPackOwnership,
    checkUserGamePack,
    checkUserExists,
    checkUserHasSteamKey,
};
