import {
    UPDATE_CART
} from "actions/types";
import store from 'store/reduxstore';

export const addToCart = (item) => {
    const currentCart = store.getState().cart.items;

    const itemIndex = currentCart.findIndex(x => x.packId == item.packId);
    if (itemIndex >= 0) {
        currentCart[itemIndex].quantity++;
        currentCart[itemIndex].price = calculateItemTotal(currentCart[itemIndex].quantity, currentCart[itemIndex].originalPrice);
    } else {
        item.quantity = 1;
        item.name = "Big Screen Games - Pack " + item.packId;
        item.originalPrice = item.price;
        currentCart.push(item);
    }

    return {
        type: UPDATE_CART,
        payload: {
            items: currentCart,
        },
    }
};

export const removeFromCart = (packId) => {
    const currentCart = store.getState().cart.items;

    const itemIndex = currentCart.findIndex(x => x.packId == packId);
    if (itemIndex >= 0) {
        currentCart.splice(itemIndex, 1);
    }

    return {
        type: UPDATE_CART,
        payload: {
            items: currentCart,
        },
    }
}

export const increaseQuantity = (packId) => {
    const currentCart = store.getState().cart.items;

    const itemIndex = currentCart.findIndex(x => x.packId == packId);
    if (itemIndex >= 0) {
        currentCart[itemIndex].quantity++;
        currentCart[itemIndex].price = calculateItemTotal(currentCart[itemIndex].quantity, currentCart[itemIndex].originalPrice);
    } 

    return {
        type: UPDATE_CART,
        payload: {
            items: currentCart,
        },
    }
}

export const lowerQuantity = (packId) => {
    const currentCart = store.getState().cart.items;

    const itemIndex = currentCart.findIndex(x => x.packId == packId);
    if (itemIndex >= 0) {
        currentCart[itemIndex].quantity--;
        currentCart[itemIndex].price = calculateItemTotal(currentCart[itemIndex].quantity, currentCart[itemIndex].originalPrice);
        if (currentCart[itemIndex].quantity <= 0) {
            currentCart.splice(itemIndex, 1);
        }
    }

    return {
        type: UPDATE_CART,
        payload: {
            items: currentCart,
        },
    }
}

const calculateItemTotal = (quantity, price) => {
    let itemTotal = 0;
    itemTotal = price * quantity;
    const formattedItemTotal = round(itemTotal);
    return formattedItemTotal;
}

function round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}

