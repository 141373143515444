import { Alert, NavLink } from 'reactstrap';
import { Modal, ModalBody, Accordion, CloseButton } from 'react-bootstrap';
import { Route, withRouter } from 'react-router'
import React, { Component } from 'react';
import Lottie from 'react-lottie';
import axios from 'axios';
import http from 'helpers/http.js';
import * as Colyseus from "colyseus.js";
import getAvatarById from "constants/avatars";
import Loading from "./Loading.js";
import styles from './Home.module.scss';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import "animate.css";

import Footer from "components/Footer";
import LayoutBox from 'components/layout/layout-box/LayoutBox.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';
import Banner from './BannerGamePack';

import chevronDown from 'images/landing/ChevronDown.png';
import potatoMobile from 'images/landing/JoinFromPhone.png';
import potatoBuy from 'images/packs/buy-here-icon.png';
import potatoHappy from 'images/landing/PotatoHappy.png';
import faq from 'images/landing/Asset_FAQ_resized.png';
//import potatosTV from 'images/landing/landing-page-img-500.png';
import potatosTV from "images/packs/PackLogo.png";

import sadPotato from 'animations/sadPotato.js';

import promotionService from "services/promotion.service.js";

import nosleep from 'nosleep.js';
import { UAParser } from "ua-parser-js";
var noSleep = new nosleep();

const supportsWebSockets = window.WebSocket;

const gameIds = {
    Lobby: "lobby",
    TemplateGame: "template_game",
    CanOfSquirms: "can_of_squirms",
    Scrawl: "scrawl",
    HerdMentality: "herd_mentality",
    ColourBrain: "colour_brain",
    Chameleon: "chameleon",
    DrawWith: "draw_with",
    Pool: "pool",
};

const animations = [
    "bounce",
    "rubberBand",
    "shakeX",
    "shakeY",
    "headShake",
    "swing",
    "tada",
    "wobble",
    "jello",
    "heartBeat",
];

const segments = [
    'better luck next time',
    'won 70',
    'won 10',
    'better luck next time',
    'won 2',
    'won uber pass',
    'better luck next time',
    'won a voucher'
]
const segColors = [
    '#EE4040',
    '#F0CF50',
    '#815CD1',
    '#3DA5E0',
    '#34A24F',
    '#F9AA1F',
    '#EC3F3F',
    '#FF9000'
]

class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);

        this.state = {
            inRoom: false,
            roomId: 0,
            roomState: null,
            room: null,
            myId: null,
            redirect: null,
            redirectURL: null,
            loading: false,
            joining: false,
            chosenAvatar: 0,
            appliedAvatar: 0,
            formRoomId: "",
            formNickname: "",
            fields: {},
            errors: {},
            showError: false,
            errorMessage: "",
            avatarList: [],
            storedRoomId: null,
            storedSessionId: null,
            player: {},
            changePotato: true,
            isOpenJoin: false,
            textStartGame: false,
            textJoinGame: false,
            isOpenOverlay: false,
            isOpenFaqs: false,
            isOpenLogin: false,
            isOpenReconnect: false,
            isOpenPromotion: false,
            isOpenLockedRoom: false,
            isSilk: false,
            source: "https://www.youtube.com/watch?v=W60H_Xvi4qw",
            shouldPlay: false,
            isIpad: false,
            badBrowser: false,
            messsage: null, // voucher expire message
            canJoin: false,
            joinButtonValue: "Join",

            currentPromotion: null,
            awardedPromotionCode: {},
            promotionEmail: "",
            promotionSubmitting: false,
            promotionSuccess: false,
            promotionGenerated: false,
            promotionError: null,
        };

        this.locationCheckInterval = null;
        this.cancelToken = null;
    }

    async componentDidMount() {
        this.browserVersion();


        //ReactGA.pageview(window.location.pathname + window.location.search);
        //ReactGAVocala.pageview(window.location.pathname + window.location.search);
        //document.body.style.overflowY = "hidden";

        const nickName = this.getNicknameFromStorage();
        let fields = this.state.fields;
        fields["formNickname"] = nickName;
        this.setState({ formNickname: nickName, fields, });

        let qrRoomCode = this.getQueryStringValue("qrCode");

        const queryRoomId = this.getQueryStringValue("roomId");
        const querySessionId = this.getQueryStringValue("sessionId");

        const storedRoomId = this.getRoomIdFromStorage();
        const storedSessionId = this.getSessionIdFromStorage();
        console.log(`stored room id : ` + storedRoomId);

        const showJoin = this.getQueryStringValue("showJoin");
        const showfaq = this.getQueryStringValue("showfaq");

        this.setState({ storedRoomId, storedSessionId });

        if (storedRoomId && !queryRoomId) {
            //await this.lookForExistingRoom(storedRoomId, storedSessionId);
        }

        if (queryRoomId) {
            this.setState({ loading: true, });
            setTimeout(() => {
                this.doReconnect(queryRoomId, querySessionId);
            }, 1500);
        } else if (qrRoomCode) {
            if (!this.state.isOpenReconnect) {
                let fields = this.state.fields;
                qrRoomCode = qrRoomCode.toUpperCase();
                fields["formRoomId"] = qrRoomCode;
                this.setState({ formRoomId: qrRoomCode, fields, });
                this.doJoinQRCode(qrRoomCode);
            }
        } else if (showJoin) {
            this.setState({ isOpenJoin: true, });
        }


        if (showfaq) {
            document.getElementById('faqSection').scrollIntoView();
        }

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);

        //console.log(this.props.location.state)
        //if (this.props.location.state) {
        //    console.log("hi")
        //} else {
        //    var response = await http.get(process.env.REACT_APP_API_URL + "api/Authenticate/CheckVoucherExpires");
        //    console.log(response.data);
        //    this.setState({ message: response.data.message });
        //    if (response.data.status == "Success") {
        //        this.gotoVoucher();
        //    }
        //}
        this.setSilk();
        this.setIsIpad();
    }

    componentWillUnmount() {
        if (this.state.room) {
            console.log("component unmounting");
            this.state.room.leave(true);
        }
    }

    setIsIpad() {

        if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) {
            this.setState({
                isIpad: true
            });
        }
    }

    setSilk() {
        //this works to detect silk browser
        if (/\bSilk\b/.test(navigator.userAgent)) {
            this.setState({
                isSilk: true
            });
        }
    }

    closeBrowserPopup = (e) => {
        e.preventDefault();
        this.setState({ badBrowser: false, });
    }

    browserVersion() {
        try {
            //browser detection
            var browser = {};
            if (/edge\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "edge";
                browser.majorVersion = parseInt(/edge\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /edge\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/chrome\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "chrome";
                browser.majorVersion = parseInt(/chrome\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /chrome\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/firefox\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "firefox";
                browser.majorVersion = parseInt(/firefox\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /firefox\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/opr\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "opera";
                browser.majorVersion = parseInt(/opr\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /opera\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/CriOS/i.test(navigator.userAgent)) {
                browser.agent = "chrome";
                browser.majorVersion = parseInt(/CriOS\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /CriOS\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/Safari\/[0-9.]+/i.test(navigator.userAgent)) {
                browser.agent = "safari";
                browser.majorVersion = parseInt(/Version\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /Version\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else {
                browser.agent = false;
                browser.majorVersion = false;
                browser.version = false;
                this.setState({ badBrowser: true, });
            }

            if (browser.agent == "chrome") {
                if (browser.majorVersion < 4) {
                    this.setState({ badBrowser: true, });
                }
            } else if (browser.agent == "edge") {
                if (browser.majorVersion < 8) {
                    this.setState({ badBrowser: true, });
                }
            } else if (browser.agent == "firefox") {
                if (browser.majorVersion < 3.5) {
                    this.setState({ badBrowser: true, });
                }
            } else if (browser.agent == "safari") {
                if (browser.majorVersion < 4) {
                    this.setState({ badBrowser: true, });
                }
            } else if (browser.agent == "opera") {
                if (browser.majorVersion < 11.5) {
                    this.setState({ badBrowser: true, });
                }
            }
            console.log("browser:  " + browser.agent);
        } catch (e) {
            console.log(e);
        }
    }

    chooseAvatar(id) {
        this.setState({ chosenAvatar: id });
    }

    async checkForPromotion() {
        let roomsJoined = this.getRoomsJoined();
        if (roomsJoined != null) {
            roomsJoined = parseInt(roomsJoined);
            if (roomsJoined == 3 || roomsJoined % 10 == 0) {
                const promotionresult = await promotionService.getActivePromotion();
                if (promotionresult.data.status == "Success") {
                    if (localStorage.getItem(`seen_promotion_${promotionresult.data.activePromotion.id}`) == null) {
                        this.setState({
                            isOpenPromotion: true,
                            currentPromotion: promotionresult.data.activePromotion,
                        });
                    }
                }
            }
        }
    }

    async lookForExistingRoom(roomId, sessionId) {
        console.log("looking for existing room");
        await axios.get(process.env.REACT_APP_MATCH_MAKE_URL + `/is-reconnecting/?roomId=${roomId}&sessionId=${sessionId}`)
            .then(res => {
                console.log("Existing room found");
                console.log(res);
                this.setState({
                    isOpenReconnect: res.data.isValid
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async checkGameExists(roomId) {
        roomId = roomId.toUpperCase();
        const storedSessionId = this.getSessionIdFromStorage();

        if (this.cancelToken != null) {
            this.cancelToken.cancel("Operation canceled due to new request.")
        }

        if (roomId != null) {
            this.cancelToken = axios.CancelToken.source();

            const result = await axios.get(process.env.REACT_APP_MATCH_MAKE_URL + `/game-exists/?roomId=${roomId}&sessionId=${storedSessionId}`,
                { cancelToken: this.cancelToken.token, }
            )
                .then(res => {
                    console.log("Game exists check result");
                    console.log(JSON.stringify(res));
                    let errors = {};

                    if (res.data.gameExists) {
                        errors["formRoomId"] = "";
                        this.setState({ canJoin: true, });
                        if (res.data.playerExists) {
                            this.setState({ joinButtonValue: "Reconnect", errors, });
                        } else {
                            this.setState({ joinButtonValue: "Join", errors, });
                        }
                    } else {
                        errors["formRoomId"] = `${roomId} does not exist!`;
                        this.setState({ canJoin: false, errors, });
                    }
                    this.cancelToken = null;
                    return res.data;
                })
                .catch(function (error) {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled');
                    }
                    console.log(error);
                });
            return result;
        }
    }

    applyAvatar = (event, changePotato = false) => {
        if (event) event.preventDefault();
        if (this.handleNameValidation()) {
            if (this.state.room) {
                this.setState({ appliedAvatar: this.state.chosenAvatar, changePotato, })
                this.state.room.send("update_avatar", { avatarId: this.state.chosenAvatar, nickName: this.state.formNickname.toUpperCase(), });
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    storeSessionId(id) {
        try {
            localStorage.setItem("sessionId", id);
        } catch (e) {
            console.log(e);
        }
        this.setState({ storedSessionId: id });
    }

    getSessionIdFromStorage() {
        try {
            return localStorage.getItem('sessionId');
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    storeNickname(name) {
        try {
            localStorage.setItem("nickname", name);
        } catch (e) {
            console.log(e);
        }
    }

    getNicknameFromStorage() {
        try {
            return localStorage.getItem('nickname');
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    storeRoomId(id) {
        try {
            localStorage.setItem("roomId", id);
        } catch (e) {
            console.log(e);
        }
        this.setState({ storedRoomId: id });
    }

    getRoomIdFromStorage() {
        try {
            return localStorage.getItem('roomId');
        } catch (e) {
            console.log(e);
        }
        return null;
    }

    updateAvatarList(list) {
        let arr = [];
        for (const [key, value] of Object.entries(list)) {
            arr.push(value);
        }
        console.log("avatar arr : " + JSON.stringify(arr));
        console.log("chosen avatar : " + this.state.chosenAvatar);
        if (arr.find(x => x.id == this.state.chosenAvatar).inUse) {
            this.chooseAvatar(this.state.appliedAvatar);
            this.setState({ avatarList: arr, });
        } else {
            this.setState({ avatarList: arr, });
        }
    }

    changeAvatar = () => {
        this.setState({ changePotato: true, });

        //this.setFormToEmpty();
    }

    setFormToEmpty = () => {
        console.log("IN FUNCTION SET FORM TO EMPTY");
        if (this.state.formNickname === "player") {
            console.log("SHOULD CHANGE FORM TO EMPTY");
            let fields = this.state.fields;
            fields["formNickname"] = "";
            this.setState({ formNickname: "", fields });
        }
    }

    submitPromotionForm = (event) => {
        event.preventDefault();
        console.log("Submitting...");
        console.log(this.state.promotionEmail.length);
        console.log(this.state.promotionSubmitting);
        if (this.state.promotionEmail.length > 0 && !this.state.promotionSubmitting) {
            this.setState({ promotionSubmitting: true, promotionError: null, });
            promotionService.submitPromotionEntry(this.state.currentPromotion.id, this.state.promotionEmail)
                .then((res) => {
                    console.log(`Promotion Submit Result : `, res);
                    if (res.data.status == "Success") {
                        console.log(`promo code : `, res.data.promotionCode)
                        let index = 10;
                        let tempPromo;
                        this.setState({ promotionSuccess: true, });
                        localStorage.setItem(`seen_promotion_${this.state.currentPromotion.id}`, "true");
                        const promoInterval = setInterval(() => {
                            index = this.getRandomElement([5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]);

                            tempPromo = { description: `${index}%`};
                            this.setState({ awardedPromotionCode: tempPromo });
                            index++;
                        }, 50);
                        setTimeout(() => {
                            clearInterval(promoInterval);
                            this.setState({ awardedPromotionCode: res.data.promotionCode, promotionGenerated: true, });
                        }, 3000);
                    } else {
                        this.setState({ promotionSubmitting: false, promotionError: res.data.reason, });
                    }
                }).catch((err) => {
                    console.log(`Promotion Submit Error : `, err);
                    this.setState({ promotionSubmitting: false, promotionError: err, });
                });
        }
    }

    updatePromotionEmail = (event) => {
        const value = event.target.value;
        console.log("Updating Email");
        this.setState({ promotionEmail: value });
    }

    getRoomsJoined() {
        return localStorage.getItem("rooms_joined_counter");
    }

    updateRoomsJoined() {
        let roomsJoined = this.getRoomsJoined();
        if (roomsJoined == null) {
            roomsJoined = 1;
        } else {
            roomsJoined = parseInt(roomsJoined);
            roomsJoined += 1;
        }
        localStorage.setItem("rooms_joined_counter", roomsJoined.toString());
    }

    doReconnect(roomId, sessionId, fromStored = false) {
        this.setState({ loading: true, isOpenReconnect: false, });
        this.client.reconnect(roomId, sessionId).then(room => {
            console.log(room.sessionId, "joined", room.name);
            this.updateRoomsJoined();
            this.setState({ room: room, roomId: room.id, myId: room.sessionId, loading: false, inRoom: true, });

            room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
                this.chooseAvatar(state.players[room.sessionId].avatar);
                this.applyAvatar(false, false);
                this.setState({ roomState: state, appliedAvatar: state.players[sessionId].avatar, player: JSON.parse(JSON.stringify(state.players[room.sessionId])), });
                this.startLocationChecks();

                this.checkForPromotion();
            });
            room.onStateChange((state) => {
                console.log(room.name, "has new state:", state);
                this.setState({ roomState: state, player: state.players[room.sessionId], });
            });

            room.onMessage("game_starting", (message) => {
                console.log(this.client.id, "received on", room.name, message);
                this.goToGame(message.gameId);
            });
            room.onMessage("host_joined_game", (message) => {
                console.log(this.client.id, "received on", room.name, message);
                if (message.gameId)
                    this.goToGame(message.gameId);
            });

            room.onMessage("update_avatar", (message) => {
                console.log("update_avatar", "received on", room.name, message);
                this.updateAvatarList(message.avatarList);
                if (message.sessionId === this.state.myId) {
                    this.chooseAvatar(message.avatarId);
                }
            });

            room.onMessage("update_nickname", (message) => {
                console.log("update_avatar", "received on", room.name, message);
                if (message.sessionId === this.state.myId) {
                    this.updatePlayerName(message.nickName);
                }
            });

            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);
                this.setState({ inRoom: false, });
                if (code != 4050) {
                    if (!this.state.redirectURL) {
                        this.doReconnect(this.state.storedRoomId, this.state.storedSessionId);
                    } else {
                        setTimeout(() => {
                            this.setState({ redirect: true, });
                        }, 1500);
                    }
                }

            });

            room.onError((code, message) => {
                console.log(this.client.id, "couldn't join", room.name);
            });
        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ loading: false, });
            //if (fromStored) {
            //    this.setState({ storedRoomId: null, storedSessionId: null });
            //    this.storeRoomId(null);
            //    this.storeSessionId(null);
            //}
        });
    }

    goToGame(gameId) {
        let redirectURL;
        switch (gameId) {
            case gameIds.CanOfSquirms:
                redirectURL = process.env.REACT_APP_COS_URL;
                break;
            case gameIds.HerdMentality:
                redirectURL = process.env.REACT_APP_HERD_URL;
                break;
            case gameIds.Scrawl:
                redirectURL = process.env.REACT_APP_SCRAWL_URL;
                break;
            case gameIds.TemplateGame:
                redirectURL = process.env.REACT_APP_TEMPLATE_URL;
                break;
            case gameIds.ColourBrain:
                redirectURL = process.env.REACT_APP_COLOURBRAIN_URL;
                break;
            case gameIds.Chameleon:
                redirectURL = process.env.REACT_APP_CHAMELEON_URL;
                break;
            case gameIds.DrawWith:
                redirectURL = process.env.REACT_APP_DRAWWITH_URL;
                break;
            case gameIds.Pool:
                redirectURL = process.env.REACT_APP_POOL_URL;
                break;
        }
        if (redirectURL) {
            this.state.room.leave(false);
            this.setState({ redirectURL: `${redirectURL}/client/?roomId=${this.state.roomId}&sessionId=${this.state.myId}` });
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        }
    }

    async updateRoomId(field, event) {
        let fields = this.state.fields;
        fields[field] = event.target.value;
        this.setState({ fields });
        this.setState({ formRoomId: event.target.value });

        if (event.target.value.length == 4) {
            await this.checkGameExists(event.target.value);
        } else {
            this.setState({ canJoin: false, joinButtonValue: "Join", });
        }
    }

    updateNickname(field, event, override = "player") {
        let fields = this.state.fields;
        fields[field] = event ? event.target.value : override;
        this.setState({ fields });
        this.setState({ formNickname: event ? event.target.value : override, })

        this.storeNickname(event ? event.target.value : override);
    }

    updatePlayerName(name) {
        this.setState(prevState => {
            prevState.player.name = name;
            return {
                player: prevState.player
            }
        });
    }

    startLocationChecks() {
        if (this.state.room) {
            this.state.room.send("location_check", { gameId: gameIds.Lobby, });
        }
        this.locationCheckInterval = setInterval(() => {
            if (this.state.room) {
                this.state.room.send("location_check", { gameId: gameIds.Lobby, });
            }
        }, 2000);
    }

    clickDoJoin = async (event) => {
        event.preventDefault();

        let roomId = this.state.fields["formRoomId"];
        roomId = roomId.toUpperCase();
        const gameCheckData = await this.checkGameExists(roomId);
        const sessionId = this.getSessionIdFromStorage();

        if (gameCheckData != null) {
            if (gameCheckData.playerExists) {
                this.doReconnect(roomId, sessionId);
            } else {
                this.doJoin();
            }
        } else {
            this.doJoin();
        }
    }

    async doJoinQRCode(qrRoomId) {
        const gameCheckData = await this.checkGameExists(qrRoomId);
        const sessionId = this.getSessionIdFromStorage();
        if (gameCheckData.playerExists) {
            this.doReconnect(qrRoomId, sessionId);
        } else {
            this.doJoin(true);
        }
    }

    doJoin(forceName = false) {
        if (this.handleValidation(forceName)) {
            let roomId = this.state.fields["formRoomId"];
            let name = this.state.fields["formNickname"];

            if (forceName) {
                if (!name || name.length < 2 || name.length > 10) {
                    this.updateNickname("formNickname", false, "player");
                    name = this.state.fields["formNickname"];
                }
            }

            this.setState({ joining: true, loading: true, });

            roomId = roomId.toUpperCase();
            name = name.toUpperCase();
            let oldId = this.getSessionIdFromStorage();

            var parser = new UAParser();

            let options = {};
            options.name = name;
            options.deviceDetails = parser.getResult();

            if (oldId != null) options.oldId = oldId;

            this.client.joinById(roomId, options).then(room => {
                console.log(room.sessionId, "joined", room.name);
                this.storeSessionId(room.sessionId);
                this.storeRoomId(roomId);
                this.updateRoomsJoined();
                this.setState({ roomId: room.id, myId: room.sessionId, room: room, joining: false, loading: false, inRoom: true, });

                room.onStateChange.once((state) => {
                    console.log("this is the first room state!", JSON.stringify(state));
                    this.chooseAvatar(state.players[room.sessionId].avatar);
                    this.setState({ roomState: state, player: JSON.parse(JSON.stringify(state.players[room.sessionId])), });
                    this.applyAvatar(false, true);
                    this.startLocationChecks();

                    this.checkForPromotion();
                });

                room.onStateChange((state) => {
                    console.log(room.name, "has new state:", state);
                    this.setState({ roomState: state, player: state.players[room.sessionId], });
                });

                room.onMessage("game_starting", (message) => {
                    console.log(this.client.id, "received on", room.name, message);
                    this.goToGame(message.gameId);
                });

                room.onMessage("update_avatar", (message) => {
                    console.log("update_avatar", "received on", room.name, message);
                    this.updateAvatarList(message.avatarList);
                    if (message.sessionId === this.state.myId) {
                        this.chooseAvatar(message.avatarId);
                    }
                });

                room.onMessage("update_nickname", (message) => {
                    console.log("update_avatar", "received on", room.name, message);
                    if (message.sessionId === this.state.myId) {
                        this.updatePlayerName(message.nickName);
                    }
                });

                room.onError((code, message) => {
                    console.log(this.client.id, "couldn't join", room.name);
                });

                room.onLeave((code) => {
                    console.log(this.client.id, "left", room.name);
                    this.setState({ inRoom: false, });
                    if (code != 4050) {
                        if (!this.state.redirectURL) {
                            this.doReconnect(this.state.storedRoomId, this.state.storedSessionId);
                        } else {
                            setTimeout(() => {
                                this.setState({ redirect: true, });
                            }, 1500);
                        }
                    }

                });

            }).catch(e => {
                console.log("JOIN ERROR", e);
                let errors = {};
                errors["formRoomId"] = e.toString();

                if (errors["formRoomId"].includes("is locked")) {
                    //this.openLockedModal();
                    this.setState({ isOpenJoin: false, isOpenLockedRoom: true });
                }

                this.setState({ joining: false, loading: false, /*showError: true,*/ errors, });
            });
        }
        else {
            this.setState({
                isOpenJoin: true
            });
        }
    }

    handleNameValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        // Name
        if (!fields["formNickname"]) {
            formIsValid = false;
            errors["formNickname"] = "Cannot be empty";
        }

        if (typeof fields["formNickname"] !== "undefined") {
            if (!fields["formNickname"].match(/^[a-zA-Z0-9 ]{2,10}$/)) {
                formIsValid = false;
                errors["formNickname"] = "Please enter between 2 and 10 letters/numbers and special characters no spaces.";
            }
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    handleValidation(forceName = false) {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        // RoomId
        if (!fields["formRoomId"]) {
            formIsValid = false;
            errors["formRoomId"] = "Cannot be empty";
        }

        if (typeof fields["formRoomId"] !== "undefined") {
            if (!fields["formRoomId"].match(/^[a-zA-Z0-9]{4}$/)) {
                formIsValid = false;
                errors["formRoomId"] = "Please enter a valid 4 letter code.";
            }
        }

        if (!forceName) {
            // Name
            if (!fields["formNickname"]) {
                formIsValid = false;
                errors["formNickname"] = "Cannot be empty";
            }

            if (typeof fields["formNickname"] !== "undefined") {
                if (!fields["formNickname"].match(/^[a-zA-Z0-9 ]{2,10}$/)) {
                    formIsValid = false;
                    errors["formNickname"] = "Please enter between 2 and 10 letters/numbers and special characters no spaces.";
                }
            }
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }


    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    scrollToView(e) {
        var element = document.getElementById(e);

        // scroll to element
        element.scrollIntoView();
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    doAnimation = () => {
        let animation = this.getRandomElement(animations);
        this.state.room.send("animate_potato", { animation: animation, });
        if (document.getElementById(`potato`)) {
            this.animateCSS(`#potato`, animation);
        }
    }

    openJoinModal = () => this.setState({ isOpenJoin: true });

    openOverlayModal = () => this.setState({ isOpenOverlay: true, shouldPlay: true });

    openFaqsModal = () => this.setState({ isOpenFaqs: true, shouldPlay: false });

    handleMouseEnterStart = () => this.setState({ textStartGame: true });

    handleMouseLeaveStart = () => this.setState({ textStartGame: false });

    handleMouseEnterJoin = () => this.setState({ textJoinGame: true });

    handleMouseLeaveJoin = () => this.setState({ textJoinGame: false });

    closeReconnectModal = () => this.setState({ isOpenReconnect: false });

    closePromotionModal = () => {
        this.setState({ isOpenPromotion: false, currentPromotion: null });
    };

    openLockedModal = () => this.setState({ isOpenLockedRoom: true });

    closeLockedModal = () => this.setState({ isOpenLockedRoom: false });



    validStartGame = () => {
        if (this.props.currentUser) {
            this.props.history.push("/lobby");
        }
        else {
            this.props.history.push("/authentication/login");
        }
    }

    gotoVoucher = () => {
        this.props.history.push({
            pathname: "/authentication/voucher-code",
            state: { detail: this.state.message }
        })
    }

    clickRedeemCode = () => {
        this.props.history.push("/authentication/register");
    }

    clickBuyNow = () => {
        this.props.history.push("/packs");
    }

    render() {
        if (this.state.redirectURL && !this.state.isOpenPromotion) {
            return (
                <div>
                    <Loading loadingText={"Sending you to the game!"} />
                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </div>
            )
        }
        return (
            <div className={styles.containerHome}>
                {
                    this.state.loading ?
                        <Loading loadingText={"Searching for room..."} />
                        :
                        !this.state.inRoom ?
                            <div>
                                <div className="container">
                                    {/*this.props.currentUser && <Banner onClick="gotoVoucher" />*/}

                                    {/*<div className="row">*/}
                                    {/*    <div className="col-12" align="center">*/}
                                    {/*        <img src="/static/media/BigPotatoBanner.2523367f.png" className="img-fluid" />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="row w-auto m-auto">
                                        <div className="col-12" style={{ paddingLeft: "3rem", }} align="center">
                                            <img src={potatosTV} className="img-fluid" style={{ maxHeight: "550px" }} />
                                        </div>
                                    </div>
                                    <div className="row m-auto" style={{ maxWidth: "750px", }}>
                                        <div className="col-sm-12 col-md-6 d-flex">
                                            <button type="button"
                                                className={styles.gameButtonsLanding + " " + styles.landingpageButtonLeft}
                                                onClick={this.validStartGame}
                                                onMouseEnter={this.handleMouseEnterStart}
                                                onMouseLeave={this.handleMouseLeaveStart}
                                                style={{ margin: "auto", marginTop: "20px", }}
                                            >
                                                Start a game
                                            </button>
                                        </div>
                                        <div className="col-sm-12 col-md-6 d-flex">
                                            <button type="button"
                                                className={styles.gameButtonsLanding + " " + styles.landingpageButtonRight}
                                                onClick={this.openJoinModal}
                                                onMouseEnter={this.handleMouseEnterJoin}
                                                onMouseLeave={this.handleMouseLeaveJoin}
                                                style={{ margin: "auto", marginTop: "20px", }}
                                            >
                                                Join a game
                                                <img id="potatoimage" className={this.state.isSilk ? styles.potatoMobileSilk : styles.potatoMobile} src={potatoMobile} />
                                            </button>
                                        </div>
                                        {
                                            !this.props.currentUser &&
                                            <React.Fragment>
                                                <div className="col-sm-12 col-md-6 d-flex">
                                                    <button type="button"
                                                        className={styles.gameButtonsLanding + " " + styles.landingpageButtonLeft + " " + styles.pink}
                                                        onClick={this.clickBuyNow}
                                                        onMouseEnter={this.handleMouseEnterStart}
                                                        onMouseLeave={this.handleMouseLeaveStart}
                                                        style={{ margin: "auto", marginTop: "20px", }}
                                                    >
                                                        Buy Now
                                                        <img id="potatoimage" className={styles.potatoBuy} src={potatoBuy} />
                                                    </button>
                                                </div>
                                                <div className="col-sm-12 col-md-6 d-flex">
                                                    <button type="button"
                                                        className={styles.gameButtonsLanding + " " + styles.landingpageButtonRight + " " + styles.pink}
                                                        onClick={this.clickRedeemCode}
                                                        onMouseEnter={this.handleMouseEnterJoin}
                                                        onMouseLeave={this.handleMouseLeaveJoin}
                                                        style={{ margin: "auto", marginTop: "20px", }}
                                                    >
                                                        Redeem Code
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <div className={"col-12 " + styles.howItWorksLanding} align="center" onClick={() => this.scrollToView("howitworksrowvideoid")}>
                                            <div style={{ padding: "0px", margin: "0px" }}>
                                                How it all works
                                            </div>
                                            <img className="img-fluid" src={chevronDown} />
                                        </div>
                                    </div>

                                    {/* How it works videos */}
                                    <div className="row" style={{ marginTop: "20px" }} id="howitworksrowvideoid">
                                        <div className="ratio ratio-16x9" align="center">
                                            <iframe src="https://www.youtube.com/embed/W60H_Xvi4qw" title="BigPotato.TV - How to play" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <div className={"col-12 " + styles.howItWorksLanding} align="center" onClick={() => this.scrollToView("faqrowid")}>
                                            <div style={{ padding: "0px", margin: "0px" }}>
                                                Frequently asked questions
                                            </div>
                                            <img className="img-fluid" src={chevronDown} />
                                        </div>
                                    </div>
                                </div>
                                <div id="faqSection" style={{ backgroundColor: "white", width: "100%", paddingTop: "40px", paddingBottom: "40px" }}>
                                    <div className="container">
                                        <div className="row" style={{ marginTop: "20px" }} id="faqrowid">
                                            <div className="col-12" align="center">
                                                <img className="img-fluid" src={faq} />
                                            </div>
                                        </div>
                                        <Accordion style={{ marginBottom: "10px" }} id="faqAccordion">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header> <span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>DO I NEED TO SIGN IN OR REGISTER TO JOIN A GAME?</span></Accordion.Header>
                                                <Accordion.Body>
                                                    Nope! Only the host needs to sign in with their account - all you need to do is 'Join a Game' with the room code your hosts gives you.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header><span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>I'VE BEEN DISCONNECTED FROM THE GAME!</span></Accordion.Header>
                                                <Accordion.Body>
                                                    No worries - just hit the 'reconnect' button - you'll be back in no time
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header><span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>WE'RE ALL READY, BUT THE GAME WON'T START!</span></Accordion.Header>
                                                <Accordion.Body>
                                                    Do you have enough players for that game? Check the top right corner for a little message that tells you if you have too many or too few players for that game.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header><span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>I CAN'T SIGN IN!</span></Accordion.Header>
                                                <Accordion.Body>
                                                    {/*<p>Have you registered your account yet? You'll need to do this using the code we sent you when you bought the package</p>*/}
                                                    {/*<p>Check for typos and make sure you're using the right email address (this will be the account that we sent the verification email to!)</p>*/}
                                                    <p>Having trouble? <a target="_blank" href="https://bigpotato.co.uk/pages/help">Get in Touch</a></p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header><span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>CAN I HOST THIS ON MY SMART TV?</span></Accordion.Header>
                                                <Accordion.Body>
                                                    Big Potato TV works best when hosted from a laptop or desktop computer, as those devices tend to have a faster processing speed. If you want to get this on your Big Screen -  we recommend going old school and hooking your screen up via an HDMI cable.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header><span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>HELP! I'VE LOST MY STEAM KEY(S), HOW DO I RECOVER THEM?</span></Accordion.Header>
                                                <Accordion.Body>
                                                    You can recover steam keys <Link to="/authentication/recover-steam-keys">here</Link> by providing the email address you used when redeeming your activation code!
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                                {/*<Footer />*/}

                                <Modal id="LockedRoom" className={styles.modalOpen} size="lg" centered show={this.state.isOpenLockedRoom} onHide={() => this.setState({ isOpenLockedRoom: false })}>
                                    <ModalBody className={styles.modalOpenBody}>
                                        <div className={styles.containerModals}>
                                            <Modal.Header className={styles.modalHeaders} closeButton></Modal.Header>
                                            <LayoutBoxHeader className={"card-header " + styles.lockedHeader}>
                                                <h5 className={this.state.isSilk ? styles.cardTitleSilk + ` ${styles.locked}` + " card-title" : styles.cardTitle + ` ${styles.locked}` + " card-title"}>OH NO!</h5>
                                            </LayoutBoxHeader>
                                            <div className={styles.animContainer}>
                                                <Lottie options={sadPotato} width="100%" height="100%" isClickToPauseDisabled={true} />
                                            </div>
                                            <LayoutBoxBody className={styles.cardBody + ` ${styles.locked}` + " card-body"}>
                                                <p className={this.state.isSilk ? styles.cardPSilk + ` ${styles.locked}` : styles.cardP + ` ${styles.locked}`}>There's already the maximum amount of players in this game</p>
                                            </LayoutBoxBody>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <Modal id="ModalJoin" aria-labelledby="contained-modal-title-vcenter" size="lg" centered className={styles.modalOpen} show={this.state.isOpenJoin} onHide={() => this.setState({ isOpenJoin: false })}>
                                    <ModalBody className={styles.modalOpenBody}>
                                        <div className={styles.containerModals}>
                                            <div className={this.state.isSilk ? styles.rowCardSilk + " row justify-content-center w-100 h-100 m-0" : styles.rowCard + " row justify-content-center w-100 h-100 m-0"}>
                                                <LayoutBox className="card text-center mx-auto">
                                                    <Modal.Header className={styles.modalHeaders} closeButton></Modal.Header>
                                                    <LayoutBoxHeader className="card-header">
                                                        <h5 className={this.state.isSilk ? styles.cardTitleSilk + " card-title" : styles.cardTitle + " card-title"}>Join Someone Else's Game</h5>
                                                        {
                                                            this.state.showError ?
                                                                <h6 className={this.state.isSilk ? styles.cardTitleSilk + " card-title" : styles.cardTitle + " card-title"} style={{ color: "red", }}>{this.state.errorMessage}</h6>
                                                                :
                                                                null
                                                        }
                                                    </LayoutBoxHeader>
                                                    <LayoutBoxBody className={styles.cardBody + " card-body"}>
                                                        <p className={this.state.isSilk ? styles.cardPSilk : styles.cardP}>Wait for the account holder to start a new game. You can then join by typing in the Room ID or scanning the QR code in the lobby.</p>
                                                        <div className={styles.cardPDiv} id="mygame">
                                                            <form className={styles.formEnter} id="roomCodeForm" onSubmit={this.clickDoJoin}>
                                                                <div className={styles.formGroup}>
                                                                    <label className={this.state.isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="formRoomId">Room ID</label><br />
                                                                    <input
                                                                        title="Please enter a valid 4 letter code."
                                                                        className={styles.formEnterInput}
                                                                        value={this.state.fields["formRoomId"]}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        id="roomId"
                                                                        name="formRoomId"
                                                                        onChange={this.updateRoomId.bind(this, "formRoomId")}
                                                                        required />
                                                                    <br />
                                                                    {this.state.errors["formRoomId"] ? <div className={styles.alertSilk + " alert alert-danger"} role="alert">{this.state.errors["formRoomId"]}</div> : ""}
                                                                </div>
                                                                <div className={styles.formGroup}>
                                                                    <label className={this.state.isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="formNickname">Nickname</label><br />
                                                                    <input
                                                                        title="Please enter between 2 and 10 letters/numbers and special characters no spaces."
                                                                        className={styles.formEnterInput}
                                                                        type="text"
                                                                        value={this.state.fields["formNickname"]}
                                                                        autoComplete="off"
                                                                        id="name"
                                                                        name="formNickname"
                                                                        maxLength={10}
                                                                        onChange={this.updateNickname.bind(this, "formNickname")}
                                                                        required />
                                                                    <br />
                                                                    {this.state.errors["formNickname"] ? <div className={styles.alertSilk + " alert alert-danger"} role="alert">{this.state.errors["formNickname"]}</div> : ""}
                                                                </div>
                                                                <div className={styles.formGroup + " " + styles.formGroupButton + " position-relative"}>
                                                                    <input className={this.state.isSilk ? styles.submitBtnSilk + " btn" + `${this.state.joining ? styles.disabled : this.state.canJoin ? "" : styles.disabled}` : styles.submitBtn + " btn"} disabled={this.state.joining || !this.state.canJoin} type="submit" value={this.state.joinButtonValue} />
                                                                    <img className={`${styles.potatoHappy} ${this.state.joining ? styles.disabled : this.state.canJoin ? "" : styles.disabled}`} src={potatoHappy} width="48" />
                                                                </div>
                                                                <div>
                                                                    By clicking <strong>JOIN</strong> you are agreeing to the <a href="/terms" target="_blank">BigPotato.tv Terms</a>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </LayoutBoxBody>
                                                </LayoutBox>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <Modal id="ModalReconnect" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered className={styles.modalReconnectOpen} show={this.state.isOpenReconnect} onHide={() => this.setState({ isOpenReconnect: false })}>
                                    <ModalBody className={styles.modalOpenBody}>
                                        <div className={styles.containerModals}>
                                            {
                                                this.state.storedRoomId != null && this.state.storedRoomId != "null" ?
                                                    <div className="w-100 m-0">
                                                        <LayoutBox className={styles.layoutReconnect + " card text-center mx-auto"}>
                                                            <LayoutBoxHeader className="card-header mt-0">
                                                                <h5 className={this.state.isSilk ? styles.cardTitleSilk + " " + styles.cardTitleReconnect + " card-title" : styles.cardTitle + " " + styles.cardTitleReconnect + " card-title"}>You're in a game,<br /> Want to reconnect?</h5>
                                                            </LayoutBoxHeader>
                                                            <LayoutBoxBody className={styles.cardBody + " card-body mb-2"}>
                                                                <div id="mygame">
                                                                    <input onClick={() => this.doReconnect(this.state.storedRoomId, this.state.storedSessionId, true)} className={styles.submitBtn + " " + styles.submitBtnReconnect + " btn mb-3" + `${this.state.joining ? styles.disabled : ""}`} disabled={this.state.joining} type="submit" value="Yes Please" />
                                                                    <button type="button" className={this.state.isSilk ? styles.submitBtnSilk + " " + styles.submitBtnReconnect + " btn" : styles.submitBtn + " " + styles.submitBtnReconnect + " btn"} onClick={this.closeReconnectModal}>
                                                                        No Thanks
                                                                    </button>
                                                                </div>
                                                            </LayoutBoxBody>
                                                        </LayoutBox>

                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </ModalBody>
                                </Modal>

                            </div>
                            : <div className={styles.roomSection}>
                                {
                                    !this.state.changePotato ?
                                        <React.Fragment>
                                            <div className={styles.title}>Waiting for host to<br />start a game</div>
                                            <div id="potato" className={styles.chosenPotato} onClick={this.doAnimation}>
                                                <Lottie
                                                    options={getAvatarById(this.state.chosenAvatar).idleAnim}
                                                    width="100%"
                                                    height="100%"
                                                    isClickToPauseDisabled={true}
                                                />
                                            </div>
                                            <div className={styles.nickname}>{this.state.player.name}</div>
                                            <div className={styles.pinkButton} onClick={this.changeAvatar}>CHANGE POTATO</div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className={styles.title}>CHOOSE YOUR POTATO</div>
                                            <div id="avatarList" className={styles.avatarList}>
                                                {
                                                    this.state.avatarList.map((av) =>
                                                        av.isActive &&
                                                        <div
                                                            className={`${styles.avatar} ${this.state.chosenAvatar == av.id ? styles.selected : ""} ${this.state.appliedAvatar == av.id ? styles.applied : ""}`}
                                                            key={av.id}
                                                            onClick={av.inUse ? null : () => this.chooseAvatar(av.id)}
                                                        >
                                                            {
                                                                av.inUse && this.state.appliedAvatar != av.id ?
                                                                    <img className={`${styles.potato} ${av.inUse ? this.state.appliedAvatar == av.id ? "" : styles.fade : ""}`} src={getAvatarById(av.id).src} />
                                                                    :
                                                                    <Lottie
                                                                        options={getAvatarById(av.id).idleAnim}
                                                                        width="100%"
                                                                        height="100%"
                                                                        isClickToPauseDisabled={true}
                                                                        style={av.inUse ? this.state.appliedAvatar == av.id ? {} : { opacity: 0.25 } : {}}
                                                                    />
                                                            }
                                                            {/*<img className={`${styles.potato} ${av.inUse ? this.state.appliedAvatar == av.id ? "" : styles.fade : ""}`} src={avatars[av.id].src} />*/}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <form onSubmit={this.applyAvatar} style={{ display: "flex", flexDirection: "column", }}>
                                                <div className={styles.nameSection}>
                                                    <div className={styles.nameTitle}>NICKNAME</div>
                                                    <div className={styles.inputArea}>
                                                        <input title="Please enter between 2 and 10 letters/numbers and special characters no spaces." className={styles.nameBox} type="text" defaultValue={this.state.fields["formNickname"] === "player" ? "" : this.state.fields["formNickname"]} autoComplete="off" onChange={this.updateNickname.bind(this, "formNickname")} maxLength={10} />
                                                        <br />
                                                        {this.state.errors["formNickname"] ? <div style={{ margin: "unset", }} className="alert alert-danger" role="alert">{this.state.errors["formNickname"]}</div> : ""}
                                                    </div>
                                                </div>
                                                {/*<div className={styles.pinkButton} onClick={this.applyAvatar}>CONFIRM</div>*/}
                                                <input className={styles.pinkButton} type="submit" value="CONFIRM" />
                                            </form>
                                        </React.Fragment>
                                }

                            </div>
                }
                {
                    this.state.badBrowser &&
                    <div className={styles.badBrowserPopup}>
                        <LayoutBox className={styles.popupBox}>
                            <div className={styles.title}>Uh oh!</div>
                            <div className={styles.description}>The browser you're using may be unsupported for big potato tv games, please try using a different one such as Chrome, Firefox or Safari!</div>
                            <div className={styles.description}>If you're already using one of these, try updating them to a newer version!</div>
                            <div className={styles.potato}>
                                <Lottie options={sadPotato} width="100%" height="100%" isClickToPauseDisabled={true} />
                            </div>
                            <button type="button" className={styles.popupButton + " btn"} onClick={this.closeBrowserPopup}>
                                Got it!
                            </button>
                        </LayoutBox>
                    </div>
                }

                <Modal id="ModalPromotion" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered className={styles.modalPromotion} show={this.state.isOpenPromotion} onHide={() => this.setState({ isOpenPromotion: false })}>
                    <ModalBody className={`${styles.modalPromotionBody} text-center`} >
                        <CloseButton variant="white" style={{ display: "block", marginLeft: "auto", }} onClick={this.closePromotionModal} />
                        {
                            this.state.promotionSuccess ?
                                <React.Fragment>
                                    <div className="row py-2">
                                        <h5 className={`${styles.promotionTitle}`}>Here is your discount!</h5>
                                    </div>
                                    <div className="row py-2">
                                        <h5 className={`${styles.promotionDescription}`}>{this.state.awardedPromotionCode.description}</h5>
                                    </div>
                                    {
                                        this.state.promotionGenerated &&
                                        <React.Fragment>
                                            <div className="row py-2">
                                                <h5 className={`${styles.promotionText}`}>We have emailed the code to you! Please contact support if you have any issues purchasing the game with your discount code.</h5>
                                            </div>
                                            <div className={`form-row py-2`}>
                                                <button type="button" onClick={this.closePromotionModal} className={`${styles.promotionButton} btn btn-primary w-100`} >
                                                    Awesome!
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                                :
                                <form onSubmit={this.submitPromotionForm}>
                                    <div className={`form-row py-2`}>
                                        <h5 className={`${styles.promotionTitle}`}>Want to own your own copy of Big Screen Games?</h5>
                                    </div>
                                    <div className="form-row py-2">
                                        <h5 className={`${styles.promotionText}`}>Enter your email below to win a discount code for your purchase.</h5>
                                    </div>
                                    <div className={`form-row py-2`}>
                                        <input type="email" onChange={this.updatePromotionEmail} required className={`${styles.promotionInput} form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your email address" />
                                    </div>
                                    <div className={`form-row py-2`}>
                                        <button type="submit" className={`${styles.promotionButton} btn btn-primary w-100`} >
                                            {
                                                this.state.promotionSubmitting ?
                                                    <div className={styles.loading}></div>
                                                    :
                                                    "Get Discount"
                                            }
                                        </button>
                                        {
                                            this.state.promotionError != null &&
                                            <div className="alert alert-danger mt-1" role="alert">
                                                {this.state.promotionError}
                                            </div>
                                        }
                                    </div>
                                    <div className={`form-row py-2 text-start`}>
                                        <div className={`${styles.promotionFooterText}`}>*By submitting your email, you agree to receive marketing emails from The Games Collective. You can unsubscribe at any time. View our <a href={`${window.location.origin}/privacy`} style={{ color: "white", }} target="_blank">privacy policy</a> for more. </div>
                                    </div>
                                    <div className={`form-row`}>
                                        <button type="button" disabled={this.state.promotionSubmitting} className={`${styles.promotionCancel}`} onClick={this.closePromotionModal}>No Thanks</button>
                                    </div>
                                </form>
                        }

                    </ModalBody>
                </Modal>
            </div>
        );

    }
}

export default withRouter(Home);
