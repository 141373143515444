import {
    UPDATE_PRICING,
} from "actions/types";


const initialState = {
    //price: "",
    //rawPrice: 0,
    //countryCode: "",
    //currency: "",
    //discount: null,
    //discountActive: false,
    //discountPercentage: 0,
    //discountDescription: "",
    Pack1Pricing: {},
    Pack2Pricing: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_PRICING:
            return {
                ...state,
                //price: payload.price,
                //rawPrice: payload.rawPrice,
                //countryCode: payload.countryCode,
                //currency: payload.currency,
                //discount: payload.discount,
                //discountActive: payload.discountActive,
                //discountPercentage: payload.discountPercentage,
                //discountDescription: payload.discountDescription,
                Pack1Pricing: payload[0],
                Pack2Pricing: payload[1],
            };
        default:
            return state;
    }
}
