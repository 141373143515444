export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const APPLY_GAMES = "APPLY_GAMES";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const UPDATE_PRICING = "UPDATE_PRICING";
export const UPDATE_OWNERSHIP = "UPDATE_OWNERSHIP";

export const UPDATE_CART = "UPDATE_CART";