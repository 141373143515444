import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Card, CardBody, NavLink } from 'reactstrap';
import { Modal, ModalBody, Accordion, } from 'react-bootstrap';
import Lottie from 'react-lottie';
import Slider from "react-slick";
import { fireConversionEvent } from "actions/analytics";
import stripeService from "services/stripe.service.js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import Carousel from "react-multi-carousel";
//import "react-multi-carousel/lib/styles.css";

import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';

import Pack1Overview from "components/packs/Pack1Overview";
import Footer from "components/Footer";

import PackLogo from "images/packs/PackLogo.png";
import MobileLogo from "images/packs/mobile-logo.png";
import DuoTatos from "images/packs/duo-tatos.png";
import WoodBg from "images/packs/wood-bg.png";
import squirmsIcon from "images/squirms-icon.png";
import scrawlIcon from "images/scrawl-icon.png";
import herdIcon from "images/herd-icon.png";
import Screenshot1 from "images/packs/screenshot-1.png";
import Screenshot2 from "images/packs/screenshot-2.png";
import Screenshot3 from "images/packs/screenshot-3.png";
import ReviewBg from "images/packs/reviews-bg.png";
import PotIcon1 from "images/packs/pot-icon-1.png";
import PotIcon2 from "images/packs/pot-icon-2.png";
import PotIcon3 from "images/packs/pot-icon-3.png";
import BuyHereIcon from "images/packs/buy-here-icon.png";
import ArrowLeft from "images/packs/arrow-left.png";
import ArrowRight from "images/packs/arrow-right.png";
import StarFull from "images/packs/full-star.png";
import StarEmpty from "images/packs/empty-star.png";

import styles from './Pack1.module.scss';
import "components/packs/carouselStyles.css";

const games = [
    {
        image: herdIcon,
        name: "HERD MENTALITY",
        quip: "Guess your friends' answers to win cows!",
        description: "A 'think like the herd' party game with one black and white mission — write down the same answer as everyone else. Watch out for the angry pink cow!",
    },
    {
        image: scrawlIcon,
        name: "SCRAWL",
        quip: "Terrible drawings, ridiculous guesses!",
        description: "The official drawing game for terrible artists. Doodle a weird phrase, pass it on and watch as things start to go very, very wrong.",
    },
    {
        image: squirmsIcon,
        name: "CAN OF SQUIRMS",
        quip: "Silly questions you can't wriggle out of!",
        description: 'Look your friend in the eyes and silently answer questions like, \"Which one of us would start the best cult?\" If you agree, the points are yours!',
    }
];
const infoCards = [
    {
        image: PotIcon1,
        title: "A BRAND NEW WAY TO PLAY",
        description: "Gather up some of our favourite family party games, live on the big screen. Play on the TV, gather around a laptop or stream them on a video call.",
    },
    {
        image: PotIcon2,
        title: "USE YOUR PHONE AS A CONTROLLER",
        description: "No cards, no dice, no board. With party games on the TV, all you need is your phone. Just scan the QR code, pick a potato character and get playing.",
    },
    {
        image: PotIcon3,
        title: "LIKE JACKBOXTV, BUT WITH MORE POTATOES",
        description: "Inside our first party pack, you'll get online versions of Herd Mentality, Can of Squirms and Scrawl - each tweaked to be extra family friendly.",
    },
];
const reviews = [
    {
        name: "ShineyCat.com",
        stars: 5,
        description: "Definitely worth getting if you play party games of ANY kind!"
    },
    {
        name: "TechRaptor",
        stars: 5,
        description: "Tons of fun and flexibility."
    },
    {
        name: "@BoredBoardGames",
        stars: 5,
        description: "For Jackbox lovers, you have to try Big Potato TV"
    },
    {
        name: "Hamo789",
        stars: 4,
        description: "An excellent family friendly game."
    },
];

const carouselSettings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: function (i) {
        return (
            <div className="carousel-dot">

            </div>
        );
    },
};

const gameCarouselSettings = {
    arrows: false,
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    initialSlide: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    customPaging: function (i) {
        return (
            <div className="carousel-dot">
            </div>
        );
    },
};

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img
            src={ArrowRight}
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img
            src={ArrowLeft}
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        />
    );
}


class Pack1 extends Component {
    static displayName = Pack1.name;

    constructor(props) {
        super(props);

        this.state = {
            currency: "GBP",
            price: 16.99,
        };
    }

    componentDidMount() {
        const qrRoomCode = this.getQueryStringValue("qrCode");
        const queryRoomId = this.getQueryStringValue("roomId");
        const querySessionId = this.getQueryStringValue("sessionId");
        if (qrRoomCode != null) {
            if (qrRoomCode.length == 4)
                this.props.history.push(`/play?qrCode=${qrRoomCode}`);
        }
        if (queryRoomId || querySessionId) {
            this.props.history.push(`/play?roomId=${queryRoomId}&sessionId=${querySessionId}`);
        }
    }

    clickBuyNow = (e) => {
        e.preventDefault();
        this.props.history.push(ApplicationPaths.Checkout);
    }

    clickJoinGame = (e) => {
        e.preventDefault();
        this.props.history.push("/play?showJoin=1");
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    render() {

        return (
            <React.Fragment>
                <div className="container mb-4">
                    <div className="row d-flex justify-content-center align-items-center m-auto">
                        <div className={`${styles.desktopLogo} col-sm-12 col-md-6`}>
                            <img src={PackLogo} className={`img-fluid`} />
                        </div>
                        <div className={`${styles.mobileLogo} col-sm-12 col-md-6`}>
                            <img src={MobileLogo} className={`img-fluid`} />
                        </div>
                        <div className="col-sm-12 col-md-6 position-relative">
                            <img src={DuoTatos} className={`${styles.duoTatos} img-fluid position-absolute`} />
                            <Pack1Overview currentUser={this.props.currentUser} />
                        </div>
                    </div>
                </div>
                <div className={`${styles.joinContainer} container-fluid p-0`} style={{ backgroundColor: "#179CD7" }}>
                    <div className="row justify-content-center py-3" style={{ width: "fit-content", margin: "auto", }}>
                        <div className="col d-flex" style={{ whiteSpace: "nowrap" }}>
                            <p className={`${styles.joinText} text-center text-white my-auto ml-auto`}>Got a lobby setup? Join here!</p>
                        </div>
                        <div className="col">
                            <button type="button" onClick={this.clickJoinGame} className={`${styles.packButton} btn btn-lg mr-auto`}>JOIN A GAME</button>
                        </div>
                    </div>
                </div>

                {/*<div className={`${styles.infoSection} container-fluid p-0 pb-2`}>*/}
                {/*    <div className={`${styles.woodBg} row w-100 m-auto  py-5`} style={{*/}
                {/*        backgroundImage: `url(${WoodBg})`,*/}
                {/*    }}>*/}
                {/*        <div className="row m-auto" style={{ width: "fit-content" }}>*/}

                {/*            {*/}
                {/*                infoCards.map((card) => {*/}
                {/*                    return <div className="col">*/}
                {/*                        <div className={`${styles.gameCard} card text-center border-0 h-100 m-auto py-3 px-3`}>*/}
                {/*                            <img src={card.image} class="img-fluid m-auto" alt="Responsive image" style={{ borderRadius: "50%", width: "60%", }} />*/}
                {/*                            <div className="card-body">*/}
                {/*                                <h6 className={`${styles.infoTitle} card-title`}>{card.title}</h6>*/}
                {/*                                <p className={`${styles.gameText} card-text`}>{card.description}</p>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </div>*/}
                   
                {/*</div>*/}
                {/*<div className={`${styles.mobileGamesRow} container-fluid m-auto p-0 mb-5 pb-5`} style={{ width: "100%" }}>*/}
                {/*    <Slider {...gameCarouselSettings}>*/}
                {/*        {*/}
                {/*            infoCards.map((card) => {*/}
                {/*                return <div className="col">*/}
                {/*                    <div className={`${styles.gameCard} card text-center border-0 h-100 m-auto py-3 px-3`}>*/}
                {/*                        <img src={card.image} class="img-fluid m-auto" alt="Responsive image" style={{ borderRadius: "50%", width: "60%", }} />*/}
                {/*                        <div className="card-body">*/}
                {/*                            <h6 className={`${styles.infoTitle} card-title`}>{card.title}</h6>*/}
                {/*                            <p className={`${styles.gameText} card-text`}>{card.description}</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </Slider>*/}
                {/*</div>*/}

                <div className="container-fluid p-0 mb-5">
                    <div className={`${styles.woodBg} row w-100 m-auto py-5`} style={{
                        backgroundImage: `url(${WoodBg})`,
                    }}>
                        <div className="row w-100 m-auto mb-3 mt-3">
                            <h2 className={`${styles.gamesHeader} text-center text-white`}>A NEW WAY TO PLAY!</h2>
                        </div>
                        <div className={`${styles.gamesRow} row m-auto p-0 mb-5`}>
                            {
                                infoCards.map((card) => {
                                    return <div className="col">
                                        <div className={`${styles.gameCard} card text-center border-0 h-100 m-auto py-3 px-3`}>
                                            <img src={card.image} className="img-fluid m-auto" alt="Responsive image" style={{ borderRadius: "50%", width: "60%", }} />
                                            <div className="card-body">
                                                <h6 className={`${styles.infoTitle} card-title`}>{card.title}</h6>
                                                <p className={`${styles.gameText} card-text`}>{card.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className={`${styles.mobileGamesRow} container-fluid m-auto p-0 mb-5`} style={{ width: "100%" }}>
                            <Slider {...gameCarouselSettings}>
                                {
                                    infoCards.map((card) => {
                                        return <div className="col">
                                            <div className={`${styles.gameCard} card text-center border-0 h-100 m-auto py-3 px-3`}>
                                                <img src={card.image} className="img-fluid m-auto" alt="Responsive image" style={{ borderRadius: "50%", width: "60%", }} />
                                                <div className="card-body">
                                                    <h6 className={`${styles.infoTitle} card-title`}>{card.title}</h6>
                                                    <p className={`${styles.gameText} card-text`}>{card.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Slider>
                        </div>
                    </div>

                </div>

                <div id="howToPlay" className="container pb-sm-5 pb-md-0">
                    <div className="row w-100 m-auto">
                        <div className="ratio ratio-16x9" align="center">
                            <iframe src="https://www.youtube.com/embed/W60H_Xvi4qw" title="BigPotato.TV - How to play" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className="row w-100 m-auto">
                        <div className="col-xs-6 col-md-4 p-0 pt-1 pr-1 m-auto" style={{ maxWidth: "50%" }}>
                            <img src={Screenshot1} className="img-fluid w-100" alt="Responsive image" />
                        </div>
                        <div className="col-xs-6 col-md-4 p-0 pt-1 px-1 m-auto" style={{ maxWidth: "50%" }}>
                            <img src={Screenshot2} className="img-fluid w-100" alt="Responsive image" />
                        </div>
                        <div className="col-xs-12 col-md-4 p-0 pt-1 pl-1 m-auto">
                            <img src={Screenshot3} className="img-fluid w-100" alt="Responsive image" />
                        </div>
                    </div>
                </div>
                <div className={`${styles.carouselSection} ${styles.reviewSection} my-5`} style={{
                    backgroundImage: `url(${ReviewBg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                }}>
                    <div className="container-fluid m-auto p-0" style={{ width: "100%" }}>
                        {/*<div className="row mt-5 w-100 m-auto mb-5" >*/}

                        {/*</div>*/}
                        <Slider {...carouselSettings}>
                            {
                                reviews.map((review) => {
                                    return <div className={`${styles.reviewWidth} container p-0`}>
                                        <div className={`${styles.reviewCard} card text-center mx-3 border-0 p-3`}>
                                            <h5 className={`${styles.reviewTitle} card-title`}>{review.name}</h5>
                                            <div className="row d-flex w-50 m-auto">
                                                {Array.from(Array(5), (e, i) => {
                                                    return <div className="col p-1">
                                                        <img className="img-fluid m-auto" style={{}} src={i <= review.stars - 1 ? StarFull : StarEmpty} />
                                                    </div>
                                                })}
                                            </div>
                                            <div className="card-body">

                                                <p className={`${styles.reviewText} card-text`}>{review.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                </div>
                <div className="container-fluid p-0 mb-5">
                    <div className={`${styles.woodBg} row w-100 m-auto  py-5`} style={{
                        backgroundImage: `url(${WoodBg})`,
                    }}>
                        <div className="row w-100 m-auto mb-3 mt-3">
                            <h2 className={`${styles.gamesHeader} text-center text-white`}>WHICH ONE WILL YOU PLAY FIRST?</h2>
                        </div>
                        <div className={`${styles.gamesRow} row m-auto p-0 mb-5`}>
                            {
                                games.map((game) => {
                                    return <div className="col">
                                        <div className={`${styles.gameCard} card text-center border-0 h-100`}>
                                            <img src={game.image} className="img-fluid" alt="Responsive image" />
                                            <h3 className={`${styles.gameTitle} card-title mt-4`}>{game.name}</h3>
                                            <div className="card-body">
                                                {/*<p className={`${styles.gameText} card-text`}>{game.quip}</p>*/}
                                                <p className={`${styles.gameText} card-text`}>{game.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className={`${styles.mobileGamesRow} container-fluid m-auto p-0 mb-5`} style={{ width: "100%" }}>
                            <Slider {...gameCarouselSettings}>
                                {
                                    games.map((game) => {
                                        return <div className="col">
                                            <div className={`${styles.gameCard} card text-center border-0 h-100`}>
                                                <img src={game.image} className="img-fluid" alt="Responsive image" />
                                                <h3 className={`${styles.gameTitle} card-title mt-4`}>{game.name}</h3>
                                                <div className="card-body">
                                                    {/*<p className={`${styles.gameText} card-text`}>{game.quip}</p>*/}
                                                    <p className={`${styles.gameText} card-text`}>{game.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Slider>
                        </div>
                    </div>

                    <div className="row w-100 w-md-75 m-auto mt-5 mb-5 pb-4 position-relative">
                        <button type="button" onClick={this.clickBuyNow} className={`${styles.packButton} btn btn-lg`} style={{ marginRight: "5%", }}>
                            BUY NOW
                        </button>
                        <img src={BuyHereIcon} className="position-absolute d-md-flex d-sm-none d-none" style={{ width: "14%", left: "15%", }} />
                        <button type="button" onClick={() => this.props.history.push("/play?showfaq=1")} className={`${styles.packButton} ${styles.blue} btn btn-lg`} style={{ marginLeft: "5%", }}>
                            FAQ
                        </button>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <iframe src="https://store.steampowered.com/widget/1884480/" frameborder="0" width="646" height="190" className={styles.widget}></iframe>*/}
                    {/*</div>*/}
                </div>
                {/*<Footer />*/}
            </React.Fragment>
        )
    }
}

export default withRouter(Pack1);