import React, { useState, useEffect } from "react";
import { useHistory, Link } from 'react-router-dom';
import { Modal, OverlayTrigger, Tooltip, } from 'react-bootstrap';
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector, connect, } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Elements } from "@stripe/react-stripe-js";
import http from 'helpers/http';
import { Container } from 'reactstrap';
import LayoutBoxWide from 'components/layout/layout-box/LayoutBoxWide.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';
import styles from 'components/api-authorization/AuthorizationStyles.module.scss';

import { checkUserExists, checkUserHasSteamKey, checkUserGamePack, applyGames } from "actions/auth";
import { increaseQuantity, lowerQuantity, removeFromCart, } from "actions/cart";

import { fireConversionEvent } from "actions/analytics";

import PackLogo from "images/packs/mobile-logo.png";

import landingBanner from 'images/landing/BigPotatoBanner.png';

import stripeService from "services/stripe.service.js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const Checkout = (props) => {
    const [clientSecret, setClientSecret] = useState("");
    const [isSilk, setSilk] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [showCodes, setShowCodes] = useState(false);
    const [products, setProducts] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [ownedPlatform, setOwnedPlatform] = useState('');
    const [productPricing, setProductPricing] = useState({});
    const [productLogo, setProductLogo] = useState("");
    const [productName, setProductName] = useState("");
    const [gamePackId, setGamePackId] = useState(0);

    const { isLoggedIn, user } = useSelector(state => state.auth);
    const { Pack1Pricing, Pack2Pricing } = useSelector(state => {
        return state.pricing;
    });
    const { items, } = useSelector(state => state.cart);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("new cart items: ");
        console.log(items);
    }, [items]);

    // Effectively componentDidMount
    useEffect(() => {
        setSilkLoad();
        const queryParams = new URLSearchParams(window.location.search);

        const sessionId = queryParams.get("session_id");

        if (sessionId) {
            setShowSuccess(true);
            stripeService.generateCodeFromCheckout(sessionId).then(res => {
                const { orderProducts, email, amount, paymentCurrency, } = res.data;
                setShowCodes(true);
                setProducts(orderProducts);
                setMessage("Payment succeeded, generating activation code...");
                fireConversionEvent({
                    eventName: "Purchase",
                    email: email,
                    currency: paymentCurrency,
                    price: amount / 100,
                    sessionId: sessionId,
                });

                //dispatch(checkUserGamePack())
                //    .then(() => {
                //        console.log("Apply voucher!");
                //        http.post(process.env.REACT_APP_API_URL + "api/Authenticate/ApplyVoucherCode", {
                //            voucherCode: code
                //        }).then((response) => {
                //            if (response.data.status == "Success") {
                //                dispatch(applyGames(response.data.games));

                //                history.push(`/authentication/welcome?email=${email}&fp=true`);
                //            } else {
                //                history.push(`/authentication/register?voucher=${code}&email=${email}&fc=true`);
                //            }
                //        }).catch(() => {
                //            history.push(`/authentication/register?voucher=${code}&email=${email}&fc=true`);
                //        });
                //    })
                //    .catch(() => {
                //        console.log("Don't apply voucher!");
                //        history.push(`/authentication/register?voucher=${code}&email=${email}&fc=true`);
                //    });
            }).catch(e => {
                console.error(e);
                setShowSuccess(false);
                setMessage("");
            });
        } else {
            if (isLoggedIn) {
                setEmail(user.email)
            }

            fireConversionEvent({
                eventName: "AddToCart",
                currency: productPricing.currency,
                price: productPricing.rawPrice,
            });
        }
    }, []);

    const clickCode = (code) => {
        history.push(`/authentication/register?voucher=${code}`);
    }

    const calculateTotal = (items) => {
       
    };

    const addQuantity = (packId) => {
        dispatch(increaseQuantity(packId));
    }
    const minusQuantity = (packId) => {
        dispatch(lowerQuantity(packId));
    }
    const removeItem = (packId) => {
        dispatch(removeFromCart(packId));
    }

    const goToCheckout = (e) => {
        e.preventDefault();


        if (!email) {
            setMessage("Please provide an email!");
            return;
        }

        setIsLoading(true);

        beginCheckout();

        //dispatch(checkUserExists(email))
        //    .then(() => {
        //        setOwnedPlatform('Web');
        //        setIsOpenModal(true);
        //        return;
        //    })
        //    .catch(() => {
        //        dispatch(checkUserHasSteamKey(email))
        //            .then(() => {
        //                setOwnedPlatform('Steam');
        //                setIsOpenModal(true);
        //                return;
        //            })
        //            .catch(() => {
        //                beginCheckout();
        //            });
        //    });
    }

    const beginCheckout = () => {
        stripeService.createCheckoutSession(email, items).then((res) => {
            fireConversionEvent({
                eventName: "InitiateCheckout",
                email,
                currency: productPricing.currency,
                price: productPricing.rawPrice,
            }).finally(() => {
                window.location = res.data.url;
            });
        }).catch((err) => {
            setIsLoading(false);
            setMessage("Please enter a valid email address.")
        });
    }

    const setSilkLoad = (e) => {
        if (/\bSilk\b/.test(navigator.userAgent)) {
            setSilk(true);
        }
    };

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    const doClickNo = () => {
        if (isLoggedIn) {
            history.push("/");
        } else {
            history.push("/authentication/login");
        }
    }

    const doClickYes = () => {
        setIsOpenModal(false);
        beginCheckout();
    }

    const closeModal = () => {
        setIsLoading(false);
        setIsOpenModal(false);
    }

    const renderTooltip = (props) => (
        <Tooltip  {...props}>
            Redeem Code
        </Tooltip>
    );

    return (
        <div className={styles.containerBox} style={{ height: "auto" }}>
            <div className={styles.containerRegister}>
                {/*<img className={isSilk ? styles.bannerImageSilk + " position-relative" : styles.bannerImage + " position-relative"} src={landingBanner} />*/}
                <Container className="h-100 p-0 mt-5">
                    <div className={isSilk ? styles.rowCardSilk + " row justify-content-center w-100 h-100 m-0" : styles.rowCard + " row justify-content-center w-100 h-100 m-0"}>
                        <LayoutBoxWide wider={true} className="card text-center mt-0 my-auto p-0 mx-auto mb-5">
                            {
                                showSuccess ?
                                    <LayoutBoxHeader className="card-header mt-0">
                                        {
                                            showCodes ?
                                                <React.Fragment>
                                                    <h6 className={isSilk ? styles.cardSubTitleSilk + " card-title pb-0" : styles.cardSubTitle + " card-title pb-0"}>Thank you for your purchase!</h6>
                                                    {/*<h6 className={isSilk ? styles.cardSubTitleSilk + " card-title pb-0" : styles.cardSubTitle + " card-title pb-0"}></h6>*/}
                                                    {/*<h6 className={isSilk ? styles.cardSubTitleSilk + " card-title pb-0" : styles.cardSubTitle + " card-title pb-0"}></h6>*/}
                                                    <div className="px-3 pt-2" style={{ marginTop: "10px", fontFamily: "Interstate-Regular", }}>
                                                        <div align="center" >
                                                            We have sent an activation code to your email address.
                                                            If you are purchasing for a friend, you can forward that email to them and should FINISH NOW.
                                                        </div>
                                                        <div align="center" className="mt-3">
                                                            If you are ready to activate for yourself, hit ACTIVATE.
                                                        </div>
                                                        <div className={`list-group list-group-flush text-start`}>
                                                            {
                                                                products.map((product) => {
                                                                    return <div className={`list-group-item`}>
                                                                        <div className={styles.cardText} style={{ fontWeight: "bold" }}>{product.productName}</div>
                                                                        <div className={`list-group list-group-flush`}>
                                                                            {
                                                                                product.codes.map((code) => {
                                                                                    return <div className={`${styles.cardTextSmaller} list-group-item`}>
                                                                                        <OverlayTrigger
                                                                                            trigger="hover"
                                                                                            placement="right"
                                                                                            delay={{ show: 100, hide: 100 }}
                                                                                            overlay={renderTooltip}
                                                                                        >
                                                                                            <button type="button"
                                                                                                onClick={() => clickCode(code)}
                                                                                                className={`${styles.cardTextSmaller} btn btn-link`}
                                                                                                style={{ color: "#e6017d", fontWeight: "bold" }}                                                                       >
                                                                                                {code}
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className="spinner-border mt-5 mb-5" role="status"></div>
                                                    {message && <div id="payment-message" className={styles.paymentMessage}>{message}</div>}
                                                </React.Fragment>
                                        }
                                    </LayoutBoxHeader>
                                    :
                                    <LayoutBoxHeader className="card-header mt-0">
                                        <h5 className={isSilk ? styles.cardTitleSilk + " card-title pb-0" : styles.cardTitle + " card-title pb-0"}>Checkout</h5>
                                        {
                                            items.length > 0 ?
                                                <React.Fragment>
                                                    <Container className="mt-3">
                                                        <div className="row">
                                                            <div className="col-md-7 order-md-2 mb-4">
                                                                <label>Your Cart</label>
                                                                <ul className="list-group mb-3">
                                                                    {
                                                                        items.map(item => {
                                                                            return <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                                                <div>
                                                                                    <h6 className="my-0">{item.name}</h6>
                                                                                    <div className="d-flex mt-3 align-middle text-center" style={{ width: "fit-content",}}>
                                                                                        <button className="btn btn-primary btn-sm px-2" onClick={() => minusQuantity(item.packId)}>
                                                                                            <FontAwesomeIcon size="sm" icon={faMinus} />
                                                                                        </button>
                                                                                        <div className="m-auto fs-5 px-3">{item.quantity}</div>
                                                                                        <button className="btn btn-primary btn-sm px-2" onClick={() => addQuantity(item.packId)}>
                                                                                            <FontAwesomeIcon size="sm" icon={faPlus} />
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <span className="text-muted">{item.price}</span>
                                                                                    <br />
                                                                                    <button type="button" class="btn btn-danger btn-sm px-2 mt-3" onClick={() => removeItem(item.packId)}>
                                                                                        <FontAwesomeIcon size="sm" icon={faTrash} />
                                                                                    </button>
                                                                                </div>
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-5 order-md-1">
                                                                <form onSubmit={goToCheckout} id="payment-form">
                                                                    <div className="row">
                                                                        <div className="mb-3">
                                                                            <label for="email">Email</label>
                                                                            <input
                                                                                id="email"
                                                                                type="email"
                                                                                value={email}
                                                                                onChange={(e) => setEmail(e.target.value)}
                                                                                placeholder="Enter email address"
                                                                                className="form-control"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <button disabled={isLoading} className={styles.stripeButton} id="submit">
                                                                        <span id="button-text">
                                                                            {isLoading ? <div className={styles.spinner} id="spinner"></div> : "Checkout"}
                                                                        </span>
                                                                    </button>
                                                                </form>
                                                            </div>
                                                        </div>

                                                    </Container>
                                                </React.Fragment>
                                                :
                                                <LayoutBoxBody className={styles.cardBody + " card-body pt-0 pb-0 mt-3 mb-3"}>
                                                    <p className={styles.modalText}>Your cart is empty...</p>
                                                    <button onClick={() => history.push("/packs")} disabled={isLoading} className={styles.stripeButton} id="submit">
                                                        <span id="button-text">
                                                            CONTINUE SHOPPING
                                                        </span>
                                                    </button>
                                                </LayoutBoxBody>
                                        }

                                    </LayoutBoxHeader>

                            }
                        </LayoutBoxWide>
                    </div>
                </Container>
            </div>

            <Modal id="UserExists" size="md" centered show={isOpenModal} onHide={closeModal}>
                <Modal.Header className="border-0" closeButton></Modal.Header>
                <Modal.Header className="border-0 text-center pt-0">
                    <h5 className={`${styles.modalTitle} pt-0`}>You already own Big Screen Games Pack 1 for {ownedPlatform}</h5>
                </Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <p className={styles.modalText}><Link onClick={doClickNo}>Click here to play</Link>. Or if you wish to purchase another code for yourself or a friend, click CONTINUE...</p>
                </Modal.Body>
                <Modal.Footer className="border-0 pt-0 justify-content-center">
                    <button className={styles.submitBtn} onClick={doClickYes}>CONTINUE</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Checkout;