import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/PlayerStyles.module.scss";

import countdownCircle from "animations/countdown-circle.js";

import redX from "images/red-x.png";

export default class Player extends Component {

    constructor(props) {
        super(props);

        let fontSize = this.getFontSize(props.player);
        this.state = {
            animSpeed: (20 / props.player.connectingTimer),
            fontSize: fontSize,
        };
        this.animRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ fontSize: this.getFontSize(nextProps.player), });
    }

    getFontSize(player) {
        if (player) {
            let name = player.name;
            let fontSize = "";
            if (name.length <= 3) {
                fontSize = "large";
            } else if (name.length <= 6) {
                fontSize = "medium";
            } else {
                fontSize = "small";
            }
            return fontSize;
        } else {
            return "medium";
        }
    }


    removePlayer(id) {
        this.props.room.send("remove_player", { id });
    }

    render() {
        return (
            <div id={this.props.player.id} className={styles.player}>
                <img src={redX} className={styles.redX} onClick={() => this.removePlayer(this.props.player.id)} />
                <div
                    id={`potato-${this.props.player.id}`}
                    name={`potato-${this.props.player.id}`}
                    className={`${styles.potato}`}
                    alt="AvatarImage"
                >
                    {
                        !this.props.player.connected ?
                            <div className={styles.countdownContainer}>
                                <Lottie
                                    options={countdownCircle}
                                    width="100%"
                                    height="100%"
                                    isClickToPauseDisabled={true}
                                    speed={this.state.animSpeed}
                                />
                            </div>
                            :
                            null
                    }
                    <Lottie
                        options={getAvatarById(this.props.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                        style={this.props.player.connected ? {} : { opacity: 0.25, }}
                    />
                </div>
                <div className={`${styles.name} ${this.state.fontSize == "large" ? styles.large : this.state.fontSize == "medium" ? styles.medium : styles.small}`}>{this.props.player.name}</div>
                <div className={`${styles.disconnectingText} ${this.props.player.connected ? styles.hidden : ""}`}>Disconnecting!</div>
            </div>
        )
    }
}