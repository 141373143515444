import React, { Component } from 'react';
import Lottie from 'react-lottie';

import cosBgAnim from 'animations/cos-background.js';
import cosLogo from "images/cos-logo.png";
import scrawlLogo from "images/scrawl-logo.png";
import herdLogo from "images/herd-logo.png";

import getAvatarById from "constants/avatars";
import styles from './LoadingStyles.module.scss';
import loadingAnim from 'animations/hourglass.js';

const gameIds = {
    CanOfSquirms: "can_of_squirms",
    Scrawl: "scrawl",
    HerdMentality: "herd_mentality"
};

export default class Loading extends Component {


    render() {
        return (
            this.props.gameId == gameIds.CanOfSquirms ?
                <div className={styles.loadingBG}>
                    <img src={cosLogo} className={styles.cosLogo} alt="CosLogo" />
                    <Lottie
                        options={cosBgAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                        style={{ position: "absolute", }}
                    />
                </div>
                :
                this.props.gameId == gameIds.HerdMentality ?
                    <div className={`${styles.loadingBG} ${styles.herd}`}>
                        <img src={herdLogo} className={styles.herdLogo} alt="HerdLogo" />
                    </div>
                    :
                    this.props.gameId == gameIds.Scrawl ?
                        <div className={`${styles.loadingBG} ${styles.scrawl}`}>
                            <img src={scrawlLogo} className={styles.scrawlLogo} alt="ScrawlLogo"/>
                        </div>
                        :
                        <div className={`${styles.loadingBG} ${this.props.noBg ? styles.noBg : ""}`}>
                            <div className={styles.loadingContainer}>
                                <div className={styles.animContainer}>
                                    <Lottie options={loadingAnim} height="100%" width="100%" isClickToPauseDisabled={true} />
                                </div>
                                <div className={styles.text}>{this.props.loadingText}</div>
                                {/*<div className={styles.barSection}>*/}
                                {/*    <div className={styles.barContainer}></div>*/}
                                {/*    <BarLoader css={{ "margin": "auto", "left": 0, "right": 0, "bottom": 0, "top": 0, "opacity": this.props.hideLoader ? 0 : 1, }} color={"#EC0080"} loading={true} width={"72%"} height={"2.5vh"} />*/}
                                {/*</div>*/}
                            </div>
                        </div>
        )
    }

}