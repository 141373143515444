import React from 'react'
import { Component } from 'react';
//import authService from './AuthorizeService';
//import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import LoginBox from './authorization-forms/LoginBox.js';
import RegisterBox from './authorization-forms/RegisterBox.js';
import { ConfirmEmail } from './authorization-forms/ConfirmEmail.js';
import { ForgotPassword } from './authorization-forms/ForgotPassword.js';
import { ResetPassword } from './authorization-forms/ResetPassword.js';
import Profile from 'components/Profile.js';
import { WelcomeBox } from './authorization-forms/WelcomeBox.js';
import VoucherCode from './VoucherCode';
import styles from 'components/api-authorization/AuthorizationStyles.module.scss';
import { RecoverSteamKeys } from './authorization-forms/RecoverSteamKeys';
import Checkout from './authorization-forms/Checkout';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: undefined
        };
    }

    componentDidMount() {
        const action = this.props.action;
        switch (action) {
            case LoginActions.Login:
                this.login(this.getReturnUrl());
                break;
            case LoginActions.VoucherCode:
                //this.login(this.getReturnUrl());
                break;
            //case LoginActions.LoginCallback:
            //    this.processLoginCallback();
            //    break;
            case LoginActions.LoginFailed:
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                this.setState({ message: error });
                break;
            case LoginActions.Profile:
                //this.redirectToProfile();
                document.body.style.overflowY = "scroll";
                break;
            case LoginActions.ConfirmEmail:
                //this.redirectToConfirmEmail();
                break;
            case LoginActions.ForgotPassword:
                //this.redirectToConfirmEmail();
                break;
            case LoginActions.ResetPassword:
                //this.redirectToConfirmEmail();
                break;
            case LoginActions.Register:
                //this.redirectToRegister();
                break;
            case LoginActions.Welcome:
                //this.redirectToRegister();
                break;
            case LoginActions.RecoverSteamKey:
            case LoginActions.Checkout:
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    render() {
        const action = this.props.action;
        const { message } = this.state;

        if (!!message) {
            return <div>{message}</div>
        } else {
            switch (action) {
                case LoginActions.Login:
                    return (<div className={styles.containerBox}><LoginBox /></div>);
                case LoginActions.VoucherCode:
                    return (<div className={styles.containerBox}><VoucherCode /></div>);
                //case LoginActions.LoginCallback:
                //    return (<div>Processing login callback</div>);
                case LoginActions.Profile:
                    return (<div className="h-100"><Profile /></div>);
                case LoginActions.Register:
                    return (<div className={styles.containerBox}><RegisterBox /></div>);
                case LoginActions.ConfirmEmail:
                    return (<div className={styles.containerBox}><ConfirmEmail /></div>);
                case LoginActions.ForgotPassword:
                    return (<div className={styles.containerBox}><ForgotPassword /></div>);
                case LoginActions.ResetPassword:
                    return (<div className={styles.containerBox}><ResetPassword /></div>);
                case LoginActions.Welcome:
                    return <WelcomeBox />;
                case LoginActions.RecoverSteamKey:
                    return <RecoverSteamKeys />;
                case LoginActions.Checkout:
                    return <Checkout  />;
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        }
    }

    async login(returnUrl) {
        const state = { returnUrl };
        //const result = await authService.signIn(state);
        //switch (result.status) {
        //    case AuthenticationResultStatus.Redirect:
        //        break;
        //    case AuthenticationResultStatus.Success:
        //        await this.navigateToReturnUrl(returnUrl);
        //        break;
        //    case AuthenticationResultStatus.Fail:
        //        this.setState({ message: result.message });
        //        break;
        //    default:
        //        throw new Error(`Invalid status result ${result.status}.`);
        //}
    }

    //async processLoginCallback() {
    //    const url = window.location.href;
    //    const result = await authService.completeSignIn(url);
    //    switch (result.status) {
    //        case AuthenticationResultStatus.Redirect:
    //            // There should not be any redirects as the only time completeSignIn finishes
    //            // is when we are doing a redirect sign in flow.
    //            throw new Error('Should not redirect.');
    //        case AuthenticationResultStatus.Success:
    //            await this.navigateToReturnUrl(this.getReturnUrl(result.state));
    //            break;
    //        case AuthenticationResultStatus.Fail:
    //            this.setState({ message: result.message });
    //            break;
    //        default:
    //            throw new Error(`Invalid authentication result status '${result.status}'.`);
    //    }
    //}

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.Profile);
    }

    redirectToConfirmEmail() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.ConfirmEmail);
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath) {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser they get sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    navigateToReturnUrl(returnUrl) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }
}
