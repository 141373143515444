const animation = require("./herd-intro.json");

module.exports = {
    loop: false,
    autoplay: false,
    prerender: true,
    renderer: "svg",
    animationData: animation,
    rendererSettings: {
        preserveAspectRatio: 'xMidyMid'
    },
}