import {
    UPDATE_PRICING
} from "actions/types";
import { history } from "helpers/history";
import { getPrice, } from "services/stripe.service";

export const setPrice = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_PRICING,
        payload: data,
    });
};

