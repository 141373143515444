import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { Login } from './Login'
//import { Logout } from './Logout'
import { ApplicationPaths, LoginActions, /*LogoutActions*/ } from './ApiAuthorizationConstants';
import PrivateRoute from 'components/PrivateRoute';

export default class ApiAuthorizationRoutes extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <Fragment>
                <Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)} />
                <Route path={ApplicationPaths.VoucherCode} render={() => loginAction(LoginActions.VoucherCode)} />
                <Route path={ApplicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)} />
                <Route path={ApplicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)} />
                <Route path={ApplicationPaths.Register} render={() => loginAction(LoginActions.Register)} />
                <Route path={ApplicationPaths.ConfirmEmail} render={() => loginAction(LoginActions.ConfirmEmail)} />
                <Route path={ApplicationPaths.ForgotPassword} render={() => loginAction(LoginActions.ForgotPassword)} />
                <Route path={ApplicationPaths.ResetPassword} render={() => loginAction(LoginActions.ResetPassword)} />
                <Route path={ApplicationPaths.Profile} render={() => loginAction(LoginActions.Profile)} />
                <Route path={ApplicationPaths.Welcome} render={() => loginAction(LoginActions.Welcome)} />
                <Route path={ApplicationPaths.RecoverSteamKey} render={() => loginAction(LoginActions.RecoverSteamKey)} />
                <Route path={ApplicationPaths.Checkout} render={() => loginAction(LoginActions.Checkout)} />
                {/*<Route path={ApplicationPaths.LogOut} render={() => logoutAction(LogoutActions.Logout)} />*/}
                {/*<Route path={ApplicationPaths.LogOutCallback} render={() => logoutAction(LogoutActions.LogoutCallback)} />*/}
                {/*<Route path={ApplicationPaths.LoggedOut} render={() => logoutAction(LogoutActions.LoggedOut)} />*/}
            </Fragment>);
    }
}

function loginAction(name) {
    return (<Login action={name}></Login>);
}

//function logoutAction(name) {
//    return (<Logout action={name}></Logout>);
//}
