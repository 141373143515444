import React, { Component } from 'react';
//import { Route } from 'react-router';
import { Route, withRouter } from 'react-router-dom'; // change for node version
import * as Colyseus from "colyseus.js";
import Loading from "components/Loading.js";
import Player from "components/Player";
import Lottie from 'react-lottie';
import QRCode from 'qrcode.react';
import { Howl, Howler } from "howler";
import { Alert, Modal, ModalBody, Row, Accordion } from 'react-bootstrap';
import chevronUp from 'images/landing/ChevronUp.png';
import faq from 'images/landing/Asset_FAQ_resized.png';
import ReactPlayer from 'react-player';

import cosIntro from 'animations/cos-intro.js';
import scrawlIntro from 'animations/scrawl-intro.js';
import herdIntro from 'animations/herd-intro.js';
import ageAnim from 'animations/age.js';
import playersAnim from 'animations/players.js';
import gameInfoAnim from 'animations/gameInfo.js';
import sadPotato from 'animations/sadPotato.js';

//import squirmsComingSoonIcon from "images/squirms-coming-soon.png";
import squirmsIcon from "images/squirms-icon.png";
import scrawlIcon from "images/scrawl-icon.png";
import herdIcon from "images/herd-icon.png";
import herdBg from "images/herd-background.png";
import scrawlBg from "images/scrawl-background.png";
import squirmsBg from "images/squirms-background.png";
import herdBoardGame from "images/herd-boardgame.png";
import scrawlBoardGame from "images/scrawl-boardgame.png";
import squirmsBoardGame from "images/squirms-boardgame.png";
import mutedIcon from "images/lobby_muted.png";
import unmutedIcon from "images/lobby_unmuted.png";
import fullscreenIcon from "images/lobby_fullscreen.png";
import helpIcon from "images/lobby_help.png";
import betaBanner from "images/beta-banner.png";

import lobbyBgMusic from "audio/lobby-bg-music.mp3";
import joinedSFX from "audio/player-joined.wav";
import disconnectingSFX from "audio/disconnecting.wav";
import disconnectedSFX from "audio/disconnected.wav";
import hoverOrClickSFX from "audio/hover-click.mp3";
import newGameSFX from "audio/new-game.wav";
import errorSFX from "audio/error.wav";

import styles from 'components/lobbys/Pack1LobbyStyles.module.scss';
import { connect } from 'react-redux';
import "animate.css";

import { getGames } from 'actions/auth.js';
import store from 'store/reduxstore.js';
import http from 'helpers/http';

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

const gameIds = {
    ColourBrain: "colour_brain",
    Chameleon: "chameleon",
    DrawWith: "draw_with",
};

const topTips = [
    "Top Tip #1: Been disconnected? Just refresh your page!",
    "Top Tip #2: Hit 'Back' on your browser to return to the lobby.",
    "Top Tip #3: Playing over a video call is easy. Just get the Host to share their screen.",
    "Top Tip #4: Tap your Potato to make it do a little dance!",
    "Top Tip #5: Did you know? The world's largest potato weighed around six tonnes.",
]

class Pack2Lobby extends Component {
    static displayName = Pack2Lobby.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        console.log("client : " + JSON.stringify(this.client));

        this.state = {
            roomId: 0,
            roomState: null,
            room: null,
            myId: null,
            redirect: null,
            redirectURL: null,
            selectedGame: null,
            loading: true,
            players: [],
            inRoom: false,
            waitingForResponse: false,
            muted: false,
            manualMuted: false,
            notifications: [],
            showVideo: false,

            doTransition: false,
            transitionOptions: {},
            messageStatus: null,
            tips: [],
            tipIndex: 0,
            showAlert: false,
            showAlertText: '',
            isOpenFaqs: false,


            showColourBrain: false,
            showChameleon: false,
            showDrawWith: false,
            gamePacks: 0
        };

        this.animRef = React.createRef();
    }

    openFaqsModal = () => this.setState({ isOpenFaqs: true });

    componentDidMount() {
        http.get(process.env.REACT_APP_API_URL + "api/Authenticate/GetUserGamePacks")
            .then((res) => {
                const gamePacks = res.data.gamePacks;
                if (!gamePacks.find(x => x.id == 2)) {
                    this.props.history.push("authentication/voucher-code");
                } else {
                    document.body.style.overflowY = "hidden";
                    this.setGamePacks(gamePacks);
                    const roomId = this.getQueryStringValue("roomId");
                    const sessionId = this.getQueryStringValue("sessionId");
                    if (roomId) {
                        this.doReconnect(roomId, sessionId);
                    } else {
                        this.doCreate();
                    }

                    this.initAudio();

                    this.newTipInterval(true);
                    this.browserVersion();
                }
            }).catch((err) => {
                console.log(err);
            });
    }

    initAudio() {
        let mutedVal = this.getMuteSetting();
        mutedVal = mutedVal ? mutedVal == "false" ? false : true : false;
        this.toggleMute(true, mutedVal);
        this.preloadAudio([
            disconnectingSFX,
            disconnectedSFX,
            newGameSFX,
            errorSFX,
            joinedSFX,
            hoverOrClickSFX,
        ]);
        Howler.volume(0.5);
        this.playAudio(lobbyBgMusic, true, 0.15);
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    setGamePacks(gamePacks) {
        this.setState({ gamePacks, });
    }

    browserVersion() {
        try {
            //browser detection
            var browser = {};
            if (/edge\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "edge";
                browser.majorVersion = parseInt(/edge\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /edge\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/chrome\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "chrome";
                browser.majorVersion = parseInt(/chrome\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /chrome\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/firefox\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "firefox";
                browser.majorVersion = parseInt(/firefox\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /firefox\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/opr\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "opera";
                browser.majorVersion = parseInt(/opr\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /opera\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/CriOS/i.test(navigator.userAgent)) {
                browser.agent = "chrome";
                browser.majorVersion = parseInt(/CriOS\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /CriOS\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/Safari\/[0-9.]+/i.test(navigator.userAgent)) {
                browser.agent = "safari";
                browser.majorVersion = parseInt(/Version\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /Version\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else {
                browser.agent = false;
                browser.majorVersion = false;
                browser.version = false;
            }

            if (browser.agent == "chrome") {
                if (browser.majorVersion < 4) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser version not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "edge") {
                if (browser.majorVersion < 8) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser version not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "firefox") {
                if (browser.majorVersion < 3.5) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "safari") {
                if (browser.majorVersion < 4) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "opera") {
                if (browser.majorVersion < 11.5) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser not supported " + browser.version
                    });
                }
            }

            setTimeout(() => {
                this.setState({
                    showAlert: false
                });
            }, 30000);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        if (this.state.room) {
            this.state.room.leave(true);
        }
        document.body.style.overflowY = "visible";
        Howler.unload();
    }

    storeMuteSetting(value) {
        localStorage.setItem("game_muted", value);
    }

    getMuteSetting() {
        return localStorage.getItem('game_muted');
    }

    newTipInterval(onMount = true) {
        if (onMount) {
            this.setState(prevState => {
                for (let i = 0; i < topTips.length; i++) {
                    const newTip = topTips[i];
                    prevState.tips.push({
                        text: newTip,
                        fadeIn: i === 0 ? true : false,
                        fadeOut: false,
                        reset: false
                    });
                }
                return {
                    tips: prevState.tips
                }
            }, () => {
                setTimeout(() => {
                    this.newTipInterval(false);
                }, 7000);
            });
        } else {

            setTimeout(() => {

                this.setState(prevState => {
                    let newIndex;
                    if (prevState.tipIndex === topTips.length - 1) {
                        newIndex = 0;
                    } else {
                        newIndex = prevState.tipIndex + 1;
                    }
                    return {
                        tipIndex: newIndex
                    }
                }, () => {
                    this.setState(prevState => {
                        let oldIndex = prevState.tipIndex === 0 ? topTips.length - 2 : prevState.tipIndex === 1 ? topTips.length - 1 : prevState.tipIndex - 2;
                        let prevIndex = prevState.tipIndex === 0 ? topTips.length - 1 : prevState.tipIndex - 1;
                        let newIndex = prevState.tipIndex;
                        prevState.tips[oldIndex].reset = true;
                        prevState.tips[oldIndex].fadeIn = false;
                        prevState.tips[oldIndex].fadeOut = false;
                        prevState.tips[prevIndex].reset = false;
                        prevState.tips[prevIndex].fadeIn = false;
                        prevState.tips[prevIndex].fadeOut = true;
                        prevState.tips[newIndex].reset = false;
                        prevState.tips[newIndex].fadeOut = false;
                        prevState.tips[newIndex].fadeIn = true;
                        return {
                            tips: prevState.tips
                        }
                    }, () => {
                        setTimeout(() => {
                            this.newTipInterval(false);
                        }, 7000)
                    });
                });
            }, 100);

        }
    }

    preloadAudio(audioFiles) {
        for (let i = 0; i < audioFiles.length; i++) {
            let audio = new Howl({
                src: [audioFiles[i]],
                preload: true
            });
        }
    }

    playAudio(audioFile, loop = false, volume = 1) {
        //let audio = new Audio(audioFile);
        //audio.play();
        let audio = new Howl({
            src: [audioFile],
            loop: loop,
            volume: volume,
        });
        audio.play();
    }

    toggleMute(force = false, value = false, manual = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = this.state.muted == true ? false : true;
        }

        console.log("muted new val : " + newVal)

        this.storeMuteSetting(newVal);

        Howler.mute(newVal);
        this.setState({ muted: newVal, manualMuted: manual, });
    }

    goToGame(gameId) {
        let redirectURL;
        let options;
        switch (gameId) {
            case gameIds.ColourBrain:
                redirectURL = process.env.REACT_APP_COLOURBRAIN_URL;
                options = cosIntro;
                break;
            case gameIds.Chameleon:
                redirectURL = process.env.REACT_APP_CHAMELEON_URL;
                options = herdIntro;
                break;
            case gameIds.DrawWith:
                redirectURL = process.env.REACT_APP_DRAWWITH_URL;
                options = scrawlIntro;
                break;
        }

        if (redirectURL) {
            this.setState({ doTransition: true, transitionOptions: options, redirectURL, });
        }
    }

    doRedirect = () => {
        this.setState({ redirect: `${this.state.redirectURL}/?roomId=${this.state.roomId}&sessionId=${this.state.myId}` });
        this.state.room.leave(false);
    }

    updateAvatar(sessionId, avatarId) {
        // 1. Make a shallow copy of the items
        let players = [...this.state.players];
        // 2. Make a shallow copy of the item you want to mutate
        let pos = players.map(function (e) { return e.id; }).indexOf(sessionId);
        let player = { ...players[pos] };
        // 3. Replace the property you're intested in
        player.avatar = avatarId;
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        players[pos] = player;
        // 5. Set the state to our new copy
        this.setState({ players });
    }

    updateNickname(sessionId, nickName) {
        // 1. Make a shallow copy of the items
        let players = [...this.state.players];
        // 2. Make a shallow copy of the item you want to mutate
        let pos = players.map(function (e) { return e.id; }).indexOf(sessionId);
        let player = { ...players[pos] };
        // 3. Replace the property you're intested in
        player.name = nickName;
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        players[pos] = player;
        // 5. Set the state to our new copy
        this.setState({ players });
    }

    setConnected(id, connected) {
        let players = [...this.state.players];
        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.connected = connected;

        players[pos] = player;

        this.setState({ players });
    }

    updateConnectTimer(id, timer) {
        let players = [...this.state.players];
        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.connectingTimer = timer;

        players[pos] = player;

        this.setState({ players });
    }

    doNotification(text, ignoreCheck = false) {
        if (ignoreCheck || !this.state.notifications.includes(text)) {
            this.setState((prevState) => {
                return { notifications: [...prevState.notifications, text] }
            });
            setTimeout(() => {
                this.setState((prevState) => {
                    prevState.notifications.shift();
                    return { notifications: prevState.notifications }
                });
            }, 4000);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    doReconnect(roomId, sessionId) {
        this.client.reconnect(roomId, sessionId).then(room => {
            console.log(room.sessionId, "joined", room.name);

            this.setState({ room: room, roomId: room.id, myId: room.sessionId, loading: false, inRoom: true, });

            room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
                room.send("host_joined_lobby");
                //let players = [];
                //state.players.forEach((value, key) => {
                //    this.addPlayerToList(value);
                //});
                //this.setState({ roomState: state, });
            });
            room.onStateChange((state) => {
                console.log(room.name, "has new state:", state);
                //let players = [];
                //state.players.forEach((value, key) => {
                //    players.push(value);
                //});
                //this.setState({ roomState: state, players, });
            });

            room.onMessage("player_joined", (message) => {
                console.log("player_joined", "received on", room.name, message);
                this.playAudio(joinedSFX, false, 1);
                this.doNotification(`${message.player.name} has joined!`, true);
                this.addPlayerToList(message.player);
            });
            room.onMessage("player_leave", (message) => {
                console.log("player_leave", "received on", room.name, message);
                this.playAudio(disconnectedSFX, false, 1);
                this.doNotification(`${message.player.name} has left!`, true);
                this.removePlayerFromList(message.sessionId);
            });
            room.onMessage("show_players", (message) => {
                console.log("show_players", "received on", room.name, message);
                for (const [key, value] of Object.entries(message.players)) {
                    this.addPlayerToList(value);
                }
            });
            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                this.playAudio(newGameSFX, false, 1);
                this.goToGame(message.gameId);
            });
            room.onMessage("cant_start", (message) => {
                console.log("cant_start", "received on", room.name, message);
                this.playAudio(errorSFX, false, 1);
                this.setState({ waitingForResponse: false, });
                if (this.state.players.length >= 3) {
                    this.doNotification("Not all players are connected!");
                } else {
                    this.doNotification("Not enough players to start!");
                }
            });
            room.onMessage("update_avatar", (message) => {
                console.log("update_avatar", "received on", room.name, message);
                if (message.sessionId) {
                    this.updateAvatar(message.sessionId, message.avatarId);
                }
            });
            room.onMessage("update_nickname", (message) => {
                console.log("update_avatar", "received on", room.name, message);
                if (message.sessionId) {
                    this.updateNickname(message.sessionId, message.nickName);
                }
            });
            room.onMessage("set_player_connected", (message) => {
                console.log("set_player_connected", "received on", room.name, message);
                this.setConnected(message.id, message.connected);
            });
            //room.onMessage("update_connect_timer", (message) => {
            //    console.log("update_connect_timer", "received on", room.name, message);
            //    this.updateConnectTimer(message.id, message.timer);
            //});
            room.onMessage("animate_potato", (message) => {
                console.log("animate_potato", "received on", room.name, message);
                this.animatePotato(message.id, message.animation);
            });

            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);
                if (!this.state.redirect) {
                    this.setState({ inRoom: false, roomId: "", myId: "", loading: false, inRoom: false, players: [], });
                }
            });

            room.onError((code, message) => {
                console.log(this.client.id, "couldn't join", room.name);
            });
        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ loading: false, });
        });
    }

    doCreate() {
        console.log(this.props);
        this.setState({ loading: true, })
        this.client.create("pack_2_room", {
            isHost: true,
            accessToken: this.props.auth.user.token
        }).then(room => {
            // show games based on user owned games

            for (var i = 0; i < this.props.auth.user.games.length; i++) {
                if (this.props.auth.user.games[i]["name"] === "Colour Brain") {
                    this.setState({ showColourBrain: true });
                }
                else if (this.props.auth.user.games[i]["name"] === "Chameleon") {
                    this.setState({ showChameleon: true });
                }
                else if (this.props.auth.user.games[i]["name"] === "Draw with Dave") {
                    this.setState({ showDrawWith: true });
                }
            }

            console.log(room.sessionId, "joined", room.name);

            this.setState({ roomId: room.id, myId: room.sessionId, room: room, loading: false, inRoom: true, });

            room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
                room.send("host_joined_lobby");
            });
            room.onStateChange((state) => {
                console.log(room.name, "has new state:", state);
            });

            room.onMessage("player_joined", (message) => {
                console.log("player_joined", "received on", room.name, message);
                this.playAudio(joinedSFX, false, 1);
                this.doNotification(`${message.player.name} has joined!`, true);
                this.addPlayerToList(message.player);
            });
            room.onMessage("player_leave", (message) => {
                console.log("player_leave", "received on", room.name, message);
                this.doNotification(`${message.player.name} has left!`, true);
                this.playAudio(disconnectedSFX, false, 1);
                this.removePlayerFromList(message.sessionId);
            });
            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                this.playAudio(newGameSFX, false, 1);
                this.goToGame(message.gameId);
            });
            room.onMessage("cant_start", (message) => {
                console.log("cant_start", "received on", room.name, message);
                this.playAudio(errorSFX, false, 1);
                this.setState({ waitingForResponse: false, });
                if (this.state.players.length >= 3) {
                    this.doNotification("Not all players are connected!");
                } else {
                    this.doNotification("Not enough players to start!");
                }
            });
            room.onMessage("update_avatar", (message) => {
                console.log("update_avatar", "received on", room.name, message);
                if (message.sessionId) {
                    this.updateAvatar(message.sessionId, message.avatarId);
                }
            });
            room.onMessage("update_nickname", (message) => {
                console.log("update_nickname", "received on", room.name, message);
                if (message.sessionId) {
                    this.updateNickname(message.sessionId, message.nickName);
                }
            });
            room.onMessage("set_player_connected", (message) => {
                console.log("set_player_connected", "received on", room.name, message);
                this.setConnected(message.id, message.connected);
            });

            room.onMessage("animate_potato", (message) => {
                console.log("animate_potato", "received on", room.name, message);
                this.animatePotato(message.id, message.animation);
            });
            //room.onMessage("update_connect_timer", (message) => {
            //    console.log("update_connect_timer", "received on", room.name, message);
            //    this.updateConnectTimer(message.id, message.timer);
            //});
            room.onError((code, message) => {
                console.log(this.client.id, "couldn't join", room.name);
            });
            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);
                if (!this.state.redirect) {
                    this.setState({ inRoom: false, roomId: "", myId: "", loading: false, inRoom: false, players: [], });
                }
            });

        }).catch(err => {
            console.log("JOIN ERROR");
            console.log(JSON.stringify(err));
            this.setState({ loading: false, });
        });
    }

    startGame = () => {
        if (this.state.selectedGame) {
            this.setState({ waitingForResponse: true, });
            this.state.room.send("StartGame", { gameId: this.state.selectedGame });
        }
    }

    gameActivity = () => {
        this.startGame();
    }

    removePlayerFromList(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id != id), }
        });
    }

    addPlayerToList(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    selectGame(gameId) {
        if (gameId != "") {
            this.playAudio(hoverOrClickSFX, false, 0.35);
        }
        this.setState({ selectedGame: gameId, });
    }

    setAlert(alert) {
        this.setState({ showAlert: alert, });
    }

    clickFAQS() {
        this.setState({
            isOpenFaqs: true,
        });
    }

    clickSetUp() {
        if (!this.state.muted) this.toggleMute();
        this.playAudio(hoverOrClickSFX, false, 0.35);
        this.setState({
            showVideo: true,
        });
    }

    unClickSetUp() {
        if (this.state.muted && !this.state.manualMuted) this.toggleMute();
        this.playAudio(hoverOrClickSFX, false, 0.35);
        this.setState({ showVideo: false, });
    }

    render() {
        if (this.state.redirect) {
            return (
                <div>
                    <Route path="/" render={() => (window.location = this.state.redirect)} />
                    <Loading gameId={this.state.selectedGame} loadingText={"Sending you to the game!"} />
                </div>
            )
        }

        return (
            this.state.games == 0 ? <Loading loadingText={"Redirecting now..."} /> :
                this.state.loading ?
                    <Loading loadingText={"Creating your room..."} />
                    :
                    this.state.inRoom ?
                        <div className={styles.lobbyContainer}>
                            {/*<img src={betaBanner} className={styles.betaBanner} />*/}
                            {
                                this.state.showVideo ?
                                    <React.Fragment>
                                        <div className={styles.videoBg} onClick={() => this.unClickSetUp()} />
                                        <div className={styles.videoContainer}>
                                            <ReactPlayer
                                                url="https://www.youtube.com/watch?v=W60H_Xvi4qw"
                                                playing={true}
                                                controls={true}
                                                width="100%"
                                                height="100%"
                                                stopOnUnmount={true}
                                            />
                                            <input className={styles.button} type="submit" value="I'M READY!" onClick={() => this.unClickSetUp()} />
                                        </div>

                                    </React.Fragment>
                                    :
                                    null
                            }

                            <Modal id="ModalFAQS" fullscreen className={styles.modalOverlayOpen} show={this.state.isOpenFaqs} onHide={() => this.setState({ isOpenFaqs: false })}>
                                <Modal.Header></Modal.Header>
                                <ModalBody className="rowFaqsScroll">

                                    <div style={{ backgroundColor: "white", width: "100%", paddingTop: "40px", paddingBottom: "40px", height: "100%" }}>
                                        <div className="container">
                                            <div className="rowHowItWorks row">
                                                <div className="col text-center">
                                                    <Row className="colFullRow colFullRowHow">
                                                        <button type="button" className={this.state.isSilk ? "howItWorksLandingSilk btn" : "howItWorksLanding btn"} onClick={() => this.setState({ isOpenOverlay: false, isOpenFaqs: false })}>
                                                            <img className={this.state.isSilk ? "chevronUpSilk" : "chevronUp"} src={chevronUp} />
                                                            Back to lobby page
                                                        </button>
                                                    </Row>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: "20px" }} id="faqrowid">
                                                <div className="col-12" align="center">
                                                    <img className="img-fluid" src={faq} />
                                                </div>
                                            </div>
                                            <Accordion style={{ marginBottom: "10px" }} id="faqAccordion">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header> <span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>DO I NEED TO SIGN IN OR REGISTER TO JOIN A GAME?</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        Nope! Only the host needs to sign in with their account - all you need to do is 'Join a Game' with the room code your hosts gives you.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header><span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>I'VE BEEN DISCONNECTED FROM THE GAME!</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        No worries - just hit the 'reconnect' button - you'll be back in no time
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header><span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>WE'RE ALL READY, BUT THE GAME WON'T START!</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        Do you have enough players for that game? Check the top right corner for a little message that tells you if you have too many or too few players for that game.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header><span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>I CAN'T SIGN IN!</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        {/*<p>Have you registered your account yet? You'll need to do this using the code we sent you when you bought the package</p>*/}
                                                        {/*<p>Check for typos and make sure you're using the right email address (this will be the account that we sent the verification email to!)</p>*/}
                                                        <p>Having trouble? <a target="_blank" href="https://bigpotato.co.uk/pages/help">Get in Touch</a></p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header><span style={{ fontFamily: "interstate-compressed, sans-serif", fontSize: "30px" }}>CAN I HOST THIS ON MY SMART TV?</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        Big Potato TV works best when hosted from a laptop or desktop computer, as those devices tend to have a faster processing speed. If you want to get this on your Big Screen -  we recommend going old school and hooking your screen up via an HDMI cable.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>

                                </ModalBody>
                            </Modal>
                            <Alert className={styles.lobbyAlert} show={this.state.showAlert} variant="danger" onClose={() => this.setAlert(false)} dismissible>
                                <Alert.Heading className={styles.lobbyAlertHeader}>You got an error! {this.state.showAlertText}.</Alert.Heading>
                                <p className={styles.lobbyAlertHeader + " mb-0"}>
                                    This browser version is not supported. You may experience issues while playing.
                                </p>
                            </Alert>
                            <div className={styles.lobbySection}>
                                <div className={styles.lobbyTitle}><span className={`${styles.lobbyTitle} ${styles.smaller}`}>WELCOME TO</span><br />BIG POTATO TV!</div>
                                <div className={styles.roomInfo}>
                                    <div className={styles.roomText}>
                                        Head to <a className={`${styles.roomText} ${styles.highlight}`}>bigpotato.tv</a> on your phone and join using the room code: <span className={`${styles.roomText} ${styles.code}`}>{this.state.roomId}</span>
                                        {/*To join the game head to <a className={`${styles.roomText} ${styles.highlight}`}>bigpotato.tv</a> on your phone and use the room code: <span className={`${styles.roomText} ${styles.code}`}>{this.state.roomId}</span>*/}
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode className={styles.qrCode} value={`${process.env.REACT_APP_HOME_URL}/play/?qrCode=${this.state.roomId}`} />
                                    </div>
                                </div>
                                <div className={styles.buttonSection}>
                                    <input className={styles.button} type="submit" value="HOW TO SET UP" onClick={() => this.clickSetUp()} />
                                </div>
                                <div className={styles.playersSection}>
                                    <div className={styles.playersTitle}>WHO'S IN?</div>
                                    <div className={styles.playersBox}>
                                        {
                                            this.state.players.length == 0 ?
                                                <div className={styles.empty}>
                                                    <div className={styles.emptyText}>No players connected...</div>
                                                    <div className={styles.emptyPotato}>
                                                        <Lottie options={sadPotato} width="100%" height="100%" isClickToPauseDisabled={true} />
                                                    </div>
                                                </div>
                                                :
                                                this.state.players.map((player) => {
                                                    return <Player player={player} room={this.state.room} />
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={styles.gamesSection}>
                                <div className={styles.iconsBox}>
                                    <div className={styles.muteToggle} onClick={() => this.toggleMute(false, false, true)}>
                                        <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} />
                                    </div>
                                    {
                                        fullscreenAvailable ?
                                            <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                <img src={fullscreenIcon} className={styles.muteIcon} />
                                            </div>
                                            :
                                            null
                                    }

                                    <div className={styles.muteToggle} onClick={this.openFaqsModal}>
                                        <img src={helpIcon} className={styles.muteIcon} />
                                    </div>
                                </div>
                                <div className={styles.notificationsContainer}>
                                    {
                                        this.state.notifications.map((notification) => {
                                            return <div className={styles.notification}>{notification}</div>
                                        })
                                    }
                                </div>
                                <div className={styles.gamesTitle}>SELECT A GAME:</div>
                                <div className={styles.gamesContainer}>
                                    {this.props.auth.user.games.find(x => x.code == 'draw_with') &&
                                        <div className={`${styles.game} ${styles.scrawl} ${styles.active}`}
                                            onMouseEnter={() => this.selectGame(gameIds.DrawWith)}
                                            onMouseLeave={() => this.selectGame("")}
                                            onClick={this.gameActivity}
                                        >
                                            <img id="drawWithGame"
                                                src={scrawlIcon}
                                                className={`${styles.gameImg}`}
                                            />
                                        </div>}
                                    {this.props.auth.user.games.find(x => x.code == 'chameleon') &&
                                        <div className={`${styles.game} ${styles.herdMentality} ${styles.active}`}
                                            onMouseEnter={() => this.selectGame(gameIds.Chameleon)}
                                            onMouseLeave={() => this.selectGame("")}
                                            onClick={this.gameActivity}
                                        >
                                            <img id="chameleonGame"
                                                src={herdIcon}
                                                className={`${styles.gameImg}`}
                                            />
                                        </div>}
                                    {this.props.auth.user.games.find(x => x.code == 'colour_brain') && <div className={`${styles.game} ${styles.canOfSquirms}  ${styles.active}`}
                                        onMouseEnter={() => this.selectGame(gameIds.ColourBrain)}
                                        onMouseLeave={() => this.selectGame("")}
                                        onClick={this.gameActivity}
                                    >
                                        <img id="colourBrainGame"
                                            src={squirmsIcon}
                                            className={`${styles.gameImg}`}
                                        />
                                    </div>}
                                </div>
                                <div className={styles.chosenGameSection}>
                                    <div className={`${styles.gameInfo} ${styles.squirms} ${this.state.selectedGame == gameIds.ColourBrain ? styles.show : ""}`}>
                                        <img src={squirmsBg} className={styles.gameBg} />
                                        <div className={styles.contentSection}>
                                            <div className={`${styles.gameTitle} ${styles.squirms}`}>ColourBrain</div>
                                            <div className={styles.gameDescription}>
                                                Colour Brain demo description...
                                            </div>
                                            <div className={styles.detailsList}>
                                                <div className={styles.detail}>
                                                    <div className={styles.detailImg}>
                                                        <Lottie options={playersAnim} width="150%" height="150%" isClickToPauseDisabled={true}
                                                            style={{ left: "50%", transform: "translate(-50%, -50%)", top: "50%", position: "absolute", }}
                                                        />
                                                    </div>
                                                    <div className={styles.detailText}>3-8 PLAYERS</div>
                                                </div>
                                                <div className={styles.detail}>
                                                    <div className={styles.detailImg}>
                                                        <Lottie options={ageAnim} width="150%" height="150%" isClickToPauseDisabled={true}
                                                            style={{ left: "50%", transform: "translate(-50%, -50%)", top: "50%", position: "absolute", }}
                                                        />
                                                    </div>
                                                    <div className={styles.detailText}>AGE 14+</div>
                                                </div>
                                                <div className={styles.detail}>
                                                    <div className={styles.detailImg}>
                                                        <Lottie options={gameInfoAnim} width="150%" height="150%" isClickToPauseDisabled={true}
                                                            style={{ left: "50%", transform: "translate(-50%, -50%)", top: "50%", position: "absolute", }}
                                                        />
                                                    </div>
                                                    <div className={styles.detailText}>OVER 200 QUESTIONS TO TRY</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.gameInfo} ${styles.scrawl} ${this.state.selectedGame == gameIds.DrawWith ? styles.show : ""}`}>
                                        <img src={scrawlBg} className={styles.gameBg} />
                                        <div className={styles.contentSection}>
                                            <div className={`${styles.gameTitle} ${styles.scrawl}`}>Draw With Dave</div>
                                            <div className={styles.gameDescription}>
                                                Draw with Dave demo description...
                                            </div>
                                            <div className={styles.detailsList}>
                                                <div className={styles.detail}>
                                                    <div className={styles.detailImg}>
                                                        <Lottie options={playersAnim} width="150%" height="150%" isClickToPauseDisabled={true}
                                                            style={{ left: "50%", transform: "translate(-50%, -50%)", top: "50%", position: "absolute", }}
                                                        />
                                                    </div>
                                                    <div className={styles.detailText}>3-8 PLAYERS</div>
                                                </div>
                                                <div className={styles.detail}>
                                                    <div className={styles.detailImg}>
                                                        <Lottie options={ageAnim} width="150%" height="150%" isClickToPauseDisabled={true}
                                                            style={{ left: "50%", transform: "translate(-50%, -50%)", top: "50%", position: "absolute", }}
                                                        />
                                                    </div>
                                                    <div className={styles.detailText}>AGE 14+</div>
                                                </div>
                                                <div className={styles.detail}>
                                                    <div className={styles.detailImg}>
                                                        <Lottie options={gameInfoAnim} width="150%" height="150%" isClickToPauseDisabled={true}
                                                            style={{ left: "50%", transform: "translate(-50%, -50%)", top: "50%", position: "absolute", }}
                                                        />
                                                    </div>
                                                    <div className={styles.detailText}>BAD ARTISTS WELCOME!</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.gameInfo} ${styles.herd} ${this.state.selectedGame == gameIds.Chameleon ? styles.show : ""}`}>
                                        <img src={herdBg} className={styles.gameBg} />
                                        <div className={styles.contentSection}>
                                            <div className={`${styles.gameTitle} ${styles.herd}`}>CHAMELEON</div>
                                            <div className={`${styles.credits} ${styles.herd}`}>By Dan Penn & Rich Coombes</div>
                                            <div className={styles.gameDescription}>
                                                Chameleon demo description...
                                            </div>
                                            <div className={styles.detailsList}>
                                                <div className={styles.detail}>
                                                    <div className={styles.detailImg}>
                                                        <Lottie options={playersAnim} width="150%" height="150%" isClickToPauseDisabled={true}
                                                            style={{ left: "50%", transform: "translate(-50%, -50%)", top: "50%", position: "absolute", }}
                                                        />
                                                    </div>
                                                    <div className={styles.detailText}>3-8 PLAYERS</div>
                                                </div>
                                                <div className={styles.detail}>
                                                    <div className={styles.detailImg}>
                                                        <Lottie options={ageAnim} width="150%" height="150%" isClickToPauseDisabled={true}
                                                            style={{ left: "50%", transform: "translate(-50%, -50%)", top: "50%", position: "absolute", }}
                                                        />
                                                    </div>
                                                    <div className={styles.detailText}>AGE 10+</div>
                                                </div>
                                                <div className={styles.detail}>
                                                    <div className={styles.detailImg}>
                                                        <Lottie options={gameInfoAnim} width="150%" height="150%" isClickToPauseDisabled={true}
                                                            style={{ left: "50%", transform: "translate(-50%, -50%)", top: "50%", position: "absolute", }}
                                                        />
                                                    </div>
                                                    <div className={styles.detailText}>WATCH OUT FOR THE PINK COW!</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.tipsContainer} ${this.state.selectedGame == "" || this.state.selectedGame == null ? styles.show : ""}`}>
                                        {
                                            this.state.tips.map((x) => {
                                                return <div id={`${x.id}`} className={`${styles.tip} ${x.fadeIn ? styles.fadeIn : x.fadeOut ? styles.fadeOut : x.reset ? styles.reset : ""}`}>{x.text.split(":").map((i, key) => {
                                                    return <div key={key}>{i}</div>
                                                })}</div>
                                                //return <div id={`${x.id}`} className={`${styles.tip} ${x.style}`}>{x.text}</div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.doTransition ?
                                    <React.Fragment>
                                        <div className={styles.logoAnimBacker}></div>
                                        <div className={styles.logoAnimContainer}>
                                            <Lottie
                                                options={this.state.transitionOptions}
                                                width="100%"
                                                height="100%"
                                                isClickToPauseDisabled={true}
                                                eventListeners={[
                                                    {
                                                        eventName: 'DOMLoaded',
                                                        callback: () => {
                                                            console.log("DOMLoaded")
                                                            this.animRef.play()
                                                        }
                                                    },
                                                    {
                                                        eventName: 'complete',
                                                        callback: this.doRedirect,
                                                    },
                                                ]}
                                                ref={animation => {
                                                    this.animRef = animation;
                                                }}
                                            />
                                        </div>
                                    </React.Fragment>

                                    :
                                    null
                            }
                        </div>
                        :
                        <div className={styles.errorContainer}>
                            <div className={styles.errorText}>Oh no!<br />Something went wrong...</div>
                            <div className={styles.animContainer}>
                                <Lottie options={sadPotato} width="100%" height="100%" isClickToPauseDisabled={true} />
                            </div>
                            <div className={`${styles.errorText} ${styles.smaller}`}>Click below to create a new room!</div>
                            <div id="createButton" className={styles.pinkButton} onClick={() => this.doCreate()}>Create Room</div>
                        </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default withRouter(connect(mapStateToProps)(Pack2Lobby));