import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import pricing from "./pricing";
import cart from "./cart";

export default combineReducers({
    auth,
    message,
    pricing,
    cart,
});
