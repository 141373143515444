import { SET_MESSAGE, CLEAR_MESSAGE } from "actions/types";

const initialState = {
    message: '',
    list: null
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_MESSAGE:
            let list = payload.list || null;
            return {
                message: payload.message,
                list: list
            };

        case CLEAR_MESSAGE:
            return initialState;

        default:
            return state;
    }
}
