

export const fireGtmConversionEvent = (options) => {
    console.log("firing gtag event");
    switch (options.eventName) {
        case "ViewContent":
            console.log("ViewContent");
            window.gtag('event', 'view_item', {
                'send_to': [
                    'UA-229778159-1',
                    'UA-69017987-18'
                ],
                'value': 0,
                'currency': options.currency,
                'items': [
                    {
                        "id": "SKU_Pack1",
                        "name": "Pack 1"
                    },
                ]
            });
            break;
        case "AddToCart":
            console.log("add_to_cart");
            window.gtag('event', 'add_to_cart', {
                'send_to': [
                    'UA-229778159-1',
                    'UA-69017987-18'
                ],
                'value': 0,
                'currency': options.currency,
                'items': [
                    {
                        "id": "SKU_Pack1",
                        "name": "Pack 1"
                    },
                ]
            });
            window.gtag('event', 'conversion', {
                'send_to': 'AW-10904677970/AdYICNa0msADENLc4M8o',
                'value': 0,
                'currency': options.currency,
                'items': [
                    {
                        "id": "SKU_Pack1",
                        "name": "Pack 1"
                    },
                ]
            });

            //window.gtag('event', 'conversion', {
            //    'send_to': 'AW-10904677970/hnqoCPTtzMADENLc4M8o',
            //    'value': 0,
            //    'currency': 'GBP',
            //    'items': [
            //        {
            //            "id": "SKU_Pack1",
            //            "name": "Pack 1"
            //        },
            //    ]
            //});
            break;
        case "InitiateCheckout":
            window.gtag('event', 'begin_checkout', {
                'send_to': [
                    'UA-229778159-1',
                    'UA-69017987-18'
                ],
                'value': 0,
                'currency': options.currency,
                'items': [
                    {
                        "id": "SKU_Pack1",
                        "name": "Pack 1"
                    },
                ]
            });
            window.gtag('event', 'conversion', {
                'send_to': 'AW-10904677970/dFl2CN6xzcADENLc4M8o',
                'value': 0,
                'currency': options.currency,
                'items': [
                    {
                        "id": "SKU_Pack1",
                        "name": "Pack 1"
                    },
                ]
            });
            //window.gtag('event', 'conversion', {
            //    'send_to': 'AW-10904677970/FBwSCOfHt8ADENLc4M8o',
            //    'value': 0,
            //    'currency': options.currency,
            //    'items': [
            //        {
            //            "id": "SKU_Pack1",
            //            "name": "Pack 1"
            //        },
            //    ]
            //});
            break;
        case "Purchase":
            window.gtag('event', 'purchase', {
                'send_to': [
                    'UA-229778159-1',
                    'UA-69017987-18'
                ],
                'value': options.price,
                'currency': options.currency,
                'transaction_id': options.sessionId,
                'items': [
                    {
                        "id": "SKU_Pack1",
                        "name": "Pack 1"
                    },
                ]
            });
            window.gtag('event', 'conversion', {
                'send_to': 'AW-10904677970/AYw-CJXvsb4DENLc4M8o',
                'value': options.price,
                'currency': options.currency,
                'transaction_id': options.sessionId,
                'items': [
                    {
                        "id": "SKU_Pack1",
                        "name": "Pack 1"
                    },
                ]
            });
            break;
    }
}
//'send_to': 'AW-10904677970/AYw-CJXvsb4DENLc4M8o',
