import React from 'react'
import { Component } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import LayoutBox from 'components/layout/layout-box/LayoutBox.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';
import styles from 'components/api-authorization/AuthorizationStyles.module.scss';
import landingBanner from 'images/landing/BigPotatoBanner.png';
import axios from "axios";
import { Alert, Button } from 'react-bootstrap';

export class WelcomeBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            count: 0,
            success: false,
            error: false,
            showAlert: false,
            showAlertText: '',
            isSilk: false,
            copyText: "Copy",
        };
    }

    resendConfirmationEmail = (e) => {
        this.setState({
            count: this.state.count + 1,
            success: false,
            error: false
        });

        if (this.state.count >= 3) {
            this.setState({
                success: false,
                error: true
            });
        }
        else {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            const API_URL = process.env.REACT_APP_API_URL;

            var data = JSON.stringify({
                "Email": params.get('email')
            });

            var config = {
                method: 'post',
                url: API_URL + 'api/Authenticate/resend',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            return axios(config)
                .then((response) => {
                    e.preventDefault();
                    console.log(JSON.stringify(response.data));
                    this.setState({
                        success: true,
                        error: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });
        }
    }

    componentDidMount() {
        this.browserVersion();
        this.setSilk();
    }

    setSilk() {
        //this works to detect silk browser
        if (/\bSilk\b/.test(navigator.userAgent)) {
            this.setState({
                isSilk: true
            });
        }
    }

    browserVersion() {
        try {
            //browser detection
            var browser = {};
            if (/edge\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "edge";
                browser.majorVersion = parseInt(/edge\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /edge\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/chrome\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "chrome";
                browser.majorVersion = parseInt(/chrome\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /chrome\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/firefox\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "firefox";
                browser.majorVersion = parseInt(/firefox\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /firefox\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/opr\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "opera";
                browser.majorVersion = parseInt(/opr\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /opera\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/CriOS/i.test(navigator.userAgent)) {
                browser.agent = "chrome";
                browser.majorVersion = parseInt(/CriOS\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /CriOS\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/Safari\/[0-9.]+/i.test(navigator.userAgent)) {
                browser.agent = "safari";
                browser.majorVersion = parseInt(/Version\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /Version\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else {
                browser.agent = false;
                browser.majorVersion = false;
                browser.version = false;
            }

            if (browser.agent == "chrome") {
                if (browser.majorVersion < 4) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser version not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "edge") {
                if (browser.majorVersion < 8) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser version not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "firefox") {
                if (browser.majorVersion < 3.5) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "safari") {
                if (browser.majorVersion < 4) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "opera") {
                if (browser.majorVersion < 11.5) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser not supported " + browser.version
                    });
                }
            }

            setTimeout(() => {
                this.setState({
                    showAlert: false
                });
            }, 30000);
        } catch (e) {
            console.log(e);
        }
    }

    updateClipboard(newClip) {
        navigator.clipboard.writeText(newClip).then(
            () => {
                this.setState({ copyText: "Copied"});
            },
            () => {
                this.setState({ copyText: "Copy" });
            }
        );
    }

    copyLink = (text) => {
        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    this.updateClipboard(text);
                }
            });
    }

    render() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let email = params.get('email');
        let key = params.get('key');
        let fp = params.get('fp');
        const { isSilk } = this.state;

        return (
            <div className={styles.containerBox} style={{ height: "auto" }}>
                <div className={styles.containerRegister}>
                    <Alert className={styles.lobbyAlert} show={this.state.showAlert} variant="danger" onClose={() => this.setAlert(false)} dismissible>
                        <Alert.Heading className={styles.lobbyAlertHeader}>You got an error! {this.state.showAlertText}.</Alert.Heading>
                        <p className={styles.lobbyAlertHeader + " mb-0"}>
                            This browser version is not supported. You may experience issues while playing.
                        </p>
                    </Alert>
                    {/*<img className={isSilk ? styles.bannerImageSilk + " position-relative" : styles.bannerImage + " position-relative"} src={landingBanner} />*/}
                    <Container className="p-0">
                        <div className="row justify-content-center m-0">
                            <LayoutBox className="card text-center my-auto p-0 mx-auto">
                                <LayoutBoxHeader className="card-header mt-0">
                                    <h5 className={styles.cardTitle + " card-title pb-0"}>ALL FINISHED!</h5>
                                </LayoutBoxHeader>
                                <LayoutBoxBody className={styles.cardBody + " card-body pt-0"}>
                                    {!key &&
                                        fp ?
                                        <div className={styles.cardPDiv}>
                                            <p className={this.state.isSilk ? styles.cardPSilk : styles.cardP}>Thanks {email}, for your purchase! Your voucher code has been applied for you.</p>
                                        </div>
                                        :
                                        <div className={styles.cardPDiv}>
                                            <p className={this.state.isSilk ? styles.cardPSilk : styles.cardP}>Congratulations {email}, you have successfully signed up.</p>
                                        </div>
                                    }
                                    {!key &&
                                        <div className={styles.cardPDiv}>
                                        <p className={this.state.isSilk ? styles.cardPSilk : styles.cardP}><Link to="/lobby">Click here</Link> to start playing!</p>
                                        </div>
                                    }
                                    {!!key &&
                                        <div className={styles.cardPDiv}>
                                            <p className={`${this.state.isSilk ? styles.cardPSilk : styles.cardP} ${styles.textLeft}`}>That's everything! You can use this code to claim your copy of Big Screen Games on Steam.</p>
                                        </div>
                                    }
                                    {!!key &&
                                        <div className={styles.cardPDiv}>
                                            <label className={`${isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} ${styles.textLeft} ${styles.steamKeyBoxLabel}`}>
                                            Your Steam Key <a onClick={() => this.copyLink(key)} className="badge" style={{ color: "#1E9DD7", padding: "0.35rem 0.1rem" }}>{this.state.copyText}</a>
                                            </label>
                                            <br />
                                            <label className={`${isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} ${styles.steamKeyBox}`}>
                                                {key}
                                            </label>
                                        {/*<Button onClick={() => this.copyLink(key)} className={styles.forgottenLink} variant="success">{this.state.copyText}</Button>*/}
                                        </div>
                                    }
                                    {!!key &&
                                        <div className={styles.cardPDiv}>
                                            <p className={`${this.state.isSilk ? styles.cardPSilk : styles.cardP} ${styles.textLeft}`}>We've also sent a copy to {email}, just in case.<br /><br />Have fun!</p>
                                        </div>
                                    }
                                    {!!key &&
                                        <div className={styles.cardPDiv}>
                                        {/*<a className={`${isSilk ? styles.backStepBtnSilk : styles.backStepBtn} ${styles.steamBtn} btn`} href="steam://openurl/https://store.steampowered.com/app/1884480/Big_Screen_Games__Pack_1/">Open Steam</a>*/}
                                        <a className={`${isSilk ? styles.backStepBtnSilk : styles.backStepBtn} ${styles.steamBtn} btn`} href="steam://open/activateproduct">Open Steam</a>
                                        </div>
                                    }
                                    {/*<div className={styles.cardPDiv}>*/}
                                    {/*    <p className={this.state.isSilk ? styles.cardPSilk : styles.cardP}>If you did not receive an email to confirm your account please check your junk folder. Click <button className="btn btn-link p-0 align-bottom" type="button" onClick={this.resendConfirmationEmail}>here</button> to re-send the confirmation email. </p>*/}
                                    {/*</div>*/}
                                    <div className={styles.cardPDiv}>
                                        {this.state.success ? <div className="alert alert-success" role="alert">Email confirmation has successfully resent. Please check your email to confirm!</div> : ""}
                                        {this.state.error ? <div className="alert alert-danger" role="alert">Unfortunately you have resent too many times. If you still have not received your email please <a target="_blank" href="https://bigpotato.co.uk/pages/help">contact support</a>. It maybe in your junk folder.</div> : ""}
                                    </div>
                                </LayoutBoxBody>
                            </LayoutBox>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}