import React from "react";
import { Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row, Col } from 'reactstrap';
import LayoutBox from 'components/layout/layout-box/LayoutBox.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';

import styles from 'components/api-authorization/AuthorizationStyles.module.scss';
import 'components/api-authorization/ReactTabsStyles.scss';
import userProfile from 'images/user-profile.png';

const Profile = () => {
    const { user: currentUser } = useSelector((state) => state.auth);

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    return (
        <div className={styles.containerBoxLarge}>
            <div className={styles.containerProfile}>
                <Container>
                    <div className="w-100">
                        <div className="w-100 m-0">
                            <LayoutBox className={styles.box_large + " card text-center mx-auto"}>
                                <LayoutBoxHeader className="card-header">
                                    <h5 className={styles.cardTitle + " card-title"}>User Account</h5>
                                </LayoutBoxHeader>
                                <LayoutBoxBody className={styles.cardBodyLeft + " card-body"}>
                                    <Tabs defaultIndex={1} onSelect={index => console.log(index)} id="user-tab-example" className="mb-3">
                                        <TabList>
                                            <Tab title="Home">
                                                Home
                                            </Tab>
                                            <Tab title="Profile">
                                                Profile
                                            </Tab>
                                            <Tab title="Contact" disabled>
                                                Contact
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            Welcome, {currentUser.email}<br />
                                            We're so glad you're here!
                                        </TabPanel>
                                        <TabPanel>
                                            <Container>
                                                <Row className="w-100">
                                                    <Col className="p-0" sm={4}>
                                                        <div className="card w-100" style={{ border: '0' }}>
                                                            <img src={userProfile} className="card-img-top" alt="UserProfile" />
                                                            <div className="card-body">
                                                                <h5 className={styles.cardTitle + " card-title"}><strong>{currentUser.email}</strong> Profile</h5>
                                                                <p>
                                                                    <strong>Token:</strong> {currentUser.token.substring(0, 20)} ...{" "}
                                                                    {currentUser.token.substr(currentUser.token.length - 20)}
                                                                </p>
                                                                <p>
                                                                    <strong>Id:</strong> {currentUser.id}
                                                                </p>
                                                                <p>
                                                                    <strong>Email:</strong> {currentUser.email}
                                                                </p>
                                                                <strong>Authorities:</strong>
                                                                <ul>
                                                                    {currentUser.roles &&
                                                                        currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
                                                                </ul>
                                                                <a href="/lobby" className={styles.submitBtn + " btn"}>Go to lobby</a>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="p-0" sm={8}>
                                                        <div className="card mt-5 shadow-sm">
                                                            <div className="card-body">
                                                                <p>
                                                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </TabPanel>
                                        <TabPanel>
                                            Contact details
                                        </TabPanel>
                                    </Tabs>
                                </LayoutBoxBody>
                            </LayoutBox>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Profile;
