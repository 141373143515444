import React, { Component, Suspense } from 'react';
import Loading from 'components/Loading';
import Pack2Lobby from './Pack2Lobby';
import Pack1Lobby from './Pack1Lobby';

//const Lobby = React.lazy(() => import('components/Lobby-old'));

export class LobbyWrapper extends Component {
    static displayName = LobbyWrapper.name;

    constructor(props) {
        super(props);

        this.state = {
            games: 0,
        };
    }

    componentDidMount() {
        var games = localStorage.getItem('games');

        this.gamePacks(games);

       
    }

    componentDidUpdate(prevProps) {
        
    }

    gamePacks(games) {
        this.setState({ games: games });
    }

    render() {

        let lobbyIndex = 0;
        if (this.props.location.pathname == "/lobby") {
            lobbyIndex = 1;
        } else if (this.props.location.pathname == "/lobby2") {
            lobbyIndex = 2;
        }
        const Lobby = lobbyIndex == 1 ? Pack1Lobby : Pack2Lobby;

        return (
            this.state.games != 0 ?
                <Suspense fallback={<Loading loadingText="Gathering some tatos..." />}>
                    <Lobby />
                </Suspense>
                :
                <Suspense fallback={<Loading loadingText="Voucher expired. Redirecting to voucher enter page..." />}>
                    <Lobby />
                </Suspense>
        );
    }
}
