import React from 'react'
import { Component } from 'react';
import { Container } from 'reactstrap';
import LayoutBox from 'components/layout/layout-box/LayoutBox.js';
import LayoutBoxHeader from 'components/layout/layout-box/LayoutBoxHeader.js';
import LayoutBoxBody from 'components/layout/layout-box/LayoutBoxBody.js';
import styles from 'components/api-authorization/AuthorizationStyles.module.scss';
import landingBanner from 'images/landing/BigPotatoBanner.png';
import potatoHappy from 'images/landing/PotatoHappy.png';
import axios from "axios";
import { Alert } from 'react-bootstrap';
import { Fragment } from 'react';

export class RecoverSteamKeys extends Component {
    constructor(props) {
        super(props);

        this.state = {
            success: false,
            error: false,
            showAlert: false,
            showAlertText: '',
            isSilk: false,
            loading: false,
            email: '',
            errors: { }
        };
    }

    onChangeEmail = (e) => {
        let email = e.target.value;
        this.setState({ email });
    };

    recoverSteamKeys = (e) => {
        e.preventDefault();

        // clear errors
        this.setState({
            errors: {}
        });

        let { email, errors } = this.state;

        if (email) {
            // validate email
            if (email.indexOf("@") == -1) {
                errors.email = "Please enter a valid email address.";
                this.setState({
                    errors
                });
                return;
            }

            // submit request
            const API_URL = process.env.REACT_APP_API_URL;

            var data = JSON.stringify({
                "Email": email,
            });

            var config = {
                method: 'post',
                url: API_URL + 'api/Authenticate/recover-steam-keys',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            return axios(config)
                .then(() => {
                    this.setState({
                        success: true,
                        error: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                    this.setState({
                        success: false,
                        error: true
                    });
                });
        } else {
            errors.email = "Please enter your email address.";
            this.setState({
                errors
            });
        }
    }

    componentDidMount() {
        this.browserVersion();
        this.setSilk();
    }

    setSilk() {
        //this works to detect silk browser
        if (/\bSilk\b/.test(navigator.userAgent)) {
            this.setState({
                isSilk: true
            });
        }
    }

    browserVersion() {
        try {
            //browser detection
            var browser = {};
            if (/edge\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "edge";
                browser.majorVersion = parseInt(/edge\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /edge\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/chrome\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "chrome";
                browser.majorVersion = parseInt(/chrome\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /chrome\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/firefox\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "firefox";
                browser.majorVersion = parseInt(/firefox\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /firefox\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/opr\/[0-9]{2}/i.test(navigator.userAgent)) {
                browser.agent = "opera";
                browser.majorVersion = parseInt(/opr\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /opera\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/CriOS/i.test(navigator.userAgent)) {
                browser.agent = "chrome";
                browser.majorVersion = parseInt(/CriOS\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /CriOS\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else if (/Safari\/[0-9.]+/i.test(navigator.userAgent)) {
                browser.agent = "safari";
                browser.majorVersion = parseInt(/Version\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
                browser.version = /Version\/([0-9.]+)/i.exec(navigator.userAgent)[1];
            } else {
                browser.agent = false;
                browser.majorVersion = false;
                browser.version = false;
            }

            if (browser.agent == "chrome") {
                if (browser.majorVersion < 4) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser version not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "edge") {
                if (browser.majorVersion < 8) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser version not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "firefox") {
                if (browser.majorVersion < 3.5) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "safari") {
                if (browser.majorVersion < 4) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser not supported " + browser.version
                    });
                }
            }
            else if (browser.agent == "opera") {
                if (browser.majorVersion < 11.5) {
                    this.setState({
                        showAlert: true,
                        showAlertText: browser.agent + " browser not supported " + browser.version
                    });
                }
            }

            setTimeout(() => {
                this.setState({
                    showAlert: false
                });
            }, 30000);
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { isSilk, email, errors, loading, success, error } = this.state;

        return (
            <div className={styles.containerBox} style={{ height: "auto" }}>
                <div className={styles.containerRegister}>
                    <Alert className={styles.lobbyAlert} show={this.state.showAlert} variant="danger" onClose={() => this.setAlert(false)} dismissible>
                        <Alert.Heading className={styles.lobbyAlertHeader}>You got an error! {this.state.showAlertText}.</Alert.Heading>
                        <p className={styles.lobbyAlertHeader + " mb-0"}>
                            This browser version is not supported. You may experience issues while playing.
                        </p>
                    </Alert>
                    {/*<img className={isSilk ? styles.bannerImageSilk + " position-relative" : styles.bannerImage + " position-relative"} src={landingBanner} />*/}
                    <Container className="h-100 p-0">
                        <div className="row justify-content-center w-100 h-100 m-0">
                            <LayoutBox className="card text-center my-auto p-0 mx-auto">
                                <LayoutBoxHeader className="card-header mt-0">
                                    <h5 className={styles.cardTitle + " card-title pb-0"}>Recover<br />Steam Key</h5>
                                </LayoutBoxHeader>
                                <LayoutBoxBody className={styles.cardBody + " card-body pt-0"}>
                                    {!success &&
                                        <Fragment>
                                            <div className={styles.cardPDiv}>
                                            <p className={this.state.isSilk ? styles.cardPSilk : styles.cardP}>Enter the email you used to redeem your steam key. If you're not sure which email you used then please <a target="_blank" href="https://bigpotato.co.uk/pages/help">contact support</a>.</p>
                                            </div>
                                            <div className={`${styles.cardPDiv} ${styles.formEnter}`}>
                                                <div className={styles.formGroup}>
                                                    <label className={isSilk ? styles.formEnterLabelSilk : styles.formEnterLabel} htmlFor="email">
                                                        Email
                                                    </label>
                                                    <br />
                                                    <input className={styles.formEnterInput}
                                                        type="text"
                                                        autoComplete="off"
                                                        id="email"
                                                        name="email"
                                                        value={email}
                                                        onChange={this.onChangeEmail}
                                                        required
                                                    />
                                                    {errors.email ? <div className={styles.alertSilk + " alert alert-danger"} role="alert">{errors.email}</div> : ""}
                                                    {!errors.email ? <Fragment><br /><br /></Fragment> : ""}
                                                </div>
                                            </div>
                                            <div className={isSilk ? styles.formGroupButtonSilk + " position-relative form-group" : styles.formGroupButton + " position-relative form-group"}>
                                                <button className={isSilk ? styles.submitBtnSilk + " btn" : styles.submitBtn + " btn"} disabled={loading} onClick={this.recoverSteamKeys}>
                                                    {loading && (
                                                        <span className={styles.loadingSpinner + " spinner-border spinner-border mr-3"}></span>
                                                    )}
                                                    <span>Submit</span>
                                                </button>
                                                <img className={styles.potatoHappy} src={potatoHappy} width="48" />
                                            </div>
                                        </Fragment>
                                    }
                                    <div className={styles.cardPDiv}>
                                        {success ? <div className="alert alert-success" role="alert">We've received your request! If we recognise that email, any associated steam keys will be sent to that address.</div> : ""}
                                        {error ? <div className="alert alert-danger" role="alert">There was an issue processing your request. If this issue persists please <a target="_blank" href="https://bigpotato.co.uk/pages/help">contact support</a>.</div> : ""}
                                    </div>
                                </LayoutBoxBody>
                            </LayoutBox>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}