import * as React from 'react';
import styles from './LayoutBox.module.scss';

export default class LayoutBoxBody extends React.Component {
    render() {
        return (
            <div className={`${styles.body} ${this.props.className}`}>
                {this.props.children}
            </div>
        );
    }
}