import React from 'react'
import { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import http from "helpers/http.js";
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { Link, NavLink } from 'react-router-dom';
import styles from 'components/api-authorization/UserStyles.module.scss';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userInfo from 'images/User.png';
import userInfoWhite from 'images/User-white.png';
import { withRouter } from "react-router";

class UserInfo extends Component {
    constructor(props) {
        super(props);

        this.container = React.createRef();
        this.state = {
            isOpen: false,
            email: "",
            games: 0
        };

        this.dropdownOpen = this.dropdownOpen.bind(this);
        this.lobbyPage = this.lobbyPage.bind(this);
        //this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    dropdownOpen() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    lobbyPage() {
        window.location = "/lobby"
    }

    addVoucherPage = () => {
        this.props.history.push('/authentication/voucher-code');        
    }

    componentDidMount() {
        var url = process.env.REACT_APP_API_URL;
        var email = JSON.parse(localStorage.getItem('user'));

        var config = {
            method: 'get',
            params: {
                email: email.email
            },
            url: url + 'api/User/GetProfile',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        //var email = localStorage.getItem("email");
        var email = 
        http.post(process.env.REACT_APP_API_URL + "api/Authenticate/GetGames", {
            email: email.email
        }).then(response => {
            var gamesData = response.data.games;
            this.setState({
                games: gamesData
            });

        }).catch(error => {
            console.log(error.response);
        });

        http(config)
            .then((response) => {
                this.setState({
                    email: response.data.email
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    //handleClickOutside = (event) => {
    //    if (
    //        this.container.current &&
    //        !this.container.current.contains(event.target)
    //    ) {
    //        this.setState({
    //            isOpen: false,
    //        });
    //    }
    //};

    onMouseEnter() {
        this.setState({ isOpen: true });
    }

    onMouseLeave() {
        this.setState({ isOpen: false });
    }

    render() {
        const style = {
            backgroundColor: '#ffeb03',
            width: '100%',
            border: '1px solid black'
        }
        const profilePath = `${ApplicationPaths.Profile}`;
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

        return (
            <div className={styles.user_info} ref={this.container}>
                <div className="user-name">
                    <Dropdown className="dropdown" isOpen={this.state.isOpen} onBlur={this.dropdownOpen} toggle={this.dropdownOpen} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                        <DropdownToggle className={this.props.isMobile ? styles.user_dropdown_mobile + " shadow-none btn btn-secondary border-0 bg-transparent dropdown-toggle" : styles.user_dropdown + " shadow-none btn btn-secondary border-0 bg-transparent dropdown-toggle w-100 d-flex"}  caret>
                            <Link style={{ padding: this.props.isMobile && '0' }} className={styles.link_user + "  w-100 d-flex"} id="linkMyAccount" to="/">
                                {this.props.isMobile ? <img src={userInfoWhite} width="22" alt="account" className={styles.user_image + " logo"} /> : <img src={userInfo} width="22" alt="account" className={styles.user_image + " logo"} />}
                                <span style={{ verticalAlign: this.props.isMobile && 'bottom' }} className={styles.user_name}>{this.state.email}</span>
                            </Link>
                        </DropdownToggle>
                        <DropdownMenu className={this.props.isMobile ? styles.user_dropdownmenu_mobile : styles.user_dropdownmenu}>
                            <DropdownItem header>
                                <ul className={styles.nav_links + " list-group w-100"}>
                                    {!this.props.isMobile &&
                                        <div>
                                            {/*<NavItem className={styles.nav_item + " p-0 d-flex list-group-item"}>*/}
                                            {/*    <NavLink tag={Link} activeStyle={style} className={styles.nav_link + " text-dark btn"} to="/" exact>Join Game</NavLink>*/}
                                            {/*</NavItem>*/}
                                            <NavItem className={styles.nav_item + " p-0 d-flex list-group-item"}>
                                                <button className={styles.nav_link + " text-dark btn button-logout"}
                                                    onClick={this.lobbyPage}>
                                                    <span className="icon-link-log pl-4">Lobby</span>
                                                </button>
                                                {/*<a className={styles.nav_link + " text-dark btn"} href="/lobby">Lobby</a>*/}
                                            </NavItem>

                                            <NavItem className={styles.nav_item + " p-0 d-flex list-group-item"}>
                                                <button className={styles.nav_link + " text-dark btn button-logout"}
                                                    onClick={this.addVoucherPage}>
                                                    <span className="icon-link-log pl-4">Add Voucher</span>
                                                </button>
                                            </NavItem>
                                            
                                            {this.props.showModeratorBoard && (
                                                <NavItem className={styles.nav_item + " p-0 d-flex list-group-item"}>
                                                    <NavLink to={"/mod"} tag={Link} activeStyle={style} className={styles.nav_link + " text-dark btn"}>
                                                        Moderator Board
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            {this.props.showAdminBoard && (
                                                <NavItem className={styles.nav_item + " p-0 d-flex list-group-item"}>
                                                    <NavLink to={"/admin"} tag={Link} activeStyle={style} className={styles.nav_link + " text-dark btn"}>
                                                        Admin Board
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                        </div>
                                    }
                                    <NavItem className={styles.nav_item + " p-0 d-flex list-group-item"}>
                                        <button className={styles.nav_link + " " + styles.nav_link_logout + " text-dark btn button-logout"}
                                            onClick={this.props.logOut}>
                                            <FontAwesomeIcon className="logout-icon" icon={faSignInAlt} />
                                            <span className="icon-link-log" style={{paddingLeft: 10 + 'px'}}>Logout</span>
                                        </button>
                                    </NavItem>
                                </ul>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

export default withRouter(UserInfo);