import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, useLocation } from "react-router-dom";
import { Layout } from 'components/Layout';
import { LayoutWithFooter } from 'components/LayoutWithFooter';
import  Home  from 'components/Home';
import ApiAuthorizationRoutes from 'components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';
import 'styles/Site.scss';
import { logout, getPackOwnership } from "actions/auth";
import { setPrice } from "actions/pricing";
import stripeService from "services/stripe.service";
import { clearMessage } from "actions/message";
import { history } from "helpers/history";
import AuthVerify from "common/AuthVerify";
import EventBus from "common/EventBus";
import NavMenu from 'components/NavMenu';

// Policy Docs
import PrivacyPolicy from "components/policy/PrivacyPolicy";
import CookiePolicy from "components/policy/CookiePolicy";
import DataProtection from "components/policy/DataProtection";
import TermsConditions from "components/policy/TermsConditions";
import PrivateRoute from 'components/PrivateRoute';
//import WebGLTest from "components/WebGLTest";
import { LobbyWrapper } from "components/lobbys/LobbyWrapper";
import Pack1Lobby from 'components/lobbys/Pack1Lobby';
import Pack2Lobby from 'components/lobbys/Pack2Lobby';
import Packs from "components/packs/Packs";
import Pack1 from "components/packs/Pack1";
import Footer from 'components/Footer';

import { getGames } from "actions/auth";
//facebook
import ReactPixel from 'react-facebook-pixel';
import GameCityOverlay from "./components/GameCityOverlay";

//google analytics BPG/Vocala
//import ReactGA from 'react-ga';

//ReactGA.initialize(
//    [
//        {
//            trackingId: 'UA-229778159-1',
//            gaOptions: {
//                name: 'tracker1'
//            }
//        },
//        {
//            trackingId: 'UA-69017987-18',
//            gaOptions: { name: 'tracker2' }
//        }
//    ],
//    { debug: false, alwaysSendToDefaultTracker: false }
//);

//const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
//const options = {
//    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//    debug: false, // enable logs
//};
//ReactPixel.init('1513181152325903', options);

//ReactPixel.pageView(); // For tracking page view
//ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
//ReactPixel.trackSingle('1513181152325903', event, data); // For tracking default events.
//ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
//ReactPixel.trackSingleCustom('1513181152325903', event, data); // For tracking custom events.

// Initialize google analytics page view tracking
history.listen(location => {
    //ReactGA.set({ page: location.pathname }, ['tracker1']); // Update the user's current page
    //ReactGA.pageview(location.pathname, ['tracker1']); // Record a pageview for the given page
    //ReactGA.set({ page: location.pathname }, ['tracker2']); // Update the user's current page
    //ReactGA.pageview(location.pathname, ['tracker2']); // Record a pageview for the given page
    window.gtag('event', 'page_view');
});

const App = (props) => {
    const [showModeratorBoard, setShowModeratorBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    const { user: currentUser } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        history.listen((location) => {
            dispatch(clearMessage()); // clear message when changing location
        });
    }, [dispatch]);

    const logOut = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const setPricing = async () => {
        let pricing = await stripeService.getPrice();
        pricing = pricing.data;
        console.log("pricing : " + JSON.stringify(pricing));
        dispatch(setPrice(pricing));
    }


    useEffect(() => {
        if (currentUser) {
            //setShowModeratorBoard(currentUser.roles.includes("User"));
            //setShowAdminBoard(currentUser.roles.includes("Admin"));
        } else {
            setShowModeratorBoard(false);
            setShowAdminBoard(false);
        }

        setPricing();

        EventBus.on("logout", () => {
            logOut();
        });

        return () => {
            EventBus.remove("logout");
        };
    }, [currentUser, logOut]);

    useEffect(() => {
        if (currentUser) {
            dispatch(getPackOwnership());
        }
        
    }, []);

    return (
        <Router history={history}>
            <Layout pathname={pathname} showModeratorBoard={showModeratorBoard} showAdminBoard={showAdminBoard} currentUser={currentUser} logOut={logOut} isLoggedIn={isLoggedIn}>

                {/*<NavMenu*/}
                {/*    showModeratorBoard={showModeratorBoard}*/}
                {/*    showAdminBoard={showAdminBoard}*/}
                {/*    currentUser={currentUser}*/}
                {/*    logOut={logOut}*/}
                {/*    isLoggedIn={isLoggedIn} />*/}
                <Switch>
                    {/*<PrivateRoute exact path='/lobby' component={LobbyWrapper} />*/}
                    {/*<PrivateRoute exact path='/lobby2' component={LobbyWrapper} />*/}
                    {/*<LayoutWithFooter pathname={pathname} showModeratorBoard={showModeratorBoard} showAdminBoard={showAdminBoard} currentUser={currentUser} logOut={logOut} isLoggedIn={isLoggedIn}>*/}
                        {/*<Route exact path={["/", "/home"]} render={() => <Pack1 currentUser={currentUser} />} />*/}
                        {/*<Route exact path="/play" render={() => <Home currentUser={currentUser} />} />*/}
                    
                        {/*<Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />*/}
                       
                        {/*<Route path='/privacy' component={PrivacyPolicy} />*/}
                        {/*<Route path='/data' component={DataProtection} />*/}
                        {/*<Route path='/terms' component={TermsConditions} />*/}
                        {/*<Route path='/cookies' component={CookiePolicy} />*/}

                        {/*<PrivateRoute exact path='/lobby2' component={Lobby} />*/}
                        {/*<Route path='/packs' component={Packs} />*/}
                        {/*<Route path='/pack1' component={Pack1} />*/}
                        <Route exact path='/' component={GameCityOverlay} />
                    {/*</LayoutWithFooter>*/}
                </Switch>
                <AuthVerify logOut={logOut} />
            </Layout>
        </Router>
    );
};

export default App;
