

export const fireTikTokConversionEvent = (options) => {
    switch (options.eventName) {
        case "ViewContent":
            window.ttq.track("ViewContent", {
                content_type: "product",
                content_name: "Pack 1",
                currency: options.currency,
                value: options.price,
                content_id: "1",
            });
            break;
        case "AddToCart":
            window.ttq.track("AddToCart", {
                content_type: "product",
                content_name: "Pack 1",
                currency: options.currency,
                value: options.price,
                content_id: "1",
            });
            break;
        case "InitiateCheckout":
            window.ttq.track("InitiateCheckout", {
                content_type: "product",
                content_name: "Pack 1",
                currency: options.currency,
                value: options.price,
                content_id: "1",
            });
            break;
        case "Purchase":
            window.ttq.track("CompletePayment", {
                content_type: "product",
                content_name: "Pack 1",
                currency: options.currency,
                value: options.price,
                content_id: "1",
            });
            break;
    }
}