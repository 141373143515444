import * as React from 'react';
import styles from './LayoutBox.module.scss';

export default class LayoutBox extends React.Component {
    render() {
        return (
            <div className={`${styles.box} ${this.props.wider ? styles.wider : styles.wide} ${this.props.className}`}>
                {this.props.children}
            </div>
        );
    }
}