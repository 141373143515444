import React, { Component } from 'react';

//import Footer from 'components/Footer';

export class Layout extends Component {
    constructor(props) {
        super(props);
    }

    static displayName = Layout.name;

    render() {
        return (
            <React.Fragment>
                <div className="h-100">
                    {this.props.children}
                </div>
                {/*<Footer />*/}
            </React.Fragment>
        );
    }
}
