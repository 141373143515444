import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import { connect, } from 'react-redux';
import Lottie from 'react-lottie';


import styles from './GameCityOverlayStyles.module.scss';
import PotatoByebye from '../animations/Potato - Byebye.js';
import BannerImg from "images/movingBanner.png";


class GameCityOverlay extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }


    render() {
        return (
            <div className={`container-fluid ${styles.moveContainer}`}>
                <div className={`${styles.moveBox}`}>
                    <img src={BannerImg} className={styles.banner} />
                    <div className={styles.anim}>
                        <Lottie
                            options={PotatoByebye}
                            width="100%"
                            height="100%"
                            isClickToPauseDisabled={true}
                        />
                    </div>
                    <p>Thanks so much for choosing to play Big Screen Games - our website has changed but we still have the same great games waiting for you. Plus three new ones! Head on over to <a href="https://game.city">game.city</a>, your login will be the exact same so you can get playing right away.</p>
                    <button onClick={() => window.location = "https://game.city"}>GO TO GAME.CITY</button>
                    <p>If you have any questions or problems please pop an email to Team Tato on  <a href="mailto:hello@bigpotato.co.uk">hello@bigpotato.co.uk</a></p>
                </div>
            </div>
        )
    }
}

export default withRouter(GameCityOverlay);